import * as React from 'react';
import { TopToolbar, ListButton, CreateButton, ShowButton } from 'react-admin';
import CustomDeleteButton from './CustomDeleteButton';

const EditActions = ({ basePath, data, resource }) => (
    <TopToolbar undoable='false'>
        <ListButton basePath={basePath} />
        <CreateButton basePath={basePath} />
        <ShowButton basePath={basePath} record={data} />
        <CustomDeleteButton basePath={basePath} record={data} resource={resource} />
    </TopToolbar>
);

export default EditActions;
