import * as React from 'react';
import { useTranslate } from 'ra-core';
import { makeStyles, Chip } from '@material-ui/core';
import {
    TextField,
    Filter,
    List,
    Datagrid,
    ReferenceField,
    TextInput,
} from 'react-admin';
import ListActions from '../ListActions';
import ListBulkActions from '../ListBulkActions';
import ListCategoryFilter from '../ListCategoryFilter';
import ListServiceCategoryFilter from '../ListServiceCategoryFilter';
import ListPagination from '../ListPagination';
import ListTransformPathField from '../ListTransformPathField';
import ListActiveField from '../ListActiveField';
import ListEmpty from '../ListEmpty';
import ResourceManagementButtons from '../ResourceManagementButtons';

const useStyles = makeStyles({
    filter: {
        width: '15em',
    },
});

const useQuickFilterStyles = makeStyles((theme) => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));

const QuickFilter = ({ label }) => {
    const translate = useTranslate();
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={translate(label)} />;
};

const ProductFilter = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <TextInput
                label={translate('ra.action.search')}
                source='search_keyword_term'
                alwaysOn
                className={classes.filter}
            />
            <TextInput
                label={translate('ra.input.slug', 1)}
                source='slug'
                defaultValue=''
                className={classes.filter}
            />
            <TextInput
                label={translate('ra.input.name', 1)}
                source='name_with_locality_derived'
                defaultValue=''
                className={classes.filter}
            />
            <QuickFilter
                source='active'
                label={translate('ra.input.active')}
                defaultValue={1}
            />
            <QuickFilter
                source='inactive'
                label={`${translate('ra.action.not')} ${translate(
                    'ra.input.active'
                ).toLowerCase()}`}
                defaultValue={1}
            />
            {!props.trash && (
                <QuickFilter
                    source='trash'
                    label={translate('ra.input.trash.show')}
                    defaultValue={1}
                />
            )}
        </Filter>
    );
};

export const ProductList = (props) => {
    const translate = useTranslate();
    return (
        <List
            {...props}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.directory'
            )}: ${translate('ra.navigation.resources.product', 2)}`}
            actions={<ListActions />}
            filters={<ProductFilter />}
            bulkActionButtons={<ListBulkActions />}
            perPage={15}
            sort={{ field: 'slug', order: 'ASC' }}
            pagination={<ListPagination />}
            aside={
                <div>
                    <ListCategoryFilter />
                    <ListServiceCategoryFilter />
                </div>
            }
            empty={<ListEmpty />}
            filter={{ flatten: null }}
        >
            <Datagrid>
                <TextField source='slug' label={translate('ra.input.slug', 1)} />
                <TextField
                    source='name_with_locality_derived'
                    label={translate('ra.input.name', 1)}
                />
                <ListActiveField
                    source='is_active'
                    label={translate('ra.input.active')}
                />
                <ListTransformPathField
                    label={translate('ra.input.path', 1)}
                    sortable={false}
                />
                <ReferenceField
                    label={translate('ra.navigation.resources.entity', 1)}
                    source='entity_id'
                    reference='entities'
                    sortable={false}
                >
                    <TextField source='name_with_locality_derived' />
                </ReferenceField>
                <ReferenceField
                    label={translate('ra.navigation.resources.category', 1)}
                    source='category_id'
                    reference='categories'
                    sortable={false}
                >
                    <TextField source='name' />
                </ReferenceField>
                <ResourceManagementButtons />
            </Datagrid>
        </List>
    );
};
