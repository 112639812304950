import * as React from 'react';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import theme from '../themes/DefaultTheme';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import LockIcon from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';
import { Notification } from 'react-admin';

const useStyles = makeStyles(
    () => ({
        page: {
            alignItems: 'center',
            background: theme.palette.background.primary,
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
        },
        wrapper: {
            minWidth: 300,
            marginTop: '6rem',
            whiteSpace: 'pre-line',
        },
        avatar: {
            background: `url(${process.env.PUBLIC_URL}logo192.png)`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            margin: '1rem 0 0',
            height: 96,
            width: '100%',
        },
        icon: {
            display: 'none',
        },
        heading: {
            ...theme.typography.button,
            textAlign: 'center',
        },
    }),
    {
        name: 'ForgottenPasswordForm',
    }
);

const PasswordResetWrapper = (props) => {
    const classes = useStyles();
    return (
        <ThemeProvider theme={theme}>
            <div className={classes.page}>
                <div className={classes.wrapper}>
                    <Card className={classes.card}>
                        <div className={classes.avatar}>
                            <Avatar className={classes.icon}>
                                <LockIcon />
                            </Avatar>
                        </div>
                        <Typography>
                            <h1 className={classes.heading}>{props.header}</h1>
                        </Typography>
                        {props.children}
                    </Card>
                    <Notification />
                </div>
            </div>
        </ThemeProvider>
    );
};

export default PasswordResetWrapper;
