import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

const LocationHelper = {
    SetLatLngByPlaces: (
        place,
        placeState,
        pointState,
        stringState = null,
        msg = ''
    ) => {
        placeState(place.label);
        geocodeByAddress(place.label)
            .then((results) => {
                if (stringState) {
                    const resultStr = `${msg} ${results[0].formatted_address}`;
                    stringState(resultStr.trim());
                }
                return getLatLng(results[0]);
            })
            .then(({ lat, lng }) => pointState({ lat, lng }));
    },
};

export default LocationHelper;
