import React, { useState, useContext, useEffect } from 'react';
import DataProvider from '../../providers/DataProvider';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useNotify } from 'ra-core';
import { MassTimeContext } from './MassTimeProvider';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import { Form } from 'react-final-form';
import { EditButton } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomInputs from '../CustomInputs';
import FormTheme from '../../themes/DefaultThemeForm';
import ValidationHelper from '../../helpers/ValidationHelper';
import dateFormat from 'dateformat';

const useStyles = makeStyles((theme) => ({
    paddedBottom: {
        paddingBottom: '1rem',
    },
    title: {
        color: theme.palette.text.primary,
    },
    ...FormTheme.styles.modal,
}));

const MassTimeEditModal = ({ record }) => {
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [saving, setSaving] = useState(false);
    const [, dispatch] = useContext(MassTimeContext);

    // Parent resource optimistic rendering
    record.mass_time_notes = record.notes;
    record.mass_time_internal_notes = record.internal_notes;
    record.mass_time_is_active = record.is_active;

    const [categoryId] = useState(record.category);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);

    const validate = (values) => {
        const errors = {
            ...ValidationHelper.ValidateRequired(
                values,
                ['mass_language_id'],
                translate('ra.validation.required')
            ),
        };
        if (
            values.repetition &&
            values.repetition.length > 1 &&
            values.repetition.includes(0)
        ) {
            errors.repetition = translate('ra.validation.mass_time_repetition');
        }

        if (!selectedDate) {
            errors.date = translate('ra.validation.required');
        }

        if (!selectedTime) {
            errors.time = translate('ra.validation.required');
        }

        return errors;
    };

    useEffect(() => {
        setSelectedDate(new Date(`${record.date}T12:00:00`));
        if (record.end_date) {
            setSelectedEndDate(new Date(`${record.end_date}T12:00:00`));
        }
        setSelectedTime(new Date(`2016-06-10T${record.time}`));
    }, [record.time, record.date, record.end_date]);

    const submit = (values) => {
        setSaving(true);
        const thisSeason =
            record.category.toLowerCase() === 'special'
                ? record.mass_season_id
                : record.category;
        const massTimeData = {
            mass_type_id: values.mass_type_id ?? null,
            mass_language_id: values.mass_language_id,
            repetition: values.repetition
                ? `[${values.repetition.toString()}]`
                : null,
            end_date: selectedEndDate
                ? dateFormat(selectedEndDate.toISOString(), 'yyyy-mm-dd')
                : null,
            notes: values.mass_time_notes ?? null,
            is_web_cast: values.is_web_cast ? 1 : 0,
            is_active: values.mass_time_is_active ? 1 : 0,
            internal_notes: values.mass_time_internal_notes ?? null,
        };
        if (selectedTime) {
            massTimeData.time = selectedTime.toLocaleTimeString('en-GB', {
                hour12: false,
                timeStyle: 'short',
            });
        }
        if (selectedDate) {
            massTimeData.date = dateFormat(selectedDate.toISOString(), 'yyyy-mm-dd');
        }
        return DataProvider.update('mass-times', {
            id: record.id,
            data: massTimeData,
        })
            .then(() => {
                dispatch({
                    type: 'toggle_dirty_accordion',
                    season: thisSeason,
                });
                setSaving(false);
                setOpen(false);
            })
            .catch((error) => {
                dispatch({
                    type: 'toggle_dirty_accordion',
                    season: thisSeason,
                });
                setSaving(false);
                notify(`${error.message} ${translate('ra.message.try_again')}`);
            });
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <EditButton onClick={handleOpen} scrollToTop={false} />
            <Modal
                aria-labelledby='modal-title'
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                scrollToTop={false}
            >
                <Fade in={open}>
                    <div>
                        <Form
                            initialValues={record}
                            onSubmit={submit}
                            validate={validate}
                            render={({ handleSubmit }) => (
                                <form onSubmit={handleSubmit} noValidate>
                                    <div className={classes.paper}>
                                        <Grid
                                            container
                                            direction='row'
                                            justify='space-between'
                                            alignItems='center'
                                        >
                                            <h3 id='modal-title' className={classes.title}>
                                                {translate('ra.action.edit')}{' '}
                                                {translate('ra.navigation.resources.mass_time', 1)}
                                            </h3>
                                            <IconButton onClick={handleClose}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Grid>
                                        <CustomInputs.MassTimeLanguageReferenceInput />
                                        <CustomInputs.MassTimeTypeReferenceInput />
                                        <div className={classes.paddedBottom}>
                                            <CustomInputs.DatePicker
                                                label={
                                                    categoryId === 'SPECIAL'
                                                        ? translate('ra.input.date', 1)
                                                        : translate('ra.input.start_date', 1)
                                                }
                                                source='date'
                                                selectedDate={selectedDate}
                                                handleDateChange={setSelectedDate}
                                                format='dd-MM-yyyy'
                                                maxDate={selectedEndDate}
                                                isRequired={true}
                                            />
                                        </div>
                                        {categoryId !== 'SPECIAL' && (
                                            <CustomInputs.RepetitionArrayInput />
                                        )}
                                        <div className={classes.paddedBottom}>
                                            <CustomInputs.TimePicker
                                                source='time'
                                                selectedTime={selectedTime}
                                                handleTimeChange={setSelectedTime}
                                                isRequired={true}
                                            />
                                        </div>
                                        {categoryId !== 'SPECIAL' && (
                                            <div className={classes.paddedBottom}>
                                                <CustomInputs.DatePicker
                                                    label={translate('ra.input.end_date', 1)}
                                                    source='end_date'
                                                    selectedDate={selectedEndDate}
                                                    handleDateChange={setSelectedEndDate}
                                                    format='dd-MM-yyyy'
                                                    minDate={selectedDate}
                                                />
                                            </div>
                                        )}
                                        <CustomInputs.NotesTextInput source={'mass_time_notes'} />
                                        <CustomInputs.IsWebCastBooleanInput />
                                        <CustomInputs.IsActiveBooleanInput
                                            source={'mass_time_is_active'}
                                        />
                                        <CustomInputs.InternalNotesTextInput
                                            source={'mass_time_internal_notes'}
                                        />
                                        <CardActions>
                                            <Button
                                                className={classes.button}
                                                variant='contained'
                                                type='submit'
                                                color='primary'
                                            >
                                                {translate('ra.action.save')}{' '}
                                                {translate('ra.navigation.resources.mass_time', 1)}{' '}
                                            </Button>
                                            {saving && <CircularProgress size={18} thickness={2} />}
                                        </CardActions>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </Fade>
            </Modal>
        </>
    );
};

export default MassTimeEditModal;
