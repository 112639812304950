import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useSafeSetState } from 'ra-core';
import FormTheme from '../../themes/DefaultThemeForm';
import {
    useNotify,
    useRefresh,
    useRedirect,
    Create,
    SimpleForm,
} from 'react-admin';
import CustomInputs from '../CustomInputs';
import CreateActions from '../CreateActions';
import ValidationHelper from '../../helpers/ValidationHelper';

const useStyles = makeStyles(() => FormTheme.styles, {
    name: 'ProductForm',
});

export const ProductCreate = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = ({ data }) => {
        notify(translate('ra.notification.created'));
        redirect(data.id);
        refresh();
    };

    const transform = (data) => ({
        ...data,
        is_exclude_locality_from_name: data.is_exclude_locality_from_name ? 1 : 0,
        is_show_in_search: data.is_show_in_search ? 1 : 0,
        is_active: 0,
    });

    const validate = (values) => ({
        ...ValidationHelper.ValidateRequired(
            values,
            [
                'category_id',
                'entity_id',
                'service_categories_ids',
                'name',
                'meta_description',
            ],
            translate('ra.validation.required')
        ),
        ...ValidationHelper.ValidateLength(
            values,
            'meta_description',
            160,
            translate('ra.validation.maxLength', {
                max: 160,
            })
        ),
    });

    const [categoryId, setCategoryId] = useSafeSetState(null);
    const filter = () => {
        const filter = { flatten: null };
        if (categoryId) {
            return {
                ...filter,
                category_id: categoryId,
            };
        } else {
            return filter;
        }
    };

    return (
        <Create
            {...props}
            actions={<CreateActions />}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.directory'
            )}: ${translate('ra.navigation.resources.product', 2)}`}
            transform={transform}
            onSuccess={onSuccess}
            onFailure={(e) => {
                notify(ValidationHelper.FormatApiErrors(e));
            }}
            undoable={false}
        >
            <SimpleForm
                validate={validate}
                warnWhenUnsavedChanges
                redirect='list'
                className={classes.form}
            >
                <h3 className={classes.formTitle} fullWidth>
                    {translate('ra.action.creating')}
                    {' - '}
                    {translate('ra.navigation.resources.product', 1)}
                </h3>
                <p fullWidth>
                    <strong>{translate('ra.action.note')}</strong>{' '}
                    {translate('ra.message.slug_auto_generated')}
                </p>
                <CustomInputs.CategoryReferenceInput
                    onChange={(e) => setCategoryId(e.target.value)}
                />
                <CustomInputs.ProductReferenceInput
                    label={translate('ra.input.parent', 1)}
                    source='parent_id'
                    filter={filter()}
                    allowEmpty
                />
                <CustomInputs.EntityReferenceInput />
                <CustomInputs.ServiceCategoryArrayReferenceInput filter={filter()} />
                <CustomInputs.ProductReferenceInput
                    label={translate('ra.input.refer_to', 1)}
                    source='refer_product_id'
                    filter={filter()}
                    helperText={translate('ra.input.helper.refer_to')}
                    allowEmpty
                />
                <CustomInputs.NameTextInput />
                <CustomInputs.LocalityTextInput />
                <CustomInputs.IsExcludeLocalityBooleanInput fullWidth />
                <CustomInputs.MetaDescriptionTextInput />
                <CustomInputs.DescriptionRichTextInput />
                <CustomInputs.TagsInput filter={filter()} allowEmpty />
                <CustomInputs.DemographicFociInput filter={filter()} allowEmpty />
                <CustomInputs.ProductArrayInput
                    label={`${translate('ra.input.related', 1)} ${translate(
                        'ra.navigation.resources.product',
                        2
                    ).toLowerCase()}`}
                    filter={{ flatten: null }}
                    allowEmpty
                />
                <CustomInputs.EstablishedTextInput />
                <CustomInputs.DedicatedTextInput />
                <CustomInputs.CapacityNumberInput />
                <CustomInputs.KeywordTermsTextInput />
                <CustomInputs.NotesRichTextInput />
                <CustomInputs.NotesRichTextInput
                    label={translate('ra.input.public_note_footer', 2)}
                    source='sub_notes'
                    helperText={translate('ra.input.helper.public_note_footer')}
                />
                <CustomInputs.IsShowInSearchBooleanInput fullWidth />
                <CustomInputs.OrderingNumberInput
                    helperText={translate('ra.input.helper.optional_ordering')}
                />
                <CustomInputs.InternalNotesTextInput />
            </SimpleForm>
        </Create>
    );
};
