import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import FormTheme from '../../themes/DefaultThemeForm';
import {
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    BooleanField,
    RichTextField,
} from 'react-admin';
import ShowActions from '../ShowActions';

const useStyles = makeStyles(() => FormTheme.styles, {
    name: 'MassTypeForm',
});

export const MassTypeShow = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const ShowTitle = ({ record }) => {
        return (
            <h3 className={classes.formTitle} fullWidth>
                {translate('ra.action.showing')}{' '}
                {translate('ra.navigation.resources.mass_type', 1)}: {record.name}
            </h3>
        );
    };
    return (
        <Show
            {...props}
            actions={<ShowActions />}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.configuration'
            )}: ${translate('ra.navigation.resources.mass_type', 2)}`}
        >
            <SimpleShowLayout className={classes.show}>
                <ShowTitle />
                <TextField source='id' label={translate('ra.input.id', 1)} />
                <TextField source='name' label={translate('ra.input.name', 1)} />
                <TextField source='slug' label={translate('ra.input.slug', 1)} />
                <TextField
                    source='meta_description'
                    label={translate('ra.input.meta_description', 1)}
                />
                <RichTextField
                    source='description'
                    label={translate('ra.input.description', 1)}
                    className={classes.show}
                />
                <TextField
                    source='ordering'
                    label={translate('ra.input.ordering', 2)}
                />
                <BooleanField
                    source='is_show_overview'
                    label={translate('ra.input.is_show_overview')}
                />
                <BooleanField
                    source='is_use_as_filter'
                    label={translate('ra.input.is_use_as_filter')}
                />
                <BooleanField source='is_active' label={translate('ra.input.active')} />
                <TextField
                    source='internal_notes'
                    label={translate('ra.input.internal_note', 2)}
                />
                <DateField
                    source='updated_at'
                    label={translate('ra.input.last_updated_date')}
                />
                <DateField
                    source='created_at'
                    label={translate('ra.input.publication_date')}
                />
            </SimpleShowLayout>
        </Show>
    );
};
