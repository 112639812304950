import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import FormTheme from '../../themes/DefaultThemeForm';
import {
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    BooleanField,
    RichTextField,
    EmailField,
    UrlField,
    FunctionField,
    ReferenceField,
} from 'react-admin';
import CustomInputs from '../CustomInputs';
import ShowActions from '../ShowActions';
import PathHelper from '../../helpers/PathHelper';
import GoogleMapComponentWrapper from '../MapField';
import { ProductShowServiceCategoriesList } from './ProductShowServiceCategoriesList';
import { ProductShowDemographicFociList } from './ProductShowDemographicFociList';
import { ProductShowTagsList } from './ProductShowTagsList';
import { ProductShowChildrenList } from './ProductShowChildrenList';
import { ProductShowRelatedProductsList } from './ProductShowRelatedProductsList';
import { ProductShowPersonnelList } from './ProductShowPersonnelList';
import { MediaShowList } from '../media/MediaShowList';
import { MassTimeShowList } from '../mass-time/MassTimeShowList';

const useStyles = makeStyles(() => FormTheme.styles, {
    name: 'ProductForm',
});

export const ProductShow = (props) => {
    const classes = useStyles();
    const translate = useTranslate();

    const WithProps = ({ record }) => {
        if (!isLocationSet && record.location_derived) {
            if (record.location_derived?.coordinates) {
                setLocation({
                    lat: record.location_derived.coordinates[1],
                    lng: record.location_derived.coordinates[0],
                });
            }
            setIsLocationSet(true);
        }
        return '';
    };
    const [isLocationSet, setIsLocationSet] = useState(false);
    const [location, setLocation] = useState({
        lat: null,
        lng: null,
    });

    const ShowTitle = ({ record }) => {
        return (
            <h3 className={classes.formTitle} fullWidth>
                {translate('ra.action.showing')}{' '}
                {translate('ra.navigation.resources.product', 1)}: {record.name}
            </h3>
        );
    };
    return (
        <Show
            {...props}
            actions={<ShowActions />}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.configuration'
            )}: ${translate('ra.navigation.resources.product', 2)}`}
        >
            <SimpleShowLayout>
                <WithProps />
                <ShowTitle />
                <TextField source='id' label={translate('ra.input.id', 1)} />
                <ReferenceField
                    label={translate('ra.navigation.resources.category', 1)}
                    source='category_id'
                    reference='categories'
                >
                    <TextField source='name' />
                </ReferenceField>
                <ReferenceField
                    label={translate('ra.input.parent', 1)}
                    source='parent_id'
                    reference='products'
                >
                    <TextField source='name' />
                </ReferenceField>
                <TextField source='slug' label={translate('ra.input.slug', 1)} />
                <FunctionField
                    label={translate('ra.input.path', 1)}
                    render={(record) =>
                        record
                            ? PathHelper.TriplePipeToSlash(record.tree_name_path_derived)
                            : null
                    }
                />
                <ReferenceField
                    label={translate('ra.navigation.resources.region', 1)}
                    source='region_id_derived'
                    reference='regions'
                >
                    <TextField source='name' />
                </ReferenceField>
                <ReferenceField
                    label={translate('ra.navigation.resources.entity', 1)}
                    source='entity_id'
                    reference='entities'
                >
                    <TextField source='name_with_locality_derived' />
                </ReferenceField>
                <BooleanField
                    source='is_show_entity_overview_derived'
                    label={translate('ra.input.is_show_entity_overview')}
                />
                <BooleanField
                    source='is_virtual_derived'
                    label={translate('ra.input.virtual_entity_product')}
                />
                <ReferenceField
                    label={translate('ra.input.refer_to', 1)}
                    source='refer_product_id'
                    reference='products'
                >
                    <TextField source='name' />
                </ReferenceField>
                <TextField
                    source='locality'
                    label={translate('ra.input.locality', 1)}
                />
                <BooleanField
                    source='is_exclude_locality_from_name'
                    label={translate('ra.input.exclude_locality')}
                />
                <TextField
                    source='locality_derived'
                    label={translate('ra.input.locality_derived', 1)}
                />
                <TextField
                    source='name_with_locality_derived'
                    label={translate('ra.input.name_with_locality_derived', 1)}
                />
                <TextField
                    source='locality_with_name_derived'
                    label={translate('ra.input.locality_with_name_derived', 1)}
                />
                <TextField
                    source='meta_description'
                    label={translate('ra.input.meta_description', 1)}
                />
                <RichTextField
                    source='description'
                    label={translate('ra.input.description', 1)}
                    className={classes.show}
                />
                <BooleanField
                    source='is_show_in_search'
                    label={translate('ra.input.is_show_in_search')}
                />
                <EmailField
                    source='email_derived'
                    label={translate('ra.input.email', 1)}
                />
                <EmailField
                    source='enquiry_form_email_derived'
                    label={translate('ra.input.enquiry_email', 1)}
                />
                <UrlField
                    source='website_url_derived'
                    label={translate('ra.input.website_url', 1)}
                />
                <UrlField
                    source='enquire_online_url_derived'
                    label={translate('ra.input.enquire_online_url', 1)}
                />
                <CustomInputs.PhoneNumberArrayField
                    source='phone_numbers_derived.data'
                    className={classes.show}
                />
                <CustomInputs.AddressArrayField
                    source='addresses_derived.data'
                    className={classes.show}
                />
                <div className={classes.show}>
                    <div className={classes.map} fullWidth>
                        <h4>{translate('ra.input.location', 1)}</h4>
                        <GoogleMapComponentWrapper
                            name='map'
                            lat={location.lat}
                            lng={location.lng}
                            zoom={18}
                            draggable={false}
                        />
                    </div>
                </div>
                <TextField
                    source='location_derived.coordinates[1]'
                    label={translate('ra.input.lat', 1)}
                />
                <TextField
                    source='location_derived.coordinates[0]'
                    label={translate('ra.input.lng', 1)}
                />
                <CustomInputs.OpenHoursArrayField
                    source='service_categories_open_hours.data'
                    showServiceCategory={true}
                    className={classes.show}
                />
                <CustomInputs.OnlinePlatformArrayField
                    source='online_platforms_derived.data'
                    className={classes.show}
                />
                <UrlField
                    source='web_cast_url'
                    label={translate('ra.input.web_cast_url', 1)}
                />
                <TextField
                    source='established'
                    label={translate('ra.input.established', 1)}
                />
                <TextField
                    source='dedicated'
                    label={translate('ra.input.dedicated', 1)}
                />
                <TextField
                    source='capacity'
                    label={translate('ra.input.capacity')}
                />
                <RichTextField
                    source='notes'
                    label={translate('ra.input.public_note', 2)}
                    className={classes.show}
                />
                <RichTextField
                    source='sub_notes'
                    label={translate('ra.input.public_note_footer', 2)}
                    className={classes.show}
                />
                <BooleanField source='is_active' label={translate('ra.input.active')} />
                <TextField
                    source='ordering'
                    label={translate('ra.input.ordering')}
                />
                <TextField
                    source='internal_notes'
                    label={translate('ra.input.internal_note', 2)}
                />
                <DateField
                    source='updated_at'
                    label={translate('ra.input.last_updated_date')}
                />
                <DateField
                    source='created_at'
                    label={translate('ra.input.publication_date')}
                />
                <h4>
                    {translate('ra.message.associated')}{' '}
                    {translate(
                        'ra.navigation.resources.service_category',
                        2
                    ).toLowerCase()}
                </h4>
                <ProductShowServiceCategoriesList />
                <h4>
                    {translate('ra.message.associated')}{' '}
                    {translate(
                        'ra.navigation.resources.demographic_focus',
                        2
                    ).toLowerCase()}
                </h4>
                <ProductShowDemographicFociList />
                <h4>
                    {translate('ra.message.associated')}{' '}
                    {translate('ra.navigation.resources.tag', 2).toLowerCase()}
                </h4>
                <ProductShowTagsList />
                <h4>
                    {translate('ra.message.immediate')}{' '}
                    {translate('ra.navigation.resources.child', 2).toLowerCase()}
                </h4>
                <ProductShowChildrenList />
                <h4>
                    {translate('ra.message.associated')}{' '}
                    {translate('ra.input.related').toLowerCase()}{' '}
                    {translate('ra.navigation.resources.product', 2).toLowerCase()}
                </h4>
                <ProductShowRelatedProductsList />
                <h4>
                    {translate('ra.message.associated')}{' '}
                    {translate('ra.navigation.resources.personnel', 2).toLowerCase()}
                </h4>
                <ProductShowPersonnelList />
                <MediaShowList />
                <MassTimeShowList />
            </SimpleShowLayout>
        </Show>
    );
};
