import * as React from 'react';
import { useTranslate } from 'ra-core';
import { useListContext, CreateButton } from 'react-admin';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const ListEmpty = () => {
    const { basePath } = useListContext();
    const translate = useTranslate();
    return (
        <Box textAlign='center' m={1}>
            <Typography variant='h4' paragraph>
                {translate('ra.message.none_found')}
            </Typography>
            <CreateButton basePath={basePath} />
        </Box>
    );
};
export default ListEmpty;
