import decodeJwt from 'jwt-decode';

const AuthProvider = {
    login: async ({ username, password }) => {
        const encodedCredentials = btoa(
            unescape(encodeURIComponent(`${username}:${password}`))
        );
        const request = new Request(
            `${process.env.REACT_APP_API_URL}/auth/jwt/token`,
            {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Basic ${encodedCredentials}`,
                    'Api-Version': 'v2',
                }),
            }
        );
        const response = await fetch(request);
        if (response.status < 200 || response.status >= 300) {
            throw new Error('ra.auth.sign_in_error');
        }
        const token = await response.json();
        const decodedToken = decodeJwt(token?.data?.jwt);
        // Confirm user has 'admin' permissions
        if (decodedToken.user.primary_role.name === 'admin') {
            localStorage.setItem('token', token.data.jwt);
            localStorage.setItem('locale', decodedToken.user.locale);
            window.location.reload(); // Apply the locale by reload...
        } else {
            throw new Error('ra.auth.permission_failure');
        }
    },

    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },

    changePassword: async ({
        password_current,
        password,
        password_confirmation,
    }) => {
        const identity = await AuthProvider.getIdentity();
        const body = {
            password_current,
            password,
            password_confirmation,
        };
        const request = new Request(
            `${process.env.REACT_APP_API_URL}/users/${identity.id}`,
            {
                method: 'PUT',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${AuthProvider.getToken()}`,
                    'Api-Version': 'v2',
                }),
                body: JSON.stringify(body),
            }
        );
        return fetch(request);
    },

    forgotPassword: async ({ username }) => {
        const body = {
            username,
        };
        const request = new Request(
            `${process.env.REACT_APP_API_URL}/password/forgotten`,
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Api-Version': 'v2',
                }),
                body: JSON.stringify(body),
            }
        );
        const response = await fetch(request);
        return response.json();
    },

    resetPassword: async ({ email, password, password_confirmation, token }) => {
        const body = {
            email,
            password,
            password_confirmation,
            token,
        };
        const request = new Request(
            `${process.env.REACT_APP_API_URL}/password/reset`,
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Api-Version': 'v2',
                }),
                body: JSON.stringify(body),
            }
        );
        return fetch(request);
    },

    getToken: () => {
        return localStorage.getItem('token');
    },

    checkAuth: async () => {
        const token = AuthProvider.getToken();
        if (token) {
            const decodedToken = decodeJwt(token);
            return new Date().setUTCSeconds(decodedToken.exp) < new Date()
                ? Promise.reject()
                : Promise.resolve();
        }
        return Promise.reject();
    },

    getUser: async () => {
        await AuthProvider.checkAuth();
        const token = AuthProvider.getToken();
        if (token) {
            const decodedToken = decodeJwt(token);
            return decodedToken.user && Date.now() < decodedToken.exp * 1000
                ? Promise.resolve(decodedToken.user)
                : Promise.reject();
        }
        return Promise.reject();
    },

    getPermissions: async () => {
        // Temp bug workaround for forgotten and reset password permissions
        // React admin is checking permissions and redirecting to login
        if (
            window.location.href.includes('/#/forgot') ||
            window.location.href.includes('/#/reset/')
        ) {
            // Return empty permissions without redirecting to login page
            return Promise.resolve([]);
        }

        const user = await AuthProvider.getUser();
        if (user) {
            return user.primary_role.name === 'admin'
                ? user.roles.map((role) => role.name)
                : Promise.reject([]);
        }
        return Promise.reject([]);
    },

    hasPermission: async (roleName) => {
        try {
            const permissions = await AuthProvider.getPermissions();
            return permissions.filter((role) => role.name === roleName).length > 0;
        } catch (e) {
            return false;
        }
    },

    getIdentity: async (error) => {
        const user = await AuthProvider.getUser();
        if (user) {
            const fullName = user.last_name
                ? `${user.first_name} ${user.last_name}`
                : user.first_name;
            return Promise.resolve({
                fullName,
                id: user.id,
                avatar: null,
            });
        }
        return Promise.reject(error);
    },

    checkError: (error) => {
        const { status } = error;
        if (status === 401 || status === 403) {
            return AuthProvider.logout();
        }
        return Promise.resolve();
    },
};

export default AuthProvider;
