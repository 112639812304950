import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import AuthProvider from './AuthProvider';

const apiUrl = process.env.REACT_APP_API_URL;
const httpClient = fetchUtils.fetchJson;
const headers = new Headers({
    Accept: 'application/json',
    Authorization: `Bearer ${AuthProvider.getToken()}`,
    'Api-Version': 'v2',
});

const addMediaWith = (query, params) => {
    if (params.with_media) {
        query.with_media = params.with_media;
        query.with_crop = params?.with_crop;
    }
    return query;
};

const DataProvider = {
    getList: (resource, params) => {
        let endpoint = resource;
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const sortPrefix = order === 'ASC' ? '' : '-';
        const filter = { ...params.filter }; // clone properties
        Object.keys(filter).forEach((key) => {
            switch (key) {
                case 'service_category_path':
                    // Handle service category filter - change API endpoint
                    endpoint = `service-categories/${filter[key]
                        .split('|||')
                        .pop()}/${endpoint}?cascade`;
                    delete filter[key];
                    break;
                case 'trash':
                    if (filter[key] === 1) {
                        params.trash = 1;
                    }
                    break;
                case 'active':
                    params.is_active = 1;
                    delete filter[key];
                    break;
                case 'inactive':
                    params.is_active = 0;
                    delete filter[key];
                    break;
                case 'search_keyword_term':
                    if (filter[key] && typeof filter[key] === 'string') {
                        // Set search to exact
                        filter[key] = `"${filter[key].replace('"', '')}"`;
                    }
                    break;
                default:
                    if (filter[key] && key !== 'with') {
                        // Add wildcard to general filters
                        filter[key] = `*${filter[key]}*`;
                    }
            }
        });

        const query = {
            sort: sortPrefix + field,
            page: parseInt(page, 10),
            limit: parseInt(perPage, 10),
            ...filter,
        };
        if (params.flatten) {
            query.flatten = null;
        }
        if (Object.prototype.hasOwnProperty.call(params, 'is_active')) {
            query.is_active = params.is_active;
        }
        if (params.trash) {
            query.trash_show = 1;
        }
        if (params.with) {
            query.with = params.with;
        }

        endpoint += !endpoint.includes('?') ? '?' : '&';

        const url = `${apiUrl}/${endpoint}${stringify(
            addMediaWith(query, params)
        )}&search_keyword_relevance=${process.env.REACT_APP_SEARCH_RELEVANCE}`;

        return httpClient(url, {
            headers,
            method: 'GET',
        }).then(({ json }) => ({
            data: json.data,
            total: parseInt(json.meta.pagination.total, 10),
        }));
    },

    getOne: (resource, params) => {
        const query = {};
        if (params.with) {
            query.with = params.with;
        }

        return httpClient(
            `${apiUrl}/${resource}/${params.id}?${stringify(
                addMediaWith(query, params)
            )}`,
            {
                headers,
                method: 'GET',
            }
        ).then(({ json }) => ({
            data: json.data,
        }));
    },

    getDownload: (resource, params) =>
        fetch(`${apiUrl}/${resource}/${params.id}`, {
            headers,
            method: 'GET',
        }).then((response) => response.blob()),

    getFile: (url) =>
        fetch(url, {
            method: 'GET',
        }).then((response) => response.blob()),

    getMany: (resource, params) => {
        const query = {
            id: params.ids.join(','),
            with: 'category',
        };

        const url = `${apiUrl}/${resource}?${stringify(
            addMediaWith(query, params)
        )}&flatten&limit=-1`;
        return httpClient(url, {
            headers,
            method: 'GET',
        }).then(({ json }) => ({
            data: json.data,
        }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const sortPrefix = order === 'ASC' ? '' : '-';

        Object.keys(params.filter).forEach((key) => {
            // Add wildcard to general filters
            if (key !== 'with') {
                // Set search to exact
                params.filter[key] = `"${params.filter[key].replace('"', '')}"`;
            }
        });

        const query = {
            sort: sortPrefix + field,
            page: parseInt(page, 10),
            limit: parseInt(perPage, 10),
            ...params.filter,
        };
        if (params.with) {
            query.with = params.with;
        }

        const url = `${apiUrl}/${resource}/${params.id}/${params.reference
            }?${stringify(addMediaWith(query, params))}`;

        return httpClient(url, {
            headers,
            method: 'GET',
        }).then(({ json }) => ({
            data: json.data,
            total: parseInt(json.meta.pagination.total, 10),
        }));
    },

    update: (resource, params) => {
        params.data._method = 'PUT';
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            headers,
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: json.data,
        }));
    },

    updateMany: () => null, // Todo: Implement updateMany

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            headers,
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.data.id },
        })),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            headers,
            method: 'DELETE',
        }).then(() => ({ data: params.id })),

    deleteMany: (resource, params) => {
        // Build batch payload
        const query = params.ids.map((i) => {
            return {
                _method: 'DELETE',
                id: i,
            };
        });
        return httpClient(`${apiUrl}/${resource}?batch`, {
            headers,
            method: 'POST',
            body: JSON.stringify(query),
        }).then(() => ({ data: params.ids }));
    },

    deletePermanently: (resource, params) =>
        httpClient(
            `${apiUrl}/${resource.replace(/^\/+/, '')}/${params.id}?trash_empty`,
            {
                headers,
                method: 'DELETE',
            }
        ).then(() => ({ data: params.id })),

    deletePermanentlyBulk: (resource, params) => {
        // Build batch payload
        const query = params.ids.map((i) => {
            return {
                _method: 'DELETE',
                id: i,
                trash_empty: true,
            };
        });
        return httpClient(`${apiUrl}/${resource.replace(/^\/+/, '')}?batch`, {
            headers,
            method: 'POST',
            body: JSON.stringify(query),
        }).then(() => ({ data: params.ids }));
    },

    restoreDeleted: (resource, params) =>
        httpClient(
            `${apiUrl}/${resource.replace(/^\/+/, '')}/${params.id}?trash_restore`,
            {
                headers,
                method: 'PATCH',
            }
        ).then(() => ({ data: params.id })),

    restoreDeletedBulk: (resource, params) => {
        // Build batch payload
        const query = params.ids.map((i) => {
            return {
                _method: 'PATCH',
                id: i,
                trash_restore: true,
            };
        });
        return httpClient(`${apiUrl}/${resource.replace(/^\/+/, '')}?batch`, {
            headers,
            method: 'POST',
            body: JSON.stringify(query),
        }).then(() => ({ data: params.ids }));
    },

    getMedia: (resource, id, collection) => {
        const query = {
            with_media: collection,
            with_media_crop: '1:1',
        };

        return httpClient(`${apiUrl}/${resource}/${id}?${stringify(query)}`, {
            headers,
            method: 'GET',
        }).then(({ json }) => ({
            data: json.data,
        }));
    },

    createSubResource: (resource, subResource, id, params) =>
        httpClient(`${apiUrl}/${resource}/${id}/${subResource}`, {
            // Regenerate headers to support multipart/form-data for uploads
            headers: new Headers({
                Accept: 'application/json',
                Authorization: `Bearer ${AuthProvider.getToken()}`,
                'Api-Version': 'v2',
            }),
            method: 'POST',
            body: params.data,
        }).then(({ json }) => ({
            data: { ...params.data, id: json.data.id },
        })),

    updateSubResource: (resource, subResource, id, subResourceId, params) => {
        params.data._method = 'PATCH';
        return httpClient(
            `${apiUrl}/${resource}/${id}/${subResource}/${subResourceId}`,
            {
                headers,
                method: 'POST',
                body: JSON.stringify(params.data),
            }
        ).then(({ json }) => ({
            data: json.data,
        }));
    },

    updateSubResourceBatch: (resource, subResource, id, params) => {
        return httpClient(`${apiUrl}/${resource}/${id}/${subResource}?batch`, {
            headers,
            method: 'POST',
            body: JSON.stringify(params),
        }).then(({ json }) => ({
            data: json.data,
        }));
    },
};

export default DataProvider;
