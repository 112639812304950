import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import { useQuery, Loading } from 'react-admin';

const useStyles = makeStyles(() => ({
    indent: {
        marginLeft: '2rem',
    },
    row: {
        marginBottom: '1rem',
    },
}));

const dayNames = [
    'ra.input.days_plural.monday',
    'ra.input.days_plural.tuesday',
    'ra.input.days_plural.wednesday',
    'ra.input.days_plural.thursday',
    'ra.input.days_plural.friday',
    'ra.input.days_plural.saturday',
    'ra.input.days_plural.sunday',
];

export const MassTimeShowList = ({ resource, record }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const { data, loading, error } = useQuery({
        resource,
        type: 'getOne',
        payload: {
            id: record.id,
            with: 'mass_times_grouped_derived',
        },
    });
    if (loading) {
        return (
            <div>
                <h4>{translate('ra.navigation.resources.mass_time', 2)}</h4>
                <Loading />
            </div>
        );
    }
    if (error) {
        return (
            <div>
                <h4>{translate('ra.navigation.resources.mass_time', 2)}</h4>
                <p>{error.message}</p>
            </div>
        );
    }
    if (data.mass_times_grouped_derived?.data.length === 0) {
        return (
            <div>
                <h4>{translate('ra.navigation.resources.mass_time', 2)}</h4>
                <div>{translate('ra.navigation.no_results')}</div>
            </div>
        );
    }

    const renderTimes = (time) => {
        const repetitionMsg = time.repetition_description_derived
            ? ` - ${time.repetition_description_derived}`
            : '';
        const typeMsg = time.mass_type_name_derived
            ? ` (${time.mass_type_name_derived})`
            : '';
        const startDateMsg = time.repetition
            ? `${translate('ra.input.start_date', 1)}: ${time.date_str_derived}`
            : '';
        const endDateMsg =
            time.repetition && time.end_date
                ? `${translate('ra.input.end_date', 1)}: ${time.end_date_str_derived}`
                : '';
        const notesMsg = time.notes
            ? `${translate('ra.input.note', 2)}: ${time.notes}`
            : '';
        const cancellationsMsg = time.cancellations?.length
            ? translate('ra.input.helper.has_cancellation', 2)
            : '';
        const isWebCastMsg = time.is_web_cast
            ? translate('ra.input.helper.is_web_cast')
            : '';
        const isActive = time.is_active
            ? translate('ra.input.active')
            : translate('ra.input.inactive').toUpperCase();
        return (
            <div>
                <div>
                    {time.time_str_derived}
                    {repetitionMsg}
                    {typeMsg}
                </div>
                <small className={classes.small}>
                    <div>{time.mass_language_name_derived}</div>
                    <div>{startDateMsg}</div>
                    <div>{endDateMsg}</div>
                    <div>{notesMsg}</div>
                    <div>{cancellationsMsg}</div>
                    <div>{isWebCastMsg}</div>
                    <div>{isActive}</div>
                </small>
            </div>
        );
    };

    const renderSeason = (seasons, seasonName) => {
        return (
            <div>
                <h4>{seasonName}</h4>
                {seasons &&
                    Object.keys(seasons).map((day) => {
                        const times = seasons[day];
                        const specialDateName =
                            times[0]['date_str_derived'] !==
                                times[0]['special_date_name_derived']
                                ? ` (${times[0]['special_date_name_derived']})`
                                : '';
                        return (
                            <div key={day} className={classes.indent}>
                                <strong>
                                    {+day
                                        ? translate(dayNames[+day - 1])
                                        : `${times[0]['date_str_derived']} ${translate(
                                            'ra.action.only'
                                        ).toLowerCase()}${specialDateName}`}
                                </strong>
                                <ul>
                                    {times.map((time, key) => (
                                        <li key={`mass-time-${key}`} className={classes.row}>
                                            {renderTimes(time)}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        );
                    })}
            </div>
        );
    };

    return (
        <div>
            <h4>{translate('ra.navigation.resources.mass_time', 2)}</h4>
            {data.mass_times_grouped_derived.data?.SUNDAY &&
                renderSeason(data.mass_times_grouped_derived.data.SUNDAY, 'Sunday')}
            {data.mass_times_grouped_derived.data?.WEEKDAY &&
                renderSeason(data.mass_times_grouped_derived.data.WEEKDAY, 'Weekday')}
            {data.mass_times_grouped_derived.data?.SPECIAL &&
                Object.keys(data.mass_times_grouped_derived.data.SPECIAL).map((o) =>
                    renderSeason(data.mass_times_grouped_derived.data?.SPECIAL[o], o)
                )}
        </div>
    );
};
