import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import FormTheme from '../../themes/DefaultThemeForm';
import {
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    BooleanField,
    RichTextField,
    EmailField,
    UrlField,
    ReferenceField,
} from 'react-admin';
import CustomInputs from '../CustomInputs';
import ShowActions from '../ShowActions';
import GoogleMapComponentWrapper from '../MapField';
import { EntityShowProductsList } from './EntityShowProductsList';
import { MediaShowList } from '../media/MediaShowList';

const useStyles = makeStyles(() => FormTheme.styles, {
    name: 'EntityForm',
});

export const EntityShow = (props) => {
    const classes = useStyles();
    const translate = useTranslate();

    const WithProps = ({ record }) => {
        if (!isLocationSet && record.location) {
            if (record.location?.coordinates) {
                setLocation({
                    lat: record.location.coordinates[1],
                    lng: record.location.coordinates[0],
                });
            }
            setIsLocationSet(true);
        }
        return '';
    };
    const [isLocationSet, setIsLocationSet] = useState(false);
    const [location, setLocation] = useState({
        lat: null,
        lng: null,
    });

    const ShowTitle = ({ record }) => {
        return (
            <h3 className={classes.formTitle} fullWidth>
                {translate('ra.action.showing')}{' '}
                {translate('ra.navigation.resources.entity', 1)}: {record.name}
            </h3>
        );
    };
    return (
        <Show
            {...props}
            actions={<ShowActions />}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.configuration'
            )}: ${translate('ra.navigation.resources.entity', 2)}`}
        >
            <SimpleShowLayout>
                <WithProps />
                <ShowTitle />
                <TextField source='id' label={translate('ra.input.id', 1)} />
                <TextField source='slug' label={translate('ra.input.slug', 1)} />
                <ReferenceField
                    label={translate('ra.navigation.resources.region', 1)}
                    source='region_id'
                    reference='regions'
                >
                    <TextField source='name' />
                </ReferenceField>
                <TextField
                    source='locality'
                    label={translate('ra.input.locality', 1)}
                />
                <BooleanField
                    source='is_exclude_locality_from_name'
                    label={translate('ra.input.exclude_locality')}
                />
                <TextField
                    source='locality_derived'
                    label={translate('ra.input.locality_derived', 1)}
                />
                <TextField
                    source='name_with_locality_derived'
                    label={translate('ra.input.name_with_locality_derived', 1)}
                />
                <TextField
                    source='locality_with_name_derived'
                    label={translate('ra.input.locality_with_name_derived', 1)}
                />
                <TextField
                    source='meta_description'
                    label={translate('ra.input.meta_description', 1)}
                />
                <RichTextField
                    source='description'
                    label={translate('ra.input.description', 1)}
                    className={classes.show}
                />
                <BooleanField
                    source='is_show_in_search'
                    label={translate('ra.input.is_show_in_search')}
                />
                <BooleanField
                    source='is_show_overview'
                    label={translate('ra.input.is_show_overview')}
                />
                <BooleanField
                    source='is_virtual'
                    label={translate('ra.input.virtual_entity')}
                />
                <EmailField source='email' label={translate('ra.input.email', 1)} />
                <EmailField
                    source='enquiry_form_email'
                    label={translate('ra.input.enquiry_email', 1)}
                />
                <UrlField
                    source='website_url'
                    label={translate('ra.input.website_url', 1)}
                />
                <UrlField
                    source='enquire_online_url'
                    label={translate('ra.input.enquire_online_url', 1)}
                />
                <CustomInputs.PhoneNumberArrayField className={classes.show} />
                <CustomInputs.AddressArrayField className={classes.show} />
                <div className={classes.show}>
                    <div className={classes.map} fullWidth>
                        <h4>{translate('ra.input.location', 1)}</h4>
                        <GoogleMapComponentWrapper
                            name='map'
                            lat={location.lat}
                            lng={location.lng}
                            zoom={18}
                            draggable={false}
                        />
                    </div>
                </div>
                <TextField
                    source='location.coordinates[1]'
                    label={translate('ra.input.lat', 1)}
                />
                <TextField
                    source='location.coordinates[0]'
                    label={translate('ra.input.lng', 1)}
                />
                <CustomInputs.OpenHoursArrayField className={classes.show} />
                <CustomInputs.OnlinePlatformArrayField className={classes.show} />
                <RichTextField
                    source='notes'
                    label={translate('ra.input.public_note', 2)}
                    className={classes.show}
                />
                <TextField
                    source='ordering'
                    label={translate('ra.input.ordering', 2)}
                />
                <BooleanField source='is_active' label={translate('ra.input.active')} />
                <TextField
                    source='internal_notes'
                    label={translate('ra.input.internal_note', 2)}
                />
                <DateField
                    source='updated_at'
                    label={translate('ra.input.last_updated_date')}
                />
                <DateField
                    source='created_at'
                    label={translate('ra.input.publication_date')}
                />
                <h4>
                    {translate('ra.message.associated')}{' '}
                    {translate('ra.navigation.resources.product', 2).toLowerCase()}
                </h4>
                <EntityShowProductsList />
                <MediaShowList />
            </SimpleShowLayout>
        </Show>
    );
};
