import { useTranslate } from 'ra-core';

const ListActiveField = ({ record = {}, source }) => {
    const translate = useTranslate();
    return record[source]
        ? translate('ra.boolean.true')
        : translate('ra.boolean.false');
};

export default ListActiveField;
