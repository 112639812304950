import React, { createContext, useReducer, useEffect } from 'react';
import { MassTimeReducer, initialState } from './MassTimeReducer';
import { useTranslate, useSafeSetState } from 'ra-core';
import { makeStyles } from '@material-ui/core/styles';
import DataProvider from '../../providers/DataProvider';
import MassTimeCreate from './MassTimeCreate';
import CircularProgress from '@material-ui/core/CircularProgress';
import MassTimeAccordion from './MassTimeAccordion';

const useStyles = makeStyles(() => ({
    loading: {
        paddingTop: '2rem',
    },
}));

export const MassTimeContext = createContext({
    state: initialState,
    dispatch: () => null,
});

export const MassTimeProvider = (data) => {
    const classes = useStyles();
    const translate = useTranslate();
    const [state, dispatch] = useReducer(MassTimeReducer, initialState);
    const [isLoading, setIsLoading] = useSafeSetState(true);
    const [massSeasonsWithRegular, setMassSeasonsWithRegular] = useSafeSetState(
        []
    );

    useEffect(() => {
        DataProvider.getList('mass-seasons', {
            pagination: { page: 1, perPage: -1 },
            sort: { field: 'name', order: 'ASC' },
            filter: { active: true },
        }).then((response) => {
            setMassSeasonsWithRegular(
                [{ name: 'Sunday' }, { name: 'Weekday' }]
                    .concat(response?.data)
                    .map((s) => {
                        return {
                            id: s.id ?? null,
                            name: s.name,
                        };
                    })
            );
            setIsLoading(false);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const hasMassCentreServiceCategory = data.record.service_categories_ids.some(
        (i) =>
            process.env.REACT_APP_MASS_CENTRE_SERVICE_CATEGORY_IDS.split(
                '|'
            ).includes(i)
    );

    return hasMassCentreServiceCategory ? (
        <MassTimeContext.Provider value={[state, dispatch]} data={data}>
            <MassTimeCreate data={data} />
            {!isLoading ? (
                massSeasonsWithRegular.map((season, key) => (
                    <MassTimeAccordion
                        data={data}
                        season={season}
                        key={key}
                    ></MassTimeAccordion>
                ))
            ) : (
                <div className={classes.loading}>
                    <CircularProgress size={18} thickness={2} />
                </div>
            )}
        </MassTimeContext.Provider>
    ) : (
        <p fullWidth>
            <strong>{translate('ra.action.note')}</strong>{' '}
            {translate('ra.message.mass_times_not_allowed')}
        </p>
    );
};
