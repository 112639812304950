import React from 'react';
import { SaveButton, Toolbar } from 'react-admin';

const CustomToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

export default CustomToolbar;
