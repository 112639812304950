import * as React from 'react';
import { useTranslate } from 'ra-core';
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    ReferenceField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
} from 'react-admin';
import ListActions from '../ListActions';
import ListBulkActions from '../ListBulkActions';
import ListFilter from '../ListFilter';
import ListPagination from '../ListPagination';
import ListActiveField from '../ListActiveField';
import ListEmpty from '../ListEmpty';
import ResourceManagementButtons from '../ResourceManagementButtons';

export const UserList = (props) => {
    const translate = useTranslate();
    return (
        <List
            {...props}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.resources.user',
                2
            )}`}
            actions={<ListActions />}
            filters={<ListFilter noSlug={true} noActive={true} />}
            bulkActionButtons={<ListBulkActions />}
            perPage={15}
            sort={{ field: 'username', order: 'ASC' }}
            pagination={<ListPagination />}
            empty={<ListEmpty />}
        >
            <Datagrid>
                <TextField
                    source='username'
                    label={translate('ra.input.username', 1)}
                />
                <TextField
                    source='full_name_reversed_derived'
                    label={translate('ra.input.name', 1)}
                />
                <EmailField source='email' label={translate('ra.input.email', 1)} />
                <ListActiveField
                    source='is_active'
                    label={translate('ra.input.enabled')}
                />
                <ReferenceField
                    label={translate('ra.input.primary_role', 1)}
                    source='primary_role_id'
                    reference='roles'
                >
                    <TextField source='name' />
                </ReferenceField>
                <ReferenceArrayField
                    label={translate('ra.navigation.resources.role', 2)}
                    source='roles_ids'
                    reference='roles'
                    sortable={false}
                >
                    <SingleFieldList>
                        <ChipField source='name' />
                    </SingleFieldList>
                </ReferenceArrayField>
                <ResourceManagementButtons />
            </Datagrid>
        </List>
    );
};
