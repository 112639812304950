import PolyglotI18nProvider from 'ra-i18n-polyglot';
import en_US from './en_US';
import en_AU from './en_AU';

const messages = {
    en_AU,
    en_US,
    null: en_AU,
};

const i18nProvider = PolyglotI18nProvider(
    () => messages[localStorage.getItem('locale')]
);

export default i18nProvider;
