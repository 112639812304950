import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        background: {
            primary: '#fafafa',
        },
        primary: {
            light: '#58a5f0',
            main: '#3490DC',
            dark: '#004c8c',
            contrastText: '#fff',
        },
        secondary: {
            light: '#58a5f0',
            main: '#3490DC',
            dark: '#004c8c',
            contrastText: '#fff',
        },
        maps: {
            fill: '#ff0000',
            stroke: '#fe7569',
        },
        text: {
            primary: '#4a4a4a',
        },
        row: {
            background: '#f5f5f5',
            border: '#eee',
        },
    },
});

export default theme;
