import React from 'react';
import { Login, LoginForm } from 'react-admin';
import theme from '../themes/DefaultTheme';
import { withStyles } from '@material-ui/core/styles';
import ForgottenPasswordLink from '../components/ForgottenPasswordLink';

const styles = {
    main: { background: theme.palette.background.primary },
    avatar: {
        background: `url(${process.env.PUBLIC_URL}logo192.png)`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        margin: '1rem 0 0',
        height: 96,
        width: '100%',
    },
    icon: { display: 'none' },
};

const CustomLoginForm = withStyles({
    button: { margin: '0 .5rem' },
})(LoginForm);

const LoginPage = (props) => (
    <Login {...props}>
        <CustomLoginForm key='login' />
        <ForgottenPasswordLink key='forgottenPassword' />
    </Login>
);

export default withStyles(styles)(LoginPage);
