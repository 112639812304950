import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useSafeSetState } from 'ra-core';
import FormTheme from '../../themes/DefaultThemeForm';
import {
    useNotify,
    useRefresh,
    Edit,
    TabbedForm,
    ShowButton,
    FormTab,
} from 'react-admin';
import CustomInputs from '../CustomInputs';
import CustomToolbar from '../CustomToolbar';
import { MassTimeProvider } from '../mass-time/MassTimeProvider';
import { MediaProvider } from '../media/MediaProvider';
import EditActions from '../EditActions';
import ValidationHelper from '../../helpers/ValidationHelper';
import dateFormat from 'dateformat';

const useStyles = makeStyles(() => FormTheme.styles, {
    name: 'ProductForm',
});

export const ProductEdit = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const onSuccess = () => {
        setIsOpenHoursSet(false);
        notify(translate('ra.notification.updated', 1));
        refresh();
    };

    // Set initial states
    const WithProps = ({ record }) => {
        if (!isOpenHoursSet && record.service_categories_open_hours) {
            record.service_categories_open_hours.data.map((d) => {
                if (!(d.open_time instanceof Date)) {
                    d.open_time = new Date(`2016-06-10T${d.open_time}`);
                }
                if (!(d.close_time instanceof Date)) {
                    d.close_time = new Date(`2016-06-10T${d.close_time}`);
                }
                return d;
            });
            setIsOpenHoursSet(true);
        }
        if (!categoryId) {
            setCategoryId(record.category_id);
        }
        if (!serviceCategoryIds) {
            setServiceCategoryIds(record.service_categories_ids);
        }
        return '';
    };

    const validate = (values) => {
        const errors = {
            ...ValidationHelper.ValidateRequired(
                values,
                [
                    'category_id',
                    'entity_id',
                    'service_categories_ids',
                    'name',
                    'slug',
                    'meta_description',
                ],
                translate('ra.validation.required')
            ),
            ...ValidationHelper.ValidateLength(
                values,
                'meta_description',
                160,
                translate('ra.validation.maxLength', {
                    max: 160,
                })
            ),
            ...ValidationHelper.ValidatePhoneNumbers(
                values,
                'phone_numbers',
                translate('ra.validation.required')
            ),
            ...ValidationHelper.ValidateAddresses(
                values,
                'addresses',
                translate('ra.validation.required')
            ),
            ...ValidationHelper.ValidateOnlinePlatforms(
                values,
                'online_platforms',
                translate('ra.validation.required')
            ),
        };

        if (
            values.service_categories_open_hours?.data.length > 0 &&
            Array.isArray(values.service_categories_open_hours.data)
        ) {
            const openHoursErrors = { data: [] };
            values.service_categories_open_hours.data.forEach((o, i) => {
                if (o && (o?.open_time || o?.close_time)) {
                    const thisError = {};
                    if (!o?.service_category_id) {
                        thisError.service_category_id = translate('ra.validation.required');
                    }
                    if (!o?.day && o?.day !== 0) {
                        thisError.day = translate('ra.validation.required');
                    }

                    if (!o.open_time) {
                        thisError.open_time = translate('ra.validation.required');
                    } else if (o.close_time && o.open_time >= o.close_time) {
                        thisError.open_time = translate('ra.validation.earlier_time');
                    }

                    if (!o.close_time) {
                        thisError.close_time = translate('ra.validation.required');
                    } else if (o.open_time && o.open_time >= o.close_time) {
                        thisError.close_time = translate('ra.validation.later_time');
                    }

                    if (Object.keys(thisError).length) {
                        openHoursErrors.data[i] = thisError;
                    }
                }
            });
            if (openHoursErrors.data.length) {
                errors.service_categories_open_hours = openHoursErrors;
            }
        }

        return errors;
    };

    const transform = (data) => {
        const openHours = data?.service_categories_open_hours?.data?.map((o) => {
            const openHourData = {};
            if (o) {
                openHourData.service_category_id = o.service_category_id;
                openHourData.day = parseInt(o.day, 10) ?? null;
                openHourData.notes = o.notes ?? null;
                if (o.open_time) {
                    openHourData.open_time = dateFormat(
                        o.open_time.setSeconds(0),
                        'HH:MM:ss'
                    );
                }
                if (o.close_time) {
                    openHourData.close_time = dateFormat(
                        o.close_time.setSeconds(0),
                        'HH:MM:ss'
                    );
                }
            }
            return openHourData;
        });
        return {
            ...data,
            phone_numbers: {
                data: data?.phone_numbers?.data?.filter((p) => p) ?? [],
            },
            addresses: { data: data?.addresses?.data?.filter((a) => a) ?? [], },
            service_categories_open_hours: {
                data: openHours?.filter((o) => o) ?? [],
            },
            online_platforms: {
                data: data?.online_platforms?.data?.filter((o) => o) ?? [],
            },
            role_personnel_ids: data?.role_personnel_ids ?? [],
            is_exclude_locality_from_name: data.is_exclude_locality_from_name ? 1 : 0,
            is_show_in_search: data.is_show_in_search ? 1 : 0,
            is_active: data.is_active ? 1 : 0,
        };
    };

    const [isOpenHoursSet, setIsOpenHoursSet] = useState(null);
    const [categoryId, setCategoryId] = useSafeSetState(null);
    const [serviceCategoryIds, setServiceCategoryIds] = useSafeSetState(null);
    const filter = () => {
        const filter = { flatten: null };
        if (categoryId) {
            return {
                ...filter,
                category_id: categoryId,
            };
        } else {
            return filter;
        }
    };

    return (
        <Edit
            {...props}
            actions={<EditActions />}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.directory'
            )}: ${translate('ra.navigation.resources.product', 2)}`}
            transform={transform}
            onSuccess={onSuccess}
            onFailure={(e) => {
                notify(ValidationHelper.FormatApiErrors(e));
            }}
            undoable={false}
        >
            <TabbedForm
                validate={validate}
                warnWhenUnsavedChanges
                toolbar={<CustomToolbar alwaysEnableSaveButton />}
                redirect='list'
                className={classes.form}
            >
                <FormTab label='Overview'>
                    <CustomInputs.CategoryReferenceInput
                        onChange={(e) => setCategoryId(e.target.value)}
                    />
                    <CustomInputs.ProductReferenceInput
                        label={translate('ra.input.parent', 1)}
                        source='parent_id'
                        filter={filter()}
                        allowEmpty
                    />
                    <CustomInputs.EntityReferenceInput />
                    <CustomInputs.ServiceCategoryArrayInput
                        filter={filter()}
                        onChange={(e) => setServiceCategoryIds(e.target.value)}
                    />
                    <CustomInputs.ProductReferenceInput
                        label={translate('ra.input.refer_to', 1)}
                        source='refer_product_id'
                        filter={filter()}
                        helperText={translate('ra.input.helper.refer_to')}
                        allowEmpty
                    />
                    <CustomInputs.NameTextInput />
                    <CustomInputs.SlugTextInput />
                    <CustomInputs.LocalityTextInput />
                    <CustomInputs.IsExcludeLocalityBooleanInput fullWidth />
                    <CustomInputs.MetaDescriptionTextInput />
                    <CustomInputs.DescriptionRichTextInput />
                    <CustomInputs.TagsInput filter={filter()} allowEmpty />
                    <CustomInputs.DemographicFociInput filter={filter()} allowEmpty />
                    <CustomInputs.ProductArrayInput
                        label={`${translate('ra.input.related', 1)} ${translate(
                            'ra.navigation.resources.product',
                            2
                        ).toLowerCase()}`}
                        filter={{ flatten: null }}
                        allowEmpty
                    />
                    <CustomInputs.EstablishedTextInput />
                    <CustomInputs.DedicatedTextInput />
                    <CustomInputs.CapacityNumberInput />
                    <CustomInputs.KeywordTermsTextInput />
                    <CustomInputs.NotesRichTextInput />
                    <CustomInputs.NotesRichTextInput
                        label={translate('ra.input.public_note_footer', 2)}
                        source='sub_notes'
                        helperText={translate('ra.input.helper.public_note_footer')}
                    />
                    <CustomInputs.IsShowInSearchBooleanInput fullWidth />
                    <CustomInputs.OrderingNumberInput
                        helperText={translate('ra.input.helper.optional_ordering')}
                    />
                    <CustomInputs.InternalNotesTextInput />
                </FormTab>
                <FormTab label={translate('ra.input.contact', 2)}>
                    <p fullWidth>
                        <strong>{translate('ra.message.bubbled_heading')}</strong>
                        <br />
                        {translate('ra.message.bubbled')}
                        <br />
                        {translate('ra.message.override')}
                        <br />
                        <ShowButton basePath={props.basePath} record={props} />
                    </p>
                    <CustomInputs.EmailTextInput />
                    <CustomInputs.EnquiryFormEmailTextInput />
                    <CustomInputs.WebsiteUrlTextInput />
                    <CustomInputs.EnquiryFormUrlTextInput />
                    <CustomInputs.PhoneNumberArrayInput
                        filter={{ model: 'product', attribute: 'PhoneNumbers' }}
                    />
                </FormTab>
                <FormTab label={translate('ra.input.location', 1)}>
                    <CustomInputs.PlacesAvailableRichTextInput />
                    <p fullWidth>
                        <strong>{translate('ra.message.bubbled_heading')}</strong>
                        <br />
                        {translate('ra.message.bubbled')}
                        <br />
                        {translate('ra.message.override')}
                        <br />
                        <ShowButton basePath={props.basePath} record={props} />
                    </p>
                    <WithProps />
                    <CustomInputs.RegionReferenceInput />
                    <CustomInputs.AddressArrayInput
                        filter={{ model: 'product', attribute: 'Addresses' }}
                    />
                </FormTab>
                <FormTab label={translate('ra.input.open_hour', 2)}>
                    <CustomInputs.OpenHoursArrayInput
                        filter={{ id: serviceCategoryIds, flatten: null }}
                        source='service_categories_open_hours'
                        showServiceCategory={true}
                    />
                </FormTab>
                <FormTab label={translate('ra.input.online_platform', 2)}>
                    <CustomInputs.WebCastUrlTextInput />
                    <p fullWidth>
                        <strong>{translate('ra.message.bubbled_heading')}</strong>
                        <br />
                        {translate('ra.message.bubbled')}
                        <br />
                        {translate('ra.message.override')}
                        <br />
                        <ShowButton basePath={props.basePath} record={props} />
                    </p>
                    <CustomInputs.OnlinePlatformArrayInput
                        filter={{ model: 'product', attribute: 'OnlinePlatforms' }}
                    />
                </FormTab>
                <FormTab label={translate('ra.navigation.resources.personnel', 2)}>
                    <CustomInputs.RolePersonnelArrayInput
                        filter={{ model: 'personnel', attribute: 'Role' }}
                    />
                </FormTab>
                <FormTab label={translate('ra.navigation.resources.mass_time', 2)}>
                    <MassTimeProvider data={props} />
                </FormTab>
                <FormTab label={translate('ra.input.media_collection', 2)}>
                    <MediaProvider data={props} />
                </FormTab>
                <FormTab label={translate('ra.input.activity_state', 1)}>
                    <p fullWidth>
                        <strong>{translate('ra.action.note')}</strong>{' '}
                        {translate('ra.message.activity_state')}
                    </p>
                    <CustomInputs.IsActiveBooleanInput
                        fullWidth
                        helperText={translate('ra.message.activity_state_confirm')}
                    />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};
