import React from 'react';
import { Route } from 'react-router-dom';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import ChangePassword from './pages/ChangePassword';

export default [
    <Route
        exact
        path='/forgot'
        component={ForgotPassword}
        key='forgot'
        noLayout
    />,
    <Route path='/reset/:token' component={ResetPassword} key='reset' noLayout />,
    <Route
        exact
        path='/change-password'
        component={ChangePassword}
        key='change-password'
    />,
];
