import React, { useReducer } from 'react';
import { useTranslate } from 'ra-core';
import { makeStyles } from '@material-ui/core/styles';
import FormTheme from '../themes/DefaultThemeForm';
import {
    TextInput,
    PasswordInput,
    NumberInput,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    RadioButtonGroupInput,
    CheckboxGroupInput,
    ReferenceArrayInput,
    SelectArrayInput,
    AutocompleteInput,
    ArrayInput,
    AutocompleteArrayInput,
    SimpleFormIterator,
    FormDataConsumer,
    TextField,
    ArrayField,
    Datagrid,
    ReferenceField,
    ListContextProvider,
    UrlField,
    FunctionField,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import RichTextHelper from '../helpers/RichTextHelper';
import PathHelper from '../helpers/PathHelper';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    DatePicker,
} from '@material-ui/pickers';
import resolvePath from 'object-resolve-path';
import dateFormat from 'dateformat';

const dayChoices = [
    { id: '0', name: 'ra.input.days.every' },
    { id: '1', name: 'ra.input.days.monday' },
    { id: '2', name: 'ra.input.days.tuesday' },
    { id: '3', name: 'ra.input.days.wednesday' },
    { id: '4', name: 'ra.input.days.thursday' },
    { id: '5', name: 'ra.input.days.friday' },
    { id: '6', name: 'ra.input.days.saturday' },
    { id: '7', name: 'ra.input.days.sunday' },
];

const repetitionChoices = [
    { id: 0, name: 'ra.input.repetition_names.weekly' },
    { id: 1, name: 'ra.input.repetition_names.first' },
    { id: 2, name: 'ra.input.repetition_names.second' },
    { id: 3, name: 'ra.input.repetition_names.third' },
    { id: 4, name: 'ra.input.repetition_names.fourth' },
    { id: 5, name: 'ra.input.repetition_names.fifth' },
];

const CustomInputs = {
    UsernameTextInput: (props) => {
        return (
            <TextInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.input.username', 1)}
                source={props.source || 'username'}
                fullWidth
            />
        );
    },
    PasswordInput: (props) => {
        const passwordLabel = useTranslate()('ra.auth.password', 1);
        return (
            <PasswordInput
                {...props}
                variant='outlined'
                label={props.label || passwordLabel}
                source={props.source || 'password'}
                fullWidth
            />
        );
    },
    ApiTokenTextInput: (props) => {
        return (
            <TextInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.auth.api_token', 1)}
                source={props.source || 'api_token'}
                fullWidth
            />
        );
    },
    NameTextInput: (props) => {
        const nameLabel = useTranslate()('ra.input.name', 1);
        return (
            <TextInput
                {...props}
                variant='outlined'
                label={props.label || nameLabel}
                source={props.source || 'name'}
                fullWidth
            />
        );
    },
    SlugTextInput: (props) => {
        return (
            <TextInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.input.slug', 1)}
                source={props.source || 'slug'}
                fullWidth
            />
        );
    },
    LocalityTextInput: (props) => {
        return (
            <TextInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.input.locality', 1)}
                source={props.source || 'locality'}
                helperText={useTranslate()('ra.input.helper.locality')}
                fullWidth
            />
        );
    },
    LocalityEntityTextInput: (props) => {
        return (
            <TextInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.input.locality', 1)}
                source={props.source || 'locality'}
                helperText={useTranslate()('ra.input.helper.locality_entity')}
                fullWidth
            />
        );
    },
    EstablishedTextInput: (props) => {
        return (
            <TextInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.input.established', 1)}
                source={props.source || 'established'}
                fullWidth
            />
        );
    },
    DedicatedTextInput: (props) => {
        return (
            <TextInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.input.dedicated', 1)}
                source={props.source || 'dedicated'}
                fullWidth
            />
        );
    },
    KeywordTermsTextInput: (props) => {
        return (
            <TextInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.input.keyword_terms', 1)}
                source={props.source || 'keyword_terms'}
                helperText={useTranslate()('ra.input.helper.keyword_terms')}
                fullWidth
            />
        );
    },
    EmailTextInput: (props) => {
        return (
            <TextInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.input.email', 1)}
                source={props.source || 'email'}
                fullWidth
            />
        );
    },
    EnquiryFormEmailTextInput: (props) => {
        return (
            <TextInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.input.enquiry_email', 1)}
                source={props.source || 'enquiry_form_email'}
                fullWidth
            />
        );
    },
    WebsiteUrlTextInput: (props) => {
        return (
            <TextInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.input.website_url', 1)}
                source={props.source || 'website_url'}
                helperText={useTranslate()('ra.input.helper.url')}
                fullWidth
            />
        );
    },
    EnquiryFormUrlTextInput: (props) => {
        return (
            <TextInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.input.enquire_online_url', 1)}
                source={props.source || 'enquire_online_url'}
                helperText={useTranslate()('ra.input.helper.url')}
                fullWidth
            />
        );
    },
    WebCastUrlTextInput: (props) => {
        return (
            <TextInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.input.web_cast_url', 1)}
                source={props.source || 'web_cast_url'}
                helperText={useTranslate()('ra.input.helper.url')}
                fullWidth
            />
        );
    },
    MetaDescriptionTextInput: (props) => {
        return (
            <TextInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.input.meta_description', 1)}
                source={props.source || 'meta_description'}
                helperText={useTranslate()('ra.validation.maxLength', {
                    max: 160,
                })}
                fullWidth
            />
        );
    },
    DescriptionRichTextInput: (props) => {
        return (
            <RichTextInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.input.description', 1)}
                source={props.source || 'description'}
                fullWidth
                toolbar={RichTextHelper.Basic}
            />
        );
    },
    NotesRichTextInput: (props) => {
        const notesLabel = useTranslate()('ra.input.public_note', 2);
        const notesHelper = useTranslate()('ra.input.helper.public_note');
        return (
            <RichTextInput
                {...props}
                variant='outlined'
                label={props.label || notesLabel}
                source={props.source || 'notes'}
                helperText={props.helperText || notesHelper}
                fullWidth
                toolbar={RichTextHelper.Basic}
            />
        );
    },
    PlacesAvailableRichTextInput: (props) => {
        return (
            <RichTextInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.input.places_available', 1)}
                source={props.source || 'places_available'}
                fullWidth
                toolbar={RichTextHelper.Basic}
                helperText={useTranslate()('ra.input.helper.places_available')}
            />
        );
    },
    CapacityNumberInput: (props) => {
        return (
            <NumberInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.input.capacity', 1)}
                source={props.source || 'capacity'}
                min={0}
                onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                    }
                }}
            />
        );
    },
    OrderingNumberInput: (props) => {
        return (
            <NumberInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.input.ordering', 1)}
                source={props.source || 'ordering'}
                min={0}
                step={10}
                helperText={useTranslate()('ra.input.helper.ordering')}
                onKeyPress={(e) => {
                    if (!/[0-9-]/.test(e.key)) {
                        e.preventDefault();
                    }
                }}
            />
        );
    },
    IsActiveBooleanInput: (props) => {
        const activeLabel = useTranslate()('ra.input.active', 1);
        return (
            <BooleanInput
                {...props}
                label={props.label || activeLabel}
                source={props.source || 'is_active'}
                defaultValue={props.defaultValue || null}
                fullWidth
            />
        );
    },
    IsExcludeLocalityBooleanInput: (props) => {
        return (
            <BooleanInput
                {...props}
                label={useTranslate()('ra.input.exclude_locality', 1)}
                source={props.source || 'is_exclude_locality_from_name'}
                defaultValue={props.defaultValue || null}
                fullWidth
            />
        );
    },
    IsShowInSearchBooleanInput: (props) => {
        return (
            <BooleanInput
                {...props}
                label={useTranslate()('ra.input.is_show_in_search', 1)}
                source={props.source || 'is_show_in_search'}
                defaultValue={props.defaultValue || true}
                fullWidth
            />
        );
    },
    IsShowOverviewBooleanInput: (props) => {
        return (
            <BooleanInput
                {...props}
                label={useTranslate()('ra.input.is_show_overview', 1)}
                source={props.source || 'is_show_overview'}
                defaultValue={props.defaultValue || true}
                fullWidth
            />
        );
    },
    IsVirtualBooleanInput: (props) => {
        return (
            <BooleanInput
                {...props}
                label={useTranslate()('ra.input.virtual_entity', 1)}
                source={props.source || 'is_virtual'}
                defaultValue={props.defaultValue || null}
                fullWidth
            />
        );
    },
    IsFilterBooleanInput: (props) => {
        return (
            <BooleanInput
                {...props}
                label={useTranslate()('ra.input.is_use_as_filter')}
                source={props.source || 'is_use_as_filter'}
                defaultValue={props.defaultValue || true}
                fullWidth
            />
        );
    },
    IsPublishTimesBooleanInput: (props) => {
        return (
            <BooleanInput
                {...props}
                label={useTranslate()('ra.input.is_publish_times')}
                source={props.source || 'is_publish_times'}
                defaultValue={props.defaultValue || false}
                fullWidth
            />
        );
    },
    IsWebCastBooleanInput: (props) => {
        const activeLabel = useTranslate()('ra.input.is_web_cast', 1);
        return (
            <BooleanInput
                {...props}
                label={props.label || activeLabel}
                source={props.source || 'is_web_cast'}
                defaultValue={props.defaultValue || null}
                fullWidth
            />
        );
    },
    NotesTextInput: (props) => {
        return (
            <TextInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.input.note', 2)}
                source={props.source || 'notes'}
                fullWidth
            />
        );
    },
    InternalNotesTextInput: (props) => {
        return (
            <TextInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.input.internal_note', 2)}
                source={props.source || 'internal_notes'}
                fullWidth
                component='pre'
                multiline
            />
        );
    },
    LocaleInput: (props) => {
        const data = process.env.REACT_APP_SUPPORTED_LOCALES.split('|').map(
            (d) => ({ id: d, name: d })
        );
        return (
            <SelectInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.input.locale', 1)}
                source={props.source || 'locale'}
                fullWidth
                choices={data}
            />
        );
    },
    TitleReferenceInput: (props) => {
        return (
            <ReferenceInput
                variant='outlined'
                label={useTranslate()('ra.input.title', 1)}
                source='title_meta_category_id'
                reference='meta-categories'
                perPage={-1}
                filter={props.filter}
                sort={{ field: 'name', order: 'ASC' }}
                fullWidth
            >
                <SelectInput optionText='name' optionValue='id' />
            </ReferenceInput>
        );
    },
    FirstNameTextInput: (props) => {
        const firstNameLabel = useTranslate()('ra.input.first_name', 1);
        return (
            <TextInput
                {...props}
                variant='outlined'
                label={props.label || firstNameLabel}
                source={props.source || 'first_name'}
                fullWidth
            />
        );
    },
    LastNameTextInput: (props) => {
        const lastNameLabel = useTranslate()('ra.input.last_name', 1);
        return (
            <TextInput
                {...props}
                variant='outlined'
                label={props.label || lastNameLabel}
                source={props.source || 'last_name'}
                fullWidth
            />
        );
    },
    PostNominalTextInput: (props) => {
        const postNominalLabel = useTranslate()('ra.input.post_nominal', 1);
        return (
            <TextInput
                {...props}
                variant='outlined'
                label={props.label || postNominalLabel}
                source={props.source || 'post_nominal'}
                helperText={useTranslate()('ra.input.helper.post_nominal')}
                fullWidth
            />
        );
    },
    BiographyPullQuoteTextInput: (props) => {
        const bioPullQuoteLabel = useTranslate()(
            'ra.input.biography_pull_quote',
            1
        );
        return (
            <TextInput
                {...props}
                variant='outlined'
                label={props.label || bioPullQuoteLabel}
                source={props.source || 'biography_pull_quote'}
                fullWidth
            />
        );
    },
    BiographyRichTextInput: (props) => {
        return (
            <RichTextInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.input.biography', 1)}
                source={props.source || 'biography'}
                fullWidth
                toolbar={RichTextHelper.Basic}
            />
        );
    },
    BiographyVideoYouTubeIdTextInput: (props) => {
        const bioVideoYouTubeIdLabel = useTranslate()(
            'ra.input.biography_video_youtube_id',
            1
        );
        return (
            <TextInput
                {...props}
                variant='outlined'
                label={props.label || bioVideoYouTubeIdLabel}
                source={props.source || 'biography_video_youtube_id'}
                helperText={useTranslate()('ra.input.helper.biography_video_youtube_id')}
                fullWidth
            />
        );
    },
    RoleProductArrayInput: (props) => {
        return (
            <ArrayInput
                variant='outlined'
                label={useTranslate()('ra.navigation.resources.product', 2)}
                source={props.source || 'role_products_ids'}
                fullWidth
            >
                <SimpleFormIterator variant='outlined' disableReordering fullWidth>
                    <ReferenceInput
                        variant='outlined'
                        label={useTranslate()('ra.input.role', 1)}
                        source='role_meta_category_id'
                        reference='meta-categories'
                        perPage={-1}
                        filter={props.filter}
                        sort={{ field: 'ordering', order: 'ASC' }}
                        fullWidth
                    >
                        <SelectInput optionText='name' optionValue='id' />
                    </ReferenceInput>
                    <CustomInputs.ProductReferenceInput
                        label={useTranslate()('ra.navigation.resources.product', 1)}
                        source='product_id'
                        debounce={500}
                        shouldRenderSuggestions={(val) => val.trim().length > 3}
                        allowEmpty
                    />
                </SimpleFormIterator>
            </ArrayInput>
        );
    },
    RolePersonnelArrayInput: (props) => {
        return (
            <ArrayInput
                variant='outlined'
                label={useTranslate()('ra.navigation.resources.personnel', 2)}
                source={props.source || 'role_personnel_ids'}
                fullWidth
            >
                <SimpleFormIterator variant='outlined' disableReordering fullWidth>
                    <ReferenceInput
                        variant='outlined'
                        label={useTranslate()('ra.input.role', 1)}
                        source='role_meta_category_id'
                        reference='meta-categories'
                        perPage={-1}
                        filter={props.filter}
                        sort={{ field: 'ordering', order: 'ASC' }}
                        fullWidth
                    >
                        <SelectInput optionText='name' optionValue='id' />
                    </ReferenceInput>
                    <CustomInputs.PersonnelReferenceInput
                        label={useTranslate()('ra.navigation.resources.personnel', 1)}
                        source='personnel_id'
                        allowEmpty
                    />
                </SimpleFormIterator>
            </ArrayInput>
        );
    },
    PrimaryRoleReferenceInput: (props) => {
        const data = process.env.REACT_APP_PRIMARY_ROLES.split('|').map((d) => {
            const parts = d.split(':');
            return {
                id: parts[1],
                name: parts[0],
            };
        });
        return (
            <RadioButtonGroupInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.input.primary_role', 1)}
                source={props.source || 'primary_role_id'}
                choices={data}
            />
        );
    },
    RoleArrayInput: (props) => {
        const transformOptionText = (data) => {
            return `${data.name} (${data.description})`;
        };
        return (
            <ReferenceArrayInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.navigation.resources.role', 2)}
                source={props.source || 'roles_ids'}
                reference='roles'
                perPage={-1}
                sort={{ field: 'name', order: 'ASC' }}
                fullWidth
            >
                <SelectArrayInput
                    optionValue='id'
                    optionText={transformOptionText}
                    helperText={useTranslate()('ra.input.helper.user_roles')}
                />
            </ReferenceArrayInput>
        );
    },
    CategoryReferenceInput: (props) => {
        return (
            <ReferenceInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.navigation.resources.category', 1)}
                source={props.source || 'category_id'}
                reference='categories'
                perPage={-1}
                sort={{ field: 'name', order: 'ASC' }}
                fullWidth
            >
                <SelectInput
                    optionText='name'
                    optionValue='id'
                    helperText={props.helperText}
                />
            </ReferenceInput>
        );
    },
    CategoriesInput: (props) => {
        const categoriesLabel = useTranslate()(
            'ra.navigation.resources.category',
            2
        );
        return (
            <ReferenceArrayInput
                {...props}
                variant='outlined'
                label={props.label || categoriesLabel}
                source={props.source || 'categories_ids'}
                reference='categories'
                perPage={-1}
                sort={{ field: 'name', order: 'ASC' }}
                helperText={props.helperText}
                fullWidth
            >
                <SelectArrayInput optionText='name' optionValue='id' />
            </ReferenceArrayInput>
        );
    },
    ServiceCategoryInput: (props) => {
        const serviceCategoryLabel = useTranslate()(
            'ra.navigation.resources.service_category',
            2
        );
        return (
            <ReferenceInput
                {...props}
                variant='outlined'
                label={props.label || serviceCategoryLabel}
                reference='service-categories'
                perPage={-1}
                sort={{ field: 'tree_name_path_derived', order: 'ASC' }}
                fullWidth
            >
                <SelectInput
                    optionValue='id'
                    optionText={(path) =>
                        PathHelper.TriplePipeToSlash(path.tree_name_path_derived)
                    }
                    helperText={props.helperText}
                />
            </ReferenceInput>
        );
    },
    ServiceCategoryArrayInput: (props) => {
        return (
            <ReferenceArrayInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.navigation.resources.service_category', 2)}
                source={props.source || 'service_categories_ids'}
                reference='service-categories'
                perPage={-1}
                sort={{ field: 'tree_name_path_derived', order: 'ASC' }}
                fullWidth
            >
                <SelectArrayInput
                    optionValue='id'
                    optionText={(path) =>
                        PathHelper.TriplePipeToSlash(path.tree_name_path_derived)
                    }
                />
            </ReferenceArrayInput>
        );
    },
    ServiceCategoryArrayReferenceInput: (props) => {
        const transformOptionText = (data) => {
            return data?.tree_name_path_derived
                ? `${PathHelper.TriplePipeToSlash(data.tree_name_path_derived)}`
                : '';
        };
        return (
            <ReferenceArrayInput
                {...props}
                name={props.source || 'service_categories_ids'}
                variant='outlined'
                label={useTranslate()('ra.navigation.resources.service_category', 2)}
                source={props.source || 'service_categories_ids'}
                reference='service-categories'
                perPage={10}
                sort={{ field: 'tree_name_path_derived', order: 'ASC' }}
                filter={props.filter}
                filterToQuery={(searchText) => ({
                    search_keyword_term: searchText,
                    with: 'category',
                })}
                allowEmpty
                fullWidth
            >
                <AutocompleteArrayInput
                    optionText={transformOptionText}
                    optionValue='id'
                    debounce={500}
                    shouldRenderSuggestions={(val) => val.trim().length > 3}
                    helperText={props.helperText}
                />
            </ReferenceArrayInput>
        );
    },
    EntityReferenceInput: (props) => {
        return (
            <ReferenceInput
                {...props}
                name={props.source || 'entity_id'}
                variant='outlined'
                label={useTranslate()('ra.navigation.resources.entity', 1)}
                source={props.source || 'entity_id'}
                reference='entities'
                perPage={10}
                sort={{ field: 'name_with_locality_derived', order: 'ASC' }}
                filterToQuery={(searchText) => ({ search_keyword_term: searchText })}
                allowEmpty
                fullWidth
            >
                <AutocompleteInput
                    optionText='name_with_locality_derived'
                    optionValue='id'
                    debounce={500}
                    shouldRenderSuggestions={(val) => val.trim().length > 3}
                    helperText={props.helperText}
                />
            </ReferenceInput>
        );
    },
    ProductReferenceInput: (props) => {
        const productLabel = useTranslate()('ra.navigation.resources.product', 2);
        return (
            <ReferenceInput
                {...props}
                name={props.source || 'product_id'}
                variant='outlined'
                label={props.label || productLabel}
                source={props.source || 'product_id'}
                reference='products'
                perPage={10}
                sort={{ field: 'tree_name_path_derived', order: 'ASC' }}
                filterToQuery={(searchText) => ({
                    search_keyword_term: searchText,
                    with: 'category',
                    flatten: null,
                })}
                allowEmpty
                fullWidth
            >
                <AutocompleteInput
                    optionText={(data) => {
                        const locality = data?.locality_derived
                            ? `(${data.locality_derived}) `
                            : '';
                        return data?.tree_name_path_derived && data?.category?.data
                            ? `${PathHelper.TriplePipeToSlash(
                                data.tree_name_path_derived
                            )} ${locality}(${data.category.data.name})`
                            : '';
                    }}
                    optionValue='id'
                    debounce={500}
                    shouldRenderSuggestions={(val) => val.trim().length > 3}
                    helperText={props.helperText}
                />
            </ReferenceInput>
        );
    },
    ProductArrayInput: (props) => {
        const productLabel = useTranslate()('ra.navigation.resources.product', 2);
        const transformOptionText = (data) => {
            const locality = data?.locality_derived
                ? `(${data.locality_derived}) `
                : '';
            return data?.tree_name_path_derived && data?.category?.data
                ? `${PathHelper.TriplePipeToSlash(
                    data.tree_name_path_derived
                )} ${locality}(${data.category.data.name})`
                : '';
        };
        return (
            <ReferenceArrayInput
                {...props}
                name={props.source || 'related_products_ids'}
                variant='outlined'
                label={props.label || productLabel}
                source={props.source || 'related_products_ids'}
                reference='products'
                perPage={10}
                sort={{ field: 'tree_name_path_derived', order: 'ASC' }}
                filterToQuery={(searchText) => ({
                    search_keyword_term: searchText,
                    with: 'category',
                })}
                allowEmpty
                fullWidth
            >
                <AutocompleteArrayInput
                    optionText={transformOptionText}
                    optionValue='id'
                    debounce={500}
                    shouldRenderSuggestions={(val) => val.trim().length > 3}
                    helperText={props.helperText}
                />
            </ReferenceArrayInput>
        );
    },
    PersonnelReferenceInput: (props) => {
        const productLabel = useTranslate()('ra.navigation.resources.personnel', 2);
        return (
            <ReferenceInput
                {...props}
                name={props.source || 'personnel_id'}
                variant='outlined'
                label={props.label || productLabel}
                source={props.source || 'personnel_id'}
                reference='personnel'
                perPage={10}
                sort={{
                    field: 'full_name_with_post_nominal_and_email_reversed_derived',
                    order: 'ASC',
                }}
                filterToQuery={(searchText) => ({
                    search_keyword_term: searchText,
                })}
                allowEmpty
                fullWidth
            >
                <AutocompleteInput
                    optionText={(data) => {
                        return (
                            data?.full_name_with_post_nominal_and_email_reversed_derived ?? ''
                        );
                    }}
                    optionValue='id'
                    debounce={500}
                    shouldRenderSuggestions={(val) => val.trim().length > 3}
                    helperText={props.helperText}
                />
            </ReferenceInput>
        );
    },
    GoalsInput: (props) => {
        return (
            <ReferenceArrayInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.navigation.resources.goal', 2)}
                source={props.source || 'goals_ids'}
                reference='goals'
                perPage={-1}
                sort={{ field: 'name', order: 'ASC' }}
                fullWidth
            >
                <SelectArrayInput optionText='name' optionValue='id' />
            </ReferenceArrayInput>
        );
    },
    TagsInput: (props) => {
        return (
            <ReferenceArrayInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.navigation.resources.tag', 2)}
                source={props.source || 'tags_ids'}
                reference='tags'
                perPage={-1}
                sort={{ field: 'name', order: 'ASC' }}
                fullWidth
            >
                <SelectArrayInput optionText='name' optionValue='id' />
            </ReferenceArrayInput>
        );
    },
    DemographicFociInput: (props) => {
        return (
            <ReferenceArrayInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.navigation.resources.demographic_focus', 2)}
                source={props.source || 'demographic_foci_ids'}
                reference='demographic-foci'
                perPage={-1}
                sort={{ field: 'name', order: 'ASC' }}
                fullWidth
            >
                <SelectArrayInput
                    optionText='name'
                    optionValue='id'
                    helperText={props.helperText}
                />
            </ReferenceArrayInput>
        );
    },
    RegionReferenceInput: (props) => {
        const transformOptionText = (data) => {
            return data?.name && data?.category?.data
                ? `${data.name} (${data.category.data.name})`
                : '';
        };
        return (
            <ReferenceInput
                {...props}
                name={props.source || 'region_id'}
                variant='outlined'
                label={useTranslate()('ra.navigation.resources.region', 1)}
                source={props.source || 'region_id'}
                reference='regions'
                perPage={10}
                sort={{ field: 'name', order: 'ASC' }}
                filterToQuery={(searchText) => ({ name: searchText, with: 'category' })}
                allowEmpty
                fullWidth
            >
                <AutocompleteInput
                    optionText={transformOptionText}
                    optionValue='id'
                    debounce={500}
                    shouldRenderSuggestions={(val) => val.trim().length > 3}
                    helperText={props.helperText}
                />
            </ReferenceInput>
        );
    },
    MassTimeTypeReferenceInput: (props) => {
        return (
            <ReferenceInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.navigation.resources.mass_type', 1)}
                source={props.source || 'mass_type_id'}
                reference='mass-types'
                perPage={-1}
                sort={{ field: 'name', order: 'ASC' }}
                allowEmpty={true}
                fullWidth
            >
                <SelectInput
                    optionText='name'
                    optionValue='id'
                    helperText={props.helperText}
                />
            </ReferenceInput>
        );
    },
    MassTimeSeasonReferenceInput: (props) => {
        return (
            <ReferenceInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.navigation.resources.mass_season', 1)}
                source={props.source || 'mass_season_id'}
                reference='mass-seasons'
                perPage={-1}
                sort={{ field: 'name', order: 'ASC' }}
                fullWidth
            >
                <SelectInput
                    optionText='name'
                    optionValue='id'
                    helperText={props.helperText}
                />
            </ReferenceInput>
        );
    },
    MassTimeLanguageReferenceInput: (props) => {
        return (
            <ReferenceInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.navigation.resources.mass_language', 1)}
                source={props.source || 'mass_language_id'}
                reference='mass-languages'
                perPage={-1}
                sort={{ field: 'name', order: 'ASC' }}
                defaultValue={
                    props.defaultValue || process.env.REACT_APP_MASS_DEFAULT_LANGUAGE_ID
                }
                fullWidth
            >
                <SelectInput
                    optionText='name'
                    optionValue='id'
                    helperText={props.helperText}
                />
            </ReferenceInput>
        );
    },
    PhoneNumberArrayInput: (props) => {
        return (
            <ArrayInput
                variant='outlined'
                label={useTranslate()('ra.input.phone_number', 2)}
                source={props.source || 'phone_numbers.data'}
                fullWidth
            >
                <SimpleFormIterator variant='outlined' disableReordering fullWidth>
                    <ReferenceInput
                        variant='outlined'
                        label={useTranslate()('ra.input.type', 1)}
                        source='type_meta_category_id'
                        reference='meta-categories'
                        perPage={-1}
                        filter={props.filter}
                        sort={{ field: 'name', order: 'ASC' }}
                        fullWidth
                    >
                        <SelectInput optionText='name' optionValue='id' />
                    </ReferenceInput>
                    <TextInput
                        variant='outlined'
                        label={useTranslate()('ra.input.phone_number', 1)}
                        source='number'
                        fullWidth
                    />
                </SimpleFormIterator>
            </ArrayInput>
        );
    },
    PhoneNumberArrayField: (props) => {
        const useStyles = makeStyles(() => FormTheme.styles, {
            name: 'DataGrid',
        });
        const data = resolvePath(
            props.record,
            props.source || 'phone_numbers.data'
        );
        const label = useTranslate()('ra.input.phone_number', 2);
        return (
            <div className={useStyles().showDataGrid}>
                <h4>{props.label || label}</h4>
                <ArrayField>
                    <ListContextProvider
                        value={{
                            data,
                            ids: data.map((d, i) => i),
                            total: data.length,
                            page: 1,
                            perPage: -1,
                            currentSort: { field: 'id', order: 'ASC' },
                            basePath: props.basePath,
                            resource: props.resource,
                            selectedIds: [],
                        }}
                    >
                        <Datagrid>
                            <ReferenceField
                                label={useTranslate()('ra.input.type', 1)}
                                source='type_meta_category_id'
                                reference='meta-categories'
                                sortable={false}
                            >
                                <TextField source='name' />
                            </ReferenceField>
                            <TextField
                                label={useTranslate()('ra.input.phone_number', 1)}
                                source='number'
                                sortable={false}
                            />
                        </Datagrid>
                    </ListContextProvider>
                </ArrayField>
            </div>
        );
    },
    AddressArrayInput: (props) => {
        return (
            <ArrayInput
                variant='outlined'
                label={useTranslate()('ra.input.address', 2)}
                source={props.source || 'addresses.data'}
                fullWidth
            >
                <SimpleFormIterator variant='outlined' disableReordering fullWidth>
                    <ReferenceInput
                        variant='outlined'
                        label={useTranslate()('ra.input.type', 1)}
                        source='type_meta_category_id'
                        reference='meta-categories'
                        perPage={-1}
                        filter={props.filter}
                        sort={{ field: 'name', order: 'ASC' }}
                        fullWidth
                    >
                        <SelectInput optionText='name' optionValue='id' />
                    </ReferenceInput>
                    <TextInput
                        variant='outlined'
                        label={useTranslate()('ra.input.line_one', 1)}
                        source='line_one'
                        fullWidth
                    />
                    <TextInput
                        variant='outlined'
                        label={useTranslate()('ra.input.line_two', 1)}
                        source='line_two'
                        fullWidth
                    />
                    <TextInput
                        variant='outlined'
                        label={useTranslate()('ra.input.line_three', 1)}
                        source='line_three'
                        fullWidth
                    />
                    <TextInput
                        variant='outlined'
                        label={useTranslate()('ra.input.suburb', 1)}
                        source='suburb'
                        fullWidth
                    />
                    <TextInput
                        variant='outlined'
                        label={useTranslate()('ra.input.state', 1)}
                        source='state'
                        fullWidth
                    />
                    <TextInput
                        variant='outlined'
                        label={useTranslate()('ra.input.postcode', 1)}
                        source='postcode'
                        fullWidth
                    />
                    <TextInput
                        variant='outlined'
                        label={useTranslate()('ra.input.public_note', 2)}
                        source='notes'
                        helperText={useTranslate()('ra.input.helper.public_note')}
                        fullWidth
                    />
                </SimpleFormIterator>
            </ArrayInput>
        );
    },
    AddressArrayField: (props) => {
        const useStyles = makeStyles(() => FormTheme.styles, {
            name: 'DataGrid',
        });
        const data = resolvePath(props.record, props.source || 'addresses.data');
        const label = useTranslate()('ra.input.address', 2);
        return (
            <div className={useStyles().showDataGrid}>
                <h4>{props.label || label}</h4>
                <ArrayField>
                    <ListContextProvider
                        value={{
                            data,
                            ids: data.map((d, i) => i),
                            total: data.length,
                            page: 1,
                            perPage: -1,
                            currentSort: { field: 'id', order: 'ASC' },
                            basePath: props.basePath,
                            resource: props.resource,
                            selectedIds: [],
                        }}
                    >
                        <Datagrid>
                            <ReferenceField
                                label={useTranslate()('ra.input.type', 1)}
                                source='type_meta_category_id'
                                reference='meta-categories'
                                sortable={false}
                            >
                                <TextField source='name' />
                            </ReferenceField>
                            <TextField
                                label={useTranslate()('ra.input.line_one', 1)}
                                source='line_one'
                                sortable={false}
                            />
                            <TextField
                                label={useTranslate()('ra.input.line_two', 1)}
                                source='line_two'
                                sortable={false}
                            />
                            <TextField
                                label={useTranslate()('ra.input.line_three', 1)}
                                source='line_three'
                                sortable={false}
                            />
                            <TextField
                                label={useTranslate()('ra.input.suburb', 1)}
                                source='suburb'
                                sortable={false}
                            />
                            <TextField
                                label={useTranslate()('ra.input.state', 1)}
                                source='state'
                                sortable={false}
                            />
                            <TextField
                                label={useTranslate()('ra.input.postcode', 1)}
                                source='postcode'
                                sortable={false}
                            />
                            <TextField
                                label={useTranslate()('ra.input.public_note', 1)}
                                source='notes'
                                sortable={false}
                            />
                        </Datagrid>
                    </ListContextProvider>
                </ArrayField>
            </div>
        );
    },
    OpenHoursArrayInput: (props) => {
        const [, forceUpdate] = useReducer((x) => x + 1, 0);
        const useStyles = makeStyles(() => FormTheme.styles, {
            name: 'OpenHoursInput',
        });
        const serviceCategoryTitle = useTranslate()(
            'ra.navigation.resources.service_category',
            1
        );
        const source = props.source || 'open_hours';
        const openTimeTitle = useTranslate()('ra.input.open_time', 1);
        const closeTimeTitle = useTranslate()('ra.input.close_time', 1);
        const changeTitle = useTranslate()('ra.action.change', 1);
        const invalidTitle = useTranslate()('ra.action.invalid', 1);
        const cancelTitle = useTranslate()('ra.action.cancel', 1);
        const clearTitle = useTranslate()('ra.action.clear', 1);
        const okTitle = useTranslate()('ra.action.ok', 1);
        const required = useTranslate()('ra.validation.required');
        const earlierTime = useTranslate()('ra.validation.earlier_time');
        const laterTime = useTranslate()('ra.validation.later_time');
        const pickerClass = useStyles().picker;
        return (
            <ArrayInput
                variant='outlined'
                label={useTranslate()('ra.input.open_hour', 2)}
                source={`${source}.data`}
                fullWidth
            >
                <SimpleFormIterator variant='outlined' disableReordering fullWidth>
                    {props.showServiceCategory && (
                        <ReferenceInput
                            variant='outlined'
                            label={serviceCategoryTitle}
                            source='service_category_id'
                            reference='service-categories'
                            perPage={-1}
                            filter={props.filter}
                            sort={{ field: 'tree_name_path_derived', order: 'ASC' }}
                            fullWidth
                        >
                            <SelectInput
                                optionValue='id'
                                optionText={(path) =>
                                    PathHelper.TriplePipeToSlash(
                                        path.tree_name_path_derived
                                    )
                                }
                            />
                        </ReferenceInput>
                    )}
                    <SelectInput
                        variant='outlined'
                        label={useTranslate()('ra.input.day', 1)}
                        source='day'
                        choices={dayChoices}
                        fullWidth
                    />
                    <FormDataConsumer>
                        {({ getSource, formData }) => {
                            const id = getSource('open_time').match(/(\d+)/)[0];
                            if (formData?.[source]?.data?.[id]?.['open_time']) {
                                formData[source].data[id]['open_time'] =
                                    formData?.[source]?.data?.[id]?.['open_time'] instanceof Date
                                        ? formData?.[source]?.data?.[id]?.['open_time']
                                        : new Date(
                                            `2016-06-10T${formData?.[source]?.data?.[id]?.['open_time']}`
                                        );
                            }
                            if (formData?.[source]?.data?.[id]?.['close_time']) {
                                formData[source].data[id]['close_time'] =
                                    formData?.[source]?.data?.[id]?.['close_time'] instanceof Date
                                        ? formData?.[source]?.data?.[id]?.['close_time']
                                        : new Date(
                                            `2016-06-10T${formData?.[source]?.data?.[id]?.['close_time']}`
                                        );
                            }
                            return (
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div>
                                        <KeyboardTimePicker
                                            autoOk
                                            label={openTimeTitle}
                                            source={getSource('open_time')}
                                            value={
                                                formData?.[source]?.data?.[id]?.['open_time'] || null
                                            }
                                            onChange={(t) => {
                                                if (!formData[source].data[id]) {
                                                    formData[source].data[id] = {};
                                                }
                                                formData[source].data[id]['open_time'] = t;
                                                forceUpdate();
                                            }}
                                            clearable
                                            margin='normal'
                                            helperText={
                                                !formData?.[source]?.data?.[id]?.['open_time']
                                                    ? required
                                                    : formData?.[source]?.data?.[id]?.['close_time'] &&
                                                        formData?.[source]?.data?.[id]?.['close_time'] <=
                                                        formData?.[source]?.data?.[id]?.['open_time']
                                                        ? earlierTime
                                                        : null
                                            }
                                            KeyboardButtonProps={{
                                                'aria-label': `${changeTitle} ${openTimeTitle.toLowerCase()}`,
                                            }}
                                            invalidLabel={`${invalidTitle} ${openTimeTitle.toLowerCase()}`}
                                            cancelLabel={cancelTitle}
                                            clearLabel={clearTitle}
                                            okLabel={okTitle}
                                            className={pickerClass}
                                        />
                                    </div>
                                    <div>
                                        <KeyboardTimePicker
                                            autoOk
                                            label={closeTimeTitle}
                                            source={getSource('close_time')}
                                            value={
                                                formData?.[source]?.data?.[id]?.['close_time'] || null
                                            }
                                            onChange={(t) => {
                                                if (!formData[source].data[id]) {
                                                    formData[source].data[id] = {};
                                                }
                                                formData[source].data[id]['close_time'] = t;
                                                forceUpdate();
                                            }}
                                            clearable
                                            margin='normal'
                                            helperText={
                                                !formData?.[source]?.data?.[id]?.['close_time']
                                                    ? required
                                                    : formData?.[source]?.data?.[id]?.['open_time'] &&
                                                        formData?.[source]?.data?.[id]?.['open_time'] >=
                                                        formData?.[source]?.data?.[id]?.['close_time']
                                                        ? laterTime
                                                        : null
                                            }
                                            KeyboardButtonProps={{
                                                'aria-label': `${changeTitle} ${closeTimeTitle.toLowerCase()}`,
                                            }}
                                            invalidLabel={`${invalidTitle} ${closeTimeTitle.toLowerCase()}`}
                                            cancelLabel={cancelTitle}
                                            clearLabel={clearTitle}
                                            okLabel={okTitle}
                                            className={pickerClass}
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            );
                        }}
                    </FormDataConsumer>
                    <TextInput
                        variant='outlined'
                        label={useTranslate()('ra.input.public_note', 2)}
                        source='notes'
                        fullWidth
                    />
                </SimpleFormIterator>
            </ArrayInput>
        );
    },
    OpenHoursArrayField: (props) => {
        const useStyles = makeStyles(() => FormTheme.styles, {
            name: 'DataGrid',
        });
        const data = resolvePath(props.record, props.source || 'open_hours.data');
        const timeFormat = props.timeFormat || 'hh:MM TT';
        const label = useTranslate()('ra.input.open_hour', 2);
        const translate = useTranslate();
        return (
            <div className={useStyles().showDataGrid}>
                <h4>{props.label || label}</h4>
                <ArrayField>
                    <ListContextProvider
                        value={{
                            data,
                            ids: data.map((d, i) => i),
                            total: data.length,
                            page: 1,
                            perPage: -1,
                            currentSort: { field: 'day,open_time', order: 'ASC' },
                            basePath: props.basePath,
                            resource: props.resource,
                            selectedIds: [],
                        }}
                    >
                        <Datagrid>
                            {props.showServiceCategory && (
                                <ReferenceField
                                    label={translate(
                                        'ra.navigation.resources.service_category',
                                        1
                                    )}
                                    source='service_category_id'
                                    reference='service-categories'
                                    sortable={false}
                                >
                                    <FunctionField
                                        source='tree_name_path_derived'
                                        render={(path) =>
                                            PathHelper.TriplePipeToSlash(
                                                path.tree_name_path_derived
                                            )
                                        }
                                    />
                                </ReferenceField>
                            )}
                            <FunctionField
                                label={translate('ra.input.day', 1)}
                                source='day'
                                render={(record) =>
                                    record.day
                                        ? translate(dayChoices[record.day].name)
                                        : translate(dayChoices[0].name)
                                }
                                sortable={false}
                            />
                            <FunctionField
                                label={translate('ra.input.open_time', 1)}
                                source='open_time'
                                render={(record) =>
                                    record.open_time
                                        ? typeof record.open_time === 'object'
                                            ? dateFormat(record.open_time, timeFormat)
                                            : dateFormat(
                                                new Date(`2018-07-28T${record.open_time}`),
                                                timeFormat
                                            )
                                        : ''
                                }
                                sortable={false}
                            />
                            <FunctionField
                                label={translate('ra.input.close_time', 1)}
                                source='close_time'
                                render={(record) =>
                                    record.close_time
                                        ? typeof record.close_time === 'object'
                                            ? dateFormat(record.close_time, timeFormat)
                                            : dateFormat(
                                                new Date(`2018-07-28T${record.close_time}`),
                                                timeFormat
                                            )
                                        : ''
                                }
                                sortable={false}
                            />
                            <TextField
                                label={translate('ra.input.public_note', 2)}
                                source='notes'
                                sortable={false}
                            />
                        </Datagrid>
                    </ListContextProvider>
                </ArrayField>
            </div>
        );
    },
    RepetitionArrayInput: (props) => {
        const label = useTranslate()('ra.input.repetition', 1);
        return (
            <CheckboxGroupInput
                variant='outlined'
                label={props.label || label}
                source={props.source || 'repetition'}
                fullWidth
                choices={repetitionChoices}
            />
        );
    },
    DatePicker: (props) => {
        const label = useTranslate()('ra.input.date', 1);
        const required = useTranslate()('ra.validation.required');
        const useStyles = makeStyles(() => FormTheme.styles, {
            name: 'DateInput',
        });
        const pickerClass = useStyles().picker;

        // Ensure min and max props are only included if necessary
        const minMaxProps = {};
        if (props.minDate) {
            minMaxProps.minDate = props.minDate;
        }
        if (props.maxDate) {
            minMaxProps.maxDate = props.maxDate;
        }

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    autoOk
                    label={props.label || label}
                    clearable
                    allowKeyboardControl
                    value={props.selectedDate}
                    onChange={(date) => props.handleDateChange(date, props.source)}
                    format={props.format}
                    views={props.views}
                    helperText={!props.selectedDate && props.isRequired ? required : null}
                    className={pickerClass}
                    {...minMaxProps}
                />
            </MuiPickersUtilsProvider>
        );
    },
    TimePicker: (props) => {
        const label = useTranslate()('ra.input.time', 1);
        const required = useTranslate()('ra.validation.required');
        const useStyles = makeStyles(() => FormTheme.styles, {
            name: 'OpenHoursInput',
        });
        const pickerClass = useStyles().picker;
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                    autoOk
                    value={props.selectedTime}
                    label={props.label || label}
                    clearable
                    onChange={(time) => props.handleTimeChange(time, props.source)}
                    helperText={!props.selectedTime && props.isRequired ? required : null}
                    className={pickerClass}
                />
            </MuiPickersUtilsProvider>
        );
    },
    EasterDatePlusDaysNumberInput: (props) => {
        return (
            <NumberInput
                {...props}
                variant='outlined'
                label={useTranslate()('ra.input.easter_date_plus_days', 1)}
                source={props.source || 'easter_date_plus_days'}
                onKeyPress={(e) => {
                    if (!/[0-9-]/.test(e.key)) {
                        e.preventDefault();
                    }
                }}
                helperText={useTranslate()('ra.input.helper.easter_date_plus_days')}
            />
        );
    },
    CancellationDatesArrayInput: (props) => {
        const [, forceUpdate] = useReducer((x) => x + 1, 0);
        const source = props.source || 'cancellations';
        return (
            <ArrayInput
                variant='outlined'
                label={useTranslate()('ra.input.cancellation_date', 2)}
                source={source}
            >
                <SimpleFormIterator variant='outlined' disableReordering fullWidth>
                    <FormDataConsumer>
                        {({ getSource, formData }) => {
                            const id = getSource().match(/(\d+)/)[0];
                            return (
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        autoOk
                                        label={null}
                                        clearable
                                        allowKeyboardControl
                                        variant='outlined'
                                        format={'dd MMMM yyyy'}
                                        value={formData[source]?.[id] ?? null}
                                        onChange={(d) => {
                                            formData[source][id] = dateFormat(d, 'yyyy-mm-dd');
                                            forceUpdate();
                                        }}
                                        fullWidth
                                    />
                                </MuiPickersUtilsProvider>
                            );
                        }}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        );
    },
    OnlinePlatformArrayInput: (props) => {
        return (
            <ArrayInput
                variant='outlined'
                label={useTranslate()('ra.input.online_platform', 2)}
                source={props.source || 'online_platforms.data'}
                fullWidth
            >
                <SimpleFormIterator variant='outlined' disableReordering fullWidth>
                    <ReferenceInput
                        variant='outlined'
                        label={useTranslate()('ra.input.type', 1)}
                        source='type_meta_category_id'
                        reference='meta-categories'
                        perPage={-1}
                        filter={props.filter}
                        sort={{ field: 'name', order: 'ASC' }}
                        fullWidth
                    >
                        <SelectInput optionText='name' optionValue='id' />
                    </ReferenceInput>
                    <TextInput
                        variant='outlined'
                        label={useTranslate()('ra.input.url', 1)}
                        source='url'
                        fullWidth
                    />
                    <TextInput
                        variant='outlined'
                        label={useTranslate()('ra.input.name', 1)}
                        source='name'
                        fullWidth
                    />
                </SimpleFormIterator>
            </ArrayInput>
        );
    },
    OnlinePlatformArrayField: (props) => {
        const useStyles = makeStyles(() => FormTheme.styles, {
            name: 'DataGrid',
        });
        const data = resolvePath(
            props.record,
            props.source || 'online_platforms.data'
        );
        const label = useTranslate()('ra.input.online_platform', 2);
        return (
            <div className={useStyles().showDataGrid}>
                <h4>{props.label || label}</h4>
                <ArrayField>
                    <ListContextProvider
                        value={{
                            data,
                            ids: data.map((d, i) => i),
                            total: data.length,
                            page: 1,
                            perPage: -1,
                            currentSort: { field: 'id', order: 'ASC' },
                            basePath: props.basePath,
                            resource: props.resource,
                            selectedIds: [],
                        }}
                    >
                        <Datagrid>
                            <ReferenceField
                                label={useTranslate()('ra.input.type', 1)}
                                source='type_meta_category_id'
                                reference='meta-categories'
                                sortable={false}
                            >
                                <TextField source='name' />
                            </ReferenceField>
                            <TextField
                                label={useTranslate()('ra.input.name', 1)}
                                source='name'
                                sortable={false}
                            />
                            <UrlField
                                label={useTranslate()('ra.input.url', 1)}
                                source='url'
                                sortable={false}
                            />
                        </Datagrid>
                    </ListContextProvider>
                </ArrayField>
            </div>
        );
    },
    PersonnelRoleField: (props) => {
        const translate = useTranslate();
        return (
            <FunctionField
                label={props.label || translate('ra.input.role', 2)}
                render={(record) =>
                    record.roles_by_role_meta_category_derived.data
                        .sort((a, b) => a.ordering - b.ordering)
                        .map((role) =>
                            (role.personnel_ids &&
                                role.personnel_ids.includes(props.parentId)) ||
                                (role.product_ids && role.product_ids.includes(props.parentId))
                                ? role.name
                                : null
                        )
                        .filter((d) => d)
                        .join(', ')
                }
                sortable={false}
            />
        );
    },
};

export default CustomInputs;
