const MassTimeHelper = {
    Categories: () => {
        return process.env.REACT_APP_MASS_TIME_CATEGORIES.split('|').map((d) => {
            const parts = d.split(':');
            return {
                id: parts[0],
                name: parts[1],
            };
        });
    },
};

export default MassTimeHelper;
