import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import FormTheme from '../../themes/DefaultThemeForm';
import {
    Show,
    SimpleShowLayout,
    ReferenceField,
    TextField,
    BooleanField,
    DateField,
} from 'react-admin';
import ShowActions from '../ShowActions';
import { RegionShowEntitiesList } from './RegionShowEntitiesList';
import { RegionShowProductsList } from './RegionShowProductsList';
import GoogleMapComponentWrapper from '../MapField';
import { MediaShowList } from '../media/MediaShowList';

const useStyles = makeStyles(() => FormTheme.styles, {
    name: 'RegionForm',
});

export const RegionShow = (props) => {
    const classes = useStyles();
    const translate = useTranslate();

    const WithProps = ({ record }) => {
        if (!area && record?.area?.data) {
            setArea(record?.area?.data);
        }
        return '';
    };
    const [area, setArea] = useState(null);

    const ShowTitle = ({ record }) => {
        return (
            <h3 className={classes.formTitle} fullWidth>
                {translate('ra.action.showing')}{' '}
                {translate('ra.navigation.resources.region', 1)}: {record.name}
            </h3>
        );
    };
    return (
        <Show
            {...props}
            actions={<ShowActions />}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.directory'
            )}: ${translate('ra.navigation.resources.region', 2)}`}
        >
            <SimpleShowLayout>
                <WithProps />
                <ShowTitle />
                <TextField source='id' label={translate('ra.input.id', 1)} />
                <ReferenceField
                    label={translate('ra.navigation.resources.category', 1)}
                    source='category_id'
                    reference='categories'
                >
                    <TextField source='name' />
                </ReferenceField>
                <TextField source='name' label={translate('ra.input.name', 1)} />
                <TextField source='slug' label={translate('ra.input.slug', 1)} />
                <div className={classes.map} fullWidth>
                    <div className={classes.show}>
                        {area && (
                            <GoogleMapComponentWrapper
                                name='map'
                                paths={area}
                                isPathShown={true}
                            />
                        )}
                        {!area && <div>{translate('ra.input.helper.no_area_data')}</div>}
                    </div>
                </div>
                <TextField
                    source='ordering'
                    label={translate('ra.input.ordering', 2)}
                />
                <BooleanField source='is_active' label={translate('ra.input.active')} />
                <TextField
                    source='internal_notes'
                    label={translate('ra.input.internal_note', 2)}
                />
                <DateField
                    source='updated_at'
                    label={translate('ra.input.last_updated_date')}
                />
                <DateField
                    source='created_at'
                    label={translate('ra.input.publication_date')}
                />
                <h4>
                    {translate('ra.message.associated')}{' '}
                    {translate('ra.navigation.resources.entity', 2).toLowerCase()}
                </h4>
                <RegionShowEntitiesList />
                <h4>
                    {translate('ra.message.associated')}{' '}
                    {translate('ra.navigation.resources.product', 2).toLowerCase()}
                </h4>
                <RegionShowProductsList />
                <MediaShowList />
            </SimpleShowLayout>
        </Show>
    );
};
