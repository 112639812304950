import * as React from 'react';
import { useTranslate } from 'ra-core';
import { List, Datagrid, ReferenceField, TextField } from 'react-admin';
import ListActions from '../ListActions';
import ListBulkActions from '../ListBulkActions';
import ListFilter from '../ListFilter';
import ListCategoryFilter from '../ListCategoryFilter';
import ListPagination from '../ListPagination';
import ListActiveField from '../ListActiveField';
import ListEmpty from '../ListEmpty';
import ResourceManagementButtons from '../ResourceManagementButtons';

export const TagList = (props) => {
    const translate = useTranslate();
    return (
        <List
            {...props}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.directory'
            )}: ${translate('ra.navigation.resources.tag', 2)}`}
            actions={<ListActions />}
            filters={<ListFilter />}
            bulkActionButtons={<ListBulkActions />}
            perPage={15}
            sort={{ field: 'slug', order: 'ASC' }}
            pagination={<ListPagination />}
            aside={
                <div>
                    <ListCategoryFilter />
                </div>
            }
            empty={<ListEmpty />}
        >
            <Datagrid>
                <TextField source='slug' label={translate('ra.input.slug', 1)} />
                <TextField source='name' label={translate('ra.input.name', 1)} />
                <ListActiveField
                    source='is_active'
                    label={translate('ra.input.active')}
                />
                <ReferenceField
                    label={translate('ra.navigation.resources.category', 1)}
                    source='category_id'
                    reference='categories'
                    sortable={false}
                >
                    <TextField source='name' />
                </ReferenceField>
                <ResourceManagementButtons />
            </Datagrid>
        </List>
    );
};
