import * as React from 'react';
import { useTranslate } from 'ra-core';
import { List, Datagrid, TextField } from 'react-admin';
import ListActions from '../ListActions';
import ListBulkActions from '../ListBulkActions';
import ListFilter from '../ListFilter';
import ListPagination from '../ListPagination';
import ListEmpty from '../ListEmpty';
import ListDateMonthAndDayField from '../ListDateMonthAndDayField';
import ListActiveField from '../ListActiveField';
import ResourceManagementButtons from '../ResourceManagementButtons';

export const MassSeasonList = (props) => {
    const translate = useTranslate();

    return (
        <List
            {...props}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.configuration'
            )}: ${translate('ra.navigation.resources.mass_season', 2)}`}
            actions={<ListActions />}
            filters={<ListFilter noActive={true} />}
            bulkActionButtons={<ListBulkActions />}
            perPage={15}
            sort={{ field: 'slug', order: 'ASC' }}
            pagination={<ListPagination />}
            empty={<ListEmpty />}
        >
            <Datagrid>
                <TextField source='slug' label={translate('ra.input.slug', 1)} />
                <TextField source='name' label={translate('ra.input.name', 1)} />
                <ListDateMonthAndDayField
                    source='date'
                    label={translate('ra.input.annual_date', 1)}
                />
                <TextField
                    source='easter_date_plus_days'
                    label={translate('ra.input.easter_date_plus_days')}
                />
                <ListActiveField
                    source='is_publish_times'
                    label={translate('ra.input.is_publish_times')}
                />
                <ListActiveField
                    source='is_active'
                    label={translate('ra.input.active')}
                />
                <ResourceManagementButtons />
            </Datagrid>
        </List>
    );
};
