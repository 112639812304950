import React, { useState, memo } from 'react';
import DataProvider from '../../providers/DataProvider';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useNotify } from 'ra-core';
import { Button } from 'react-admin';
import DescriptionIcon from '@material-ui/icons/Description';
import DownloadIcon from '@material-ui/icons/GetApp';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Grid from '@material-ui/core/Grid';
import { DeleteWithConfirmButton } from 'react-admin';
import MediaEditModal from './MediaEditModal';
import ArrayHelper from '../../helpers/ArrayHelper';
import ValidationHelper from '../../helpers/ValidationHelper';

const useStyles = makeStyles((theme) => ({
    image: {
        height: 100,
        objectFit: 'cover',
        width: 100,
    },
    hide: {
        maxHeight: 0,
        opacity: 0,
        overflow: 'hidden',
        transition: 'all .5s ease-out',
    },
    mediaList: {
        margin: 0,
        padding: 0,
        '& li': {
            backgroundColor: theme.palette.row.background,
            border: `1px solid ${theme.palette.row.border}`,
            display: 'grid',
            gridTemplateColumns: '140px 50% auto',
            listStyle: 'none',
            marginBottom: '.7rem',
            '&> div': {
                margin: 'auto 0',
            },
            '&:hover': {
                cursor: 'grab',
            },
        },
    },
    show: {
        maxHeight: 1000,
    },
}));

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const MediaList = memo(function MediaList({
    media,
    thisResource,
    classes,
    translate,
}) {
    const [deleted, setDeleted] = useState([]);
    const addDeleted = (id) => {
        setDeleted([...deleted, id]);
    };
    return media.map((media, index) => (
        <Draggable draggableId={media.id} index={index} key={media.id}>
            {(provided) => (
                <li
                    className={!deleted.includes(media.id) ? classes.show : classes.hide}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <div className={classes.image}>
                        {media.mime_type.includes('image') && (
                            <img
                                className={classes.image}
                                src={
                                    media['derived']?.['crop-1-1']?.['100w']?.['regular'] ??
                                    media.url
                                }
                                title={media.name}
                                alt={media.name}
                            />
                        )}
                        {!media.mime_type.includes('image') && (
                            <DescriptionIcon className={classes.document} />
                        )}
                    </div>
                    <div>{media.name}</div>
                    <Grid
                        container
                        direction='row'
                        justify='space-around'
                        alignItems='center'
                    >
                        <Button
                            onClick={() => {
                                DataProvider.getFile(media.url).then((blob) => {
                                    const URL = window.URL.createObjectURL(blob, {
                                        type: media.mime_type,
                                    });
                                    const link = document.createElement('a');
                                    link.href = URL;
                                    link.download = media.file_name;
                                    link.click();
                                    link.remove();
                                });
                            }}
                            label={translate('ra.action.download')}
                        >
                            <DownloadIcon />
                        </Button>
                        <MediaEditModal thisResource={thisResource} record={media} />
                        <DeleteWithConfirmButton
                            label={translate('ra.action.delete_permanently')}
                            confirmTitle={translate('ra.message.delete_media_title', {
                                name: media.name,
                            })}
                            confirmContent={`${translate(
                                'ra.message.delete_content'
                            )} ${translate('ra.message.cannot_be_undone')}`}
                            redirect={false}
                            record={media}
                            resource={`${thisResource.basePath}/${thisResource.id}/media`}
                            undoable={false}
                            onSuccess={(id) => {
                                addDeleted(id.data);
                            }}
                        />
                    </Grid>
                </li>
            )}
        </Draggable>
    ));
});

const MediaDraggableInput = (props) => {
    const { currentMedia, thisResource } = props;
    const [state, setState] = useState({ media: currentMedia });
    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();

    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const media = reorder(
            state.media,
            result.source.index,
            result.destination.index
        );
        setState({ media });
        updateOrdering(media);
    }

    function updateOrdering(media) {
        const chunkSize = process.env.REACT_APP_MAX_NUMBER_REQUESTS_PER_BULK;
        ArrayHelper.Chunk(media, chunkSize).map((chunk, chunkIndex) => {
            const bulkPayload = chunk.map((m, index) => {
                return {
                    _method: 'PATCH',
                    id: m.id,
                    order: index + 1 + chunkIndex * chunkSize,
                };
            });
            return DataProvider.updateSubResourceBatch(
                thisResource.basePath,
                'media',
                thisResource.id,
                bulkPayload
            )
                .then()
                .catch((error) => {
                    notify(ValidationHelper.FormatApiErrors(error));
                });
        });
    }

    return (
        <div style={{ width: '100%' }}>
            {currentMedia && currentMedia.length > 0 ? (
                <DragDropContext onDragEnd={onDragEnd}>
                    <ul className={classes.mediaList}>
                        <Droppable droppableId='list'>
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    <MediaList
                                        media={state.media}
                                        thisResource={thisResource}
                                        classes={classes}
                                        translate={translate}
                                    />
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </ul>
                </DragDropContext>
            ) : (
                <div>{translate('ra.message.no_media')}</div>
            )}
        </div>
    );
};

export default MediaDraggableInput;
