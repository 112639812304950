import * as React from 'react';
import { useTranslate } from 'ra-core';
import {
    useQuery,
    Loading,
    Error,
    FilterList,
    FilterListItem,
} from 'react-admin';
import UrlHelper from '../helpers/UrlHelper';
import CategoryIcon from '@material-ui/icons/Apps';
import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core';

const ServiceCategoryFilter = () => {
    const translate = useTranslate();
    const { data, loading, error } = useQuery({
        type: 'getList',
        resource: 'service-categories',
        payload: {
            pagination: { page: 1, perPage: -1 },
            filter: {},
            sort: { field: '-search_relevance,tree_name_path_derived', order: 'ASC' },
            flatten: true,
        },
    });

    const hashObj = UrlHelper.HashToObject(window.location.hash);
    const filterObj = hashObj?.filter ? JSON.parse(hashObj.filter) : null;
    const selectedCategory = filterObj?.category_id // Set by ListCategoryFilter
        ? filterObj.category_id
        : null;
    const selectedPath = filterObj?.service_category_path
        ? filterObj.service_category_path.split('|||')
        : [];

    if (loading) {
        return <Loading />;
    }
    if (error) {
        return <Error />;
    }
    if (!data) {
        return null;
    }

    return (
        <FilterList
            label={translate('ra.navigation.resources.service_category', 1)}
            icon={<CategoryIcon />}
        >
            {data.map((d) => {
                const isDisplay =
                    (selectedCategory === null || d.category_id === selectedCategory) &&
                    (d.tree_depth_derived === 0 || selectedPath.includes(d.parent_id));
                return (
                    <div
                        style={{
                            marginLeft: d.tree_depth_derived + 'rem',
                            display: isDisplay ? 'block' : 'none',
                        }}
                        className={d.parent_id}
                        key={d.parent_id}
                    >
                        <FilterListItem
                            label={d.name}
                            value={{
                                service_category_path: d.tree_id_path_derived,
                            }}
                            key={d.id}
                        />
                    </div>
                );
            })}
        </FilterList>
    );
};

const Card = withStyles((theme) => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginLeft: '1em',
            marginTop: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

const ListServiceCategoryFilter = () => (
    <Card>
        <CardContent>
            <ServiceCategoryFilter />
        </CardContent>
    </Card>
);

export default ListServiceCategoryFilter;
