import * as React from 'react';
import { useTranslate } from 'ra-core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    root: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        fontSize: '.7rem',
        fontWeight: 500,
        margin: '1rem auto',
        padding: theme.spacing(1),
        textAlign: 'center',
    },
}));

export default function ForgottenPasswordLink() {
    const translate = useTranslate();
    const classes = useStyles();
    return (
        <Typography className={classes.root}>
            <Link href='/#/forgot'>{translate('ra.auth.password_forgotten')}</Link>
        </Typography>
    );
}
