import keyBy from 'lodash/keyBy';

const MediaHelper = {
    Collections: (isKeyByCode = false) => {
        const array = process.env.REACT_APP_MEDIA_COLLECTIONS.split('|').map((d) => {
            const parts = d.split(':');
            return {
                id: parts[0],
                name: parts[1],
            };
        });
        return isKeyByCode ? keyBy(array, 'id') : array;
    },
    AcceptFileTypes: () => {
        process.env.REACT_APP_MEDIA_FILE_TYPES.split(',');
    },
    ConvertFileToBase64: (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file.rawFile);
        });
    },
};

export default MediaHelper;
