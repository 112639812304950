import * as React from 'react';
import { useTranslate } from 'ra-core';
import { makeStyles, Chip } from '@material-ui/core';
import { TextField, Filter, List, Datagrid, TextInput } from 'react-admin';
import ListActions from '../ListActions';
import ListBulkActions from '../ListBulkActions';
import ListPagination from '../ListPagination';
import ListEmpty from '../ListEmpty';
import ResourceManagementButtons from '../ResourceManagementButtons';

const useStyles = makeStyles({
    filter: {
        width: '15em',
    },
});

const useQuickFilterStyles = makeStyles((theme) => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));

const QuickFilter = ({ label }) => {
    const translate = useTranslate();
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={translate(label)} />;
};

const MetaCategoryFilter = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <TextInput
                label={translate('ra.action.search')}
                source='name'
                alwaysOn
                className={classes.filter}
            />
            <TextInput
                label={translate('ra.input.model', 1)}
                source='model'
                defaultValue=''
                className={classes.filter}
            />
            <TextInput
                label={translate('ra.input.attribute', 1)}
                source='attribute'
                defaultValue=''
                className={classes.filter}
            />
            {!props.trash && (
                <QuickFilter
                    source='trash'
                    label={translate('ra.input.trash.show')}
                    defaultValue={1}
                />
            )}
        </Filter>
    );
};

export const MetaCategoryList = (props) => {
    const translate = useTranslate();
    return (
        <List
            {...props}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.configuration'
            )}: ${translate('ra.navigation.resources.meta_category', 2)}`}
            actions={<ListActions />}
            filters={<MetaCategoryFilter />}
            bulkActionButtons={<ListBulkActions />}
            perPage={15}
            sort={{ field: 'model', order: 'ASC' }}
            pagination={<ListPagination />}
            empty={<ListEmpty />}
        >
            <Datagrid>
                <TextField source='model' label={translate('ra.input.model', 1)} />
                <TextField
                    source='attribute'
                    label={translate('ra.input.attribute', 1)}
                />
                <TextField source='name' label={translate('ra.input.name', 1)} />
                <TextField
                    source='ordering'
                    label={translate('ra.input.ordering', 1)}
                />
                <ResourceManagementButtons />
            </Datagrid>
        </List>
    );
};
