import React, { useState } from 'react';
import DataProvider from '../../providers/DataProvider';
import { useTranslate, useNotify } from 'ra-core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import MassTimeEditModal from './MassTimeEditModal';
import MassTimeCancellationsModal from './MassTimeCancellationsModal';
import { DeleteWithConfirmButton } from 'react-admin';
import ValidationHelper from '../../helpers/ValidationHelper';

export const MassTimeTime = ({ time }) => {
    const useStyles = makeStyles((theme) => ({
        container: {
            backgroundColor: theme.palette.row.background,
            border: `1px solid ${theme.palette.row.border}`,
            display: 'grid',
            gridTemplateColumns: '40% 20% auto',
            padding: '1rem',
            '&> div': {
                margin: 'auto 0',
            },
        },
        hide: {
            maxHeight: 0,
            opacity: 0,
            overflow: 'hidden',
            transition: 'all .5s ease-out',
        },
        label: {
            display: 'inline',
        },
        show: {
            maxHeight: 1000,
        },
        name: {
            paddingBottom: '.5rem',
        },
    }));

    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles();
    const [isDeleted, setIsDeleted] = useState(false);
    const [isActive, setIsActive] = useState(time.is_active);
    const toggleIsActive = () => {
        const newIsActive = !isActive;
        time.is_active = newIsActive;
        setIsActive(newIsActive);
        return DataProvider.update('mass-times', {
            id: time.id,
            data: { is_active: newIsActive ? 1 : 0 },
        })
            .then()
            .catch((error) => {
                notify(ValidationHelper.FormatApiErrors(error));
            });
    };
    const repetitionMsg = time.repetition_description_derived
        ? ` - ${time.repetition_description_derived}`
        : '';
    const typeMsg = time.mass_type_name_derived
        ? ` (${time.mass_type_name_derived})`
        : '';
    const startDateMsg = time.repetition
        ? `${translate('ra.input.start_date', 1)}: ${time.date_str_derived}`
        : '';
    const endDateMsg =
        time.repetition && time.end_date
            ? `${translate('ra.input.end_date', 1)}: ${time.end_date_str_derived}`
            : '';
    const notesMsg = time.notes
        ? `${translate('ra.input.note', 2)}: ${time.notes}`
        : '';
    const cancellationsMsg = time.cancellations?.length
        ? translate('ra.input.helper.has_cancellation', 2)
        : '';
    const isWebCastMsg = time.is_web_cast
        ? translate('ra.input.helper.is_web_cast')
        : '';
    return (
        <div className={!isDeleted ? classes.show : classes.hide}>
            <div className={classes.container}>
                <div>
                    <div className={classes.name}>
                        {time.time_str_derived}
                        {repetitionMsg}
                        {typeMsg}
                    </div>
                    <small className={classes.small}>
                        <div>{time.mass_language_name_derived}</div>
                        <div>{startDateMsg}</div>
                        <div>{endDateMsg}</div>
                        <div>{notesMsg}</div>
                        <div>{cancellationsMsg}</div>
                        <div>{isWebCastMsg}</div>
                    </small>
                </div>
                <div>
                    <Switch checked={isActive} onChange={toggleIsActive} />
                    <div className={classes.label}>
                        <small>
                            {isActive
                                ? translate('ra.input.active')
                                : translate('ra.input.inactive')}
                        </small>
                    </div>
                </div>
                <Grid
                    container
                    direction='row'
                    justify='space-around'
                    alignItems='center'
                >
                    <MassTimeEditModal record={time} />
                    {time.category !== 'SPECIAL' && time.repetition?.length && (
                        <MassTimeCancellationsModal record={time} />
                    )}
                    <DeleteWithConfirmButton
                        label={translate('ra.action.delete_permanently')}
                        confirmTitle={translate('ra.message.delete_mass_time_title')}
                        confirmContent={`${translate(
                            'ra.message.delete_content'
                        )} ${translate('ra.message.cannot_be_undone')}`}
                        redirect={false}
                        record={time}
                        resource={'mass-times'}
                        undoable={false}
                        onSuccess={() => {
                            setIsDeleted(true);
                        }}
                    />
                </Grid>
            </div>
        </div>
    );
};
