import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import FormTheme from '../../themes/DefaultThemeForm';
import { Show, SimpleShowLayout, TextField, DateField } from 'react-admin';
import ShowActions from '../ShowActions';
import { RoleShowUsersList } from './RoleShowUsersList';

const useStyles = makeStyles(() => FormTheme.styles, {
    name: 'RoleForm',
});

export const RoleShow = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const ShowTitle = ({ record }) => {
        return (
            <h3 className={classes.formTitle} fullWidth>
                {translate('ra.action.showing')}{' '}
                {translate('ra.navigation.resources.role', 1)}: {record.name}
            </h3>
        );
    };
    return (
        <Show
            {...props}
            actions={<ShowActions />}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.configuration'
            )}: ${translate('ra.navigation.resources.role', 2)}`}
        >
            <SimpleShowLayout>
                <ShowTitle />
                <TextField source='id' label={translate('ra.input.id', 1)} />
                <TextField source='name' label={translate('ra.input.name', 1)} />
                <TextField
                    source='description'
                    label={translate('ra.input.description', 1)}
                />
                <TextField
                    source='ordering'
                    label={translate('ra.input.ordering', 2)}
                />
                <TextField
                    source='internal_notes'
                    label={translate('ra.input.internal_note', 2)}
                />
                <DateField
                    source='updated_at'
                    label={translate('ra.input.last_updated_date')}
                />
                <DateField
                    source='created_at'
                    label={translate('ra.input.publication_date')}
                />
                <div>
                    <h4>
                        {translate('ra.message.associated')}{' '}
                        {translate('ra.navigation.resources.user', 2).toLowerCase()}
                    </h4>
                </div>
                <RoleShowUsersList />
            </SimpleShowLayout>
        </Show>
    );
};
