import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import DataProvider from '../providers/DataProvider';
import {
    useDeleteWithConfirmController,
    useRefresh,
    useTranslate,
    useNotify,
} from 'ra-core';
import { Button, Confirm } from 'react-admin';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const CustomPermanentlyDeleteButton = ({
    resource,
    record = {},
    basePath,
    redirect,
    onClick,
    isPlural,
    ...rest
}) => {
    const { open, loading, handleDialogOpen, handleDialogClose } =
        useDeleteWithConfirmController({
            resource,
            record,
            redirect,
            basePath,
            onClick,
        });
    const refresh = useRefresh();
    const translate = useTranslate();
    const notify = useNotify();

    const useStyles = makeStyles((theme) => ({
        deleteButton: {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: fade(theme.palette.error.main, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
    }));
    const classes = useStyles();

    const handlePermanentlyDelete = () => {
        return !isPlural
            ? DataProvider.deletePermanently(basePath, { id: record.id })
                .then(() => {
                    notify(translate('ra.input.trash.empty.success'));
                    refresh();
                })
                .catch((error) => {
                    notify(error.message);
                })
            : DataProvider.deletePermanentlyBulk(basePath, { ids: record })
                .then(() => {
                    notify(translate('ra.input.trash.empty.success_plural'));
                    refresh();
                })
                .catch((error) => {
                    notify(error.message);
                });
    };

    return (
        <Fragment>
            <Button
                onClick={handleDialogOpen}
                className={classes.deleteButton}
                label='ra.input.trash.empty.button'
                {...rest}
            >
                <DeleteForeverIcon />
            </Button>
            <Confirm
                isOpen={open}
                loading={loading}
                title={
                    !isPlural
                        ? 'ra.input.trash.empty.title'
                        : 'ra.input.trash.empty.title_plural'
                }
                content={
                    !isPlural
                        ? 'ra.input.trash.empty.message'
                        : 'ra.input.trash.empty.message_plural'
                }
                translateOptions={{
                    name: resource,
                    id: record.id,
                }}
                onConfirm={handlePermanentlyDelete}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
};

export default CustomPermanentlyDeleteButton;
