import theme from './DefaultTheme';

const FormTheme = {
    styles: {
        alert: {
            backgroundColor: '#f44336',
            '&:hover': {
                backgroundColor: '#d63b2f',
            },
        },
        modal: {
            button: {
                marginLeft: -8,
            },
            field: {
                width: '100%',
            },
            modal: {
                MsOverflowStyle: 'none',
                margin: 'auto',
                overflowY: 'scroll',
                scrollbarWidth: 'none',
                width: 600,
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
            },
            paper: {
                backgroundColor: theme.palette.background.paper,
                border: `2px solid ${theme.palette.text.primary}`,
                borderRadius: 5,
                boxShadow: theme.shadows[5],
                padding: theme.spacing(2, 4, 3),
            },
        },
        form: {
            maxWidth: 1135,
        },
        formSubTitle: {
            fontSize: '1.1rem',
            fontWeight: 500,
        },
        formSubForm: {
            margin: '1rem 0',
            '& button': {
                margin: '0 0 1rem .5rem',
            },
        },
        formTitle: {
            fontSize: '1.5rem',
            fontWeight: 500,
            margin: '.25rem 0 1rem',
        },
        groupFields: {
            marginBottom: '2rem',
        },
        inlineNote: {
            padding: '1rem 0',
        },
        map: {
            padding: '1rem 0',
        },
        picker: {
            '& p': {
                color: '#f00',
            },
        },
        show: {
            maxWidth: 1135,
        },
        showDataGrid: {
            marginBottom: '3rem',
        },
        strong: {
            fontWeight: 600,
        },
    },
};

export default FormTheme;
