import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import FormTheme from '../../themes/DefaultThemeForm';
import {
    useNotify,
    useRefresh,
    TextField,
    TextInput,
    Edit,
    SimpleForm,
} from 'react-admin';
import CustomInputs from '../CustomInputs';
import CustomToolbar from '../CustomToolbar';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import GoogleMapComponentWrapper from '../MapField';
import EditActions from '../EditActions';
import LocationHelper from '../../helpers/LocationHelper';
import ValidationHelper from '../../helpers/ValidationHelper';

const useStyles = makeStyles(() => FormTheme.styles, {
    name: 'CustomLocationForm',
});

export const CustomLocationEdit = ({ ...props }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const onSuccess = () => {
        notify(translate('ra.notification.updated', 1));
        refresh();
    };

    const validate = (values) =>
        ValidationHelper.ValidateRequired(
            values,
            ['criteria', 'formatted_address'],
            translate('ra.validation.required')
        );

    const transform = (data) => {
        data.lat = document.getElementById('lat').value;
        data.lng = document.getElementById('lng').value;
        return data;
    };

    // Set initial state
    const WithProps = ({ record }) => {
        if (!location.lat || !location.lng) {
            setLocation({ lat: record.lat, lng: record.lng });
        }
        return '';
    };

    // Map states
    const [location, setLocation] = useState({
        lat: null,
        lng: null,
    });
    const [formattedAddress, setFormattedAddress] = useState(
        translate('ra.input.helper.custom_location_formatted_address')
    );
    const [locationSearch, setLocationSearch] = useState(null);
    const getLocationSearch = (place) => {
        LocationHelper.SetLatLngByPlaces(
            place,
            setLocationSearch,
            setLocation,
            setFormattedAddress,
            translate('ra.input.helper.auto_generated')
        );
    };

    return (
        <Edit
            {...props}
            actions={<EditActions />}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.configuration'
            )}: ${translate('ra.navigation.resources.custom_location', 2)}`}
            transform={transform}
            onSuccess={onSuccess}
            onFailure={(e) => {
                notify(ValidationHelper.FormatApiErrors(e));
            }}
            undoable={false}
        >
            <SimpleForm
                className={classes.form}
                toolbar={<CustomToolbar alwaysEnableSaveButton />}
                validate={validate}
                warnWhenUnsavedChanges
            >
                <WithProps />
                <TextField source='id' label={translate('ra.input.id', 1)} />
                <TextInput
                    variant='outlined'
                    label={translate('ra.input.criteria', 1)}
                    source='criteria'
                    helperText={translate('ra.input.helper.custom_location_criteria')}
                    fullWidth
                />
                <div className={classes.map} fullWidth>
                    <div>
                        <GooglePlacesAutocomplete
                            selectProps={{
                                locationSearch,
                                placeholder: translate('ra.input.helper.search_for_location'),
                                onChange: getLocationSearch,
                            }}
                            autocompletionRequest={{
                                apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                                minLengthAutocomplete: 3,
                                componentRestrictions: {
                                    country: process.env.REACT_APP_GOOGLE_MAPS_COUNTRY.split('|'),
                                },
                            }}
                        />
                    </div>
                    <GoogleMapComponentWrapper
                        name='map'
                        lat={location.lat}
                        lng={location.lng}
                        zoom={18}
                        draggable={true}
                        passLatLng={setLocation}
                    />
                </div>
                <TextInput
                    id='lat'
                    name='lat'
                    variant='outlined'
                    label={translate('ra.input.lat', 1)}
                    source='lat'
                    inputProps={{
                        value: location.lat,
                    }}
                    fullWidth
                />
                <TextInput
                    id='lng'
                    name='lng'
                    variant='outlined'
                    label={translate('ra.input.lng', 1)}
                    source='lng'
                    inputProps={{
                        value: location.lng,
                    }}
                    fullWidth
                />
                <TextInput
                    variant='outlined'
                    label={translate('ra.input.formatted_address', 1)}
                    source='formatted_address'
                    helperText={formattedAddress}
                    fullWidth
                />
                <CustomInputs.InternalNotesTextInput />
            </SimpleForm>
        </Edit>
    );
};
