import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import { MediaShowList } from '../media/MediaShowList';
import FormTheme from '../../themes/DefaultThemeForm';
import { Show, SimpleShowLayout, TextField, DateField } from 'react-admin';
import ShowActions from '../ShowActions';

const useStyles = makeStyles(() => FormTheme.styles, {
    name: 'CategoryForm',
});

export const CategoryShow = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const ShowTitle = ({ record }) => {
        return (
            <h3 className={classes.formTitle} fullWidth>
                {translate('ra.action.showing')}{' '}
                {translate('ra.navigation.resources.category', 1)}: {record.name}
            </h3>
        );
    };
    return (
        <Show
            {...props}
            actions={<ShowActions />}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.configuration'
            )}: ${translate('ra.navigation.resources.category', 2)}`}
        >
            <SimpleShowLayout className={classes.show}>
                <ShowTitle />
                <TextField source='id' label={translate('ra.input.id', 1)} />
                <TextField source='name' label={translate('ra.input.name', 1)} />
                <TextField source='slug' label={translate('ra.input.slug', 1)} />
                <TextField
                    source='ordering'
                    label={translate('ra.input.ordering', 2)}
                />
                <TextField
                    source='internal_notes'
                    label={translate('ra.input.internal_note', 2)}
                />
                <DateField
                    source='updated_at'
                    label={translate('ra.input.last_updated_date')}
                />
                <DateField
                    source='created_at'
                    label={translate('ra.input.publication_date')}
                />
                <MediaShowList />
            </SimpleShowLayout>
        </Show>
    );
};
