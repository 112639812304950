import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import FormTheme from '../../themes/DefaultThemeForm';
import {
    useNotify,
    useRefresh,
    useRedirect,
    Create,
    SimpleForm,
} from 'react-admin';
import CustomInputs from '../CustomInputs';
import CreateActions from '../CreateActions';
import ValidationHelper from '../../helpers/ValidationHelper';

const useStyles = makeStyles(() => FormTheme.styles, {
    name: 'MassSeasonForm',
});

let date = null;

export const MassSeasonCreate = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = ({ data }) => {
        notify(translate('ra.notification.created'));
        redirect(data.id);
        refresh();
    };

    const [selectedDate, setSelectedDate] = useState(null);
    const handleDateChange = (d) => {
        setSelectedDate(d);
        date = d?.toISOString().slice(5, 10);
    };

    const validate = (values) => {
        const errors = {
            ...ValidationHelper.ValidateRequired(
                values,
                ['name', 'meta_description'],
                translate('ra.validation.required')
            ),
            ...ValidationHelper.ValidateLength(
                values,
                'meta_description',
                160,
                translate('ra.validation.maxLength', {
                    max: 160,
                })
            ),
        };

        if (
            date &&
            values.easter_date_plus_days != null &&
            Number.isInteger(values.easter_date_plus_days)
        ) {
            errors.easter_date_plus_days = translate(
                'ra.validation.use_one_of_date_or_easter_days'
            );
        }
        if (!date && values.easter_date_plus_days == null) {
            errors.date = translate('ra.validation.one_required');
            errors.easter_date_plus_days = translate('ra.validation.one_required');
        }

        return errors;
    };

    const transform = (data) => {
        return {
            ...data,
            date,
            is_publish_times: data.is_publish_times ? 1 : 0,
            is_active: data.is_active ? 1 : 0,
        };
    };

    return (
        <Create
            {...props}
            actions={<CreateActions />}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.configuration'
            )}: ${translate('ra.navigation.resources.mass_season', 2)}`}
            transform={transform}
            onSuccess={onSuccess}
            onFailure={(e) => {
                notify(ValidationHelper.FormatApiErrors(e));
            }}
            undoable={false}
        >
            <SimpleForm
                validate={validate}
                warnWhenUnsavedChanges
                redirect='list'
                className={classes.form}
            >
                <h3 className={classes.formTitle} fullWidth>
                    {translate('ra.action.creating')}
                    {' - '}
                    {translate('ra.navigation.resources.mass_season', 1)}
                </h3>
                <p fullWidth>
                    <strong>{translate('ra.action.remember')}</strong>{' '}
                    {translate('ra.message.mass_season_remember')}
                </p>
                <p fullWidth>
                    <strong>{translate('ra.action.christmas')}</strong>{' '}
                    {translate('ra.message.mass_season_christmas')}
                </p>
                <p fullWidth>
                    <strong>{translate('ra.action.easter')}</strong>{' '}
                    {translate('ra.message.mass_season_easter')}
                </p>
                <p fullWidth>
                    <strong>{translate('ra.action.note')}</strong>{' '}
                    {translate('ra.message.slug_auto_generated')}
                </p>
                <CustomInputs.NameTextInput />
                <CustomInputs.MetaDescriptionTextInput />
                <CustomInputs.DescriptionRichTextInput />
                <div className={classes.groupFields} fullWidth>
                    <div className={classes.inlineNote} fullWidth>
                        <span className={classes.strong}>
                            {translate('ra.action.note')}
                        </span>{' '}
                        {translate('ra.input.helper.enter_only_one_date_or_easter_days')}
                    </div>
                    <CustomInputs.DatePicker
                        label={translate('ra.input.annual_date', 1)}
                        selectedDate={selectedDate}
                        handleDateChange={handleDateChange}
                    />
                    <CustomInputs.EasterDatePlusDaysNumberInput />
                </div>
                <CustomInputs.IsPublishTimesBooleanInput fullWidth />
                <CustomInputs.IsActiveBooleanInput />
                <CustomInputs.InternalNotesTextInput />
            </SimpleForm>
        </Create>
    );
};
