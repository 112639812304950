import * as React from 'react';
import { useTranslate } from 'ra-core';
import { List, Datagrid, TextField } from 'react-admin';
import ListActions from '../ListActions';
import ListBulkActions from '../ListBulkActions';
import ListFilter from '../ListFilter';
import ListPagination from '../ListPagination';
import ListEmpty from '../ListEmpty';
import ListActiveField from '../ListActiveField';
import ResourceManagementButtons from '../ResourceManagementButtons';

export const MassLanguageList = (props) => {
    const translate = useTranslate();

    return (
        <List
            {...props}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.configuration'
            )}: ${translate('ra.navigation.resources.mass_language', 2)}`}
            actions={<ListActions />}
            filters={<ListFilter noActive={true} />}
            bulkActionButtons={<ListBulkActions />}
            perPage={15}
            sort={{ field: 'slug', order: 'ASC' }}
            pagination={<ListPagination />}
            empty={<ListEmpty />}
        >
            <Datagrid>
                <TextField source='slug' label={translate('ra.input.slug', 1)} />
                <TextField source='name' label={translate('ra.input.name', 1)} />
                <ListActiveField
                    source='is_active'
                    label={translate('ra.input.active')}
                />
                <ResourceManagementButtons />
            </Datagrid>
        </List>
    );
};
