import * as React from 'react';
import { Layout } from 'react-admin';
import CustomAppBar from './CustomAppBar';
import CustomMenu from './CustomMenu';
import CustomNotification from './CustomNotification';

const CustomLayout = (props) => (
    <Layout
        {...props}
        appBar={CustomAppBar}
        menu={CustomMenu}
        notification={CustomNotification}
    />
);

export default CustomLayout;
