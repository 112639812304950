import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import FormTheme from '../../themes/DefaultThemeForm';
import { Show, SimpleShowLayout, TextField, DateField } from 'react-admin';
import ShowActions from '../ShowActions';
import GoogleMapComponentWrapper from '../MapField';

const useStyles = makeStyles(() => FormTheme.styles, {
    name: 'CustomLocationForm',
});

export const CustomLocationShow = (props) => {
    const classes = useStyles();
    const translate = useTranslate();

    const WithProps = ({ record }) => {
        if (!location.lat || !location.lng) {
            setLocation({ lat: record.lat, lng: record.lng });
        }
        return '';
    };
    const [location, setLocation] = useState({
        lat: null,
        lng: null,
    });

    const ShowTitle = ({ record }) => {
        return (
            <h3 className={classes.formTitle} fullWidth>
                {translate('ra.action.showing')}{' '}
                {translate('ra.navigation.resources.custom_location', 1)}:{' '}
                {record.criteria}
            </h3>
        );
    };
    return (
        <Show
            {...props}
            actions={<ShowActions />}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.configuration'
            )}: ${translate('ra.navigation.resources.custom_location', 2)}`}
        >
            <SimpleShowLayout className={classes.show}>
                <WithProps />
                <ShowTitle />
                <TextField source='id' label={translate('ra.input.id', 1)} />
                <TextField
                    source='criteria'
                    label={translate('ra.input.criteria', 1)}
                />
                <div className={classes.map} fullWidth>
                    <GoogleMapComponentWrapper
                        name='map'
                        lat={location.lat}
                        lng={location.lng}
                        zoom={18}
                        draggable={false}
                    />
                </div>
                <TextField source='lat' label={translate('ra.input.lat', 1)} />
                <TextField source='lng' label={translate('ra.input.lng', 1)} />
                <TextField
                    source='formatted_address'
                    label={translate('ra.input.formatted_address', 1)}
                />
                <TextField
                    source='internal_notes'
                    label={translate('ra.input.internal_note', 2)}
                />
                <DateField
                    source='updated_at'
                    label={translate('ra.input.last_updated_date')}
                />
                <DateField
                    source='created_at'
                    label={translate('ra.input.publication_date')}
                />
            </SimpleShowLayout>
        </Show>
    );
};
