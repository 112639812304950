import React from 'react';
import { compose, withProps } from 'recompose';
import theme from '../themes/DefaultTheme';
import { withGoogleMap, GoogleMap, Marker, Polygon } from 'react-google-maps';

// ES Lint hint
/*global google*/

const GoogleMapComponent = compose(
    withProps({
        loadingElement: <div style={{ height: '100%' }} />,
        containerElement: <div style={{ height: '600px' }} />,
        mapElement: <div style={{ height: '100%', width: '100%' }} />,
    }),
    withGoogleMap
)((props) => {
    const zoom = () => {
        return props.zoom || null;
    };

    const center = () => {
        return props.lat && props.lng
            ? new google.maps.LatLng(props.lat, props.lng)
            : null;
    };

    const MultiPolygon = () => {
        if (props.paths.length) {
            return props.paths[0].coordinates.map((p) => {
                const polygon = p.map((d) => {
                    return new google.maps.LatLng(d[1], d[0]);
                });
                return (
                    <Polygon
                        path={polygon}
                        options={{
                            strokeColor: theme.palette.maps.stroke,
                            strokeOpacity: 1,
                            strokeWeight: 2,
                            fillColor: theme.palette.maps.fill,
                            fillOpacity: 0.2,
                        }}
                        key={polygon}
                    ></Polygon>
                );
            });
        }
    };

    const bounds = () => {
        let bounds = new google.maps.LatLngBounds();
        if (props.paths.length) {
            props.paths[0].coordinates.forEach((p) => {
                p.forEach((d) => {
                    bounds.extend(new google.maps.LatLng(d[1], d[0]));
                });
            });
        }
        return bounds;
    };

    return (
        <GoogleMap
            ref={(map) => map && props.paths?.length && map.fitBounds(bounds())}
            defaultZoom={zoom()}
            center={center()}
            defaultOptions={{
                fullscreenControl: true,
                streetViewControl: false,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                    position: google.maps.ControlPosition.TOP_LEFT,
                    mapTypeIds: [
                        google.maps.MapTypeId.ROADMAP,
                        google.maps.MapTypeId.HYBRID,
                    ],
                },
            }}
        >
            {props.isPathShown && <MultiPolygon />}

            {props.isMarkerShown && (
                <Marker
                    position={new google.maps.LatLng(props.lat, props.lng)}
                    draggable={props.draggable}
                    onDragEnd={(e) => {
                        props.handleChange(
                            { lat: e.latLng.lat(), lng: e.latLng.lng() }
                        );
                    }}
                />
            )}
        </GoogleMap>
    );
});

const GoogleMapComponentWrapper = (props) => {
    return (
        <GoogleMapComponent
            lat={props.lat}
            lng={props.lng}
            paths={props.paths}
            isPathShown={props.isPathShown}
            zoom={props.zoom}
            draggable={props.draggable}
            isMarkerShown={true}
            handleChange={props.passLatLng}
        />
    );
};

export default GoogleMapComponentWrapper;
