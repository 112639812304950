import * as React from 'react';
import Typography from '@material-ui/core/Typography';

const ListDateMonthAndDayField = ({ record = {}, source, label }) => {
    return (
        <Typography component='span' variant='body2' label={label}>
            {record[source]
                ? new Date(`1004-${record[source]}`).toLocaleDateString('en-US', {
                    month: 'long',
                    day: 'numeric',
                })
                : null}
        </Typography>
    );
};

ListDateMonthAndDayField.defaultProps = {
    addLabel: true,
};

export default ListDateMonthAndDayField;
