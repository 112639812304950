import * as React from 'react';
import { TopToolbar, ListButton, CreateButton, EditButton } from 'react-admin';
import CustomDeleteButton from './CustomDeleteButton';

const ShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
        <CreateButton basePath={basePath} />
        <EditButton basePath={basePath} record={data} />
        <CustomDeleteButton basePath={basePath} record={data} resource={resource} />
    </TopToolbar>
);

export default ShowActions;
