import * as React from 'react';
import AuthProvider from '../providers/AuthProvider';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form } from 'react-final-form';
import { useTranslate, useNotify, useSafeSetState } from 'ra-core';
import { Notification } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import PasswordResetWrapper from '../layout/PasswordResetWrapper';
import PasswordResetTheme from '../themes/DefaultThemePasswordReset';
import ValidationHelper from '../helpers/ValidationHelper';

const useStyles = makeStyles(() => PasswordResetTheme.styles, {
    name: 'ChangePasswordForm',
});

const Input = ({
    meta: { touched, error, submitError },
    input: inputProps,
    ...props
}) => (
    <TextField
        error={!!(touched && (error || submitError))}
        helperText={touched && (error || ValidationHelper.FormatError(submitError))}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const ChangePassword = (props) => {
    const [loading, setLoading] = useSafeSetState(false);
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles(props);

    const validate = (values) => ({
        ...ValidationHelper.ValidateRequired(
            values,
            ['password_current', 'password', 'password_confirmation'],
            translate('ra.validation.required')
        ),
        ...ValidationHelper.ValidateMatch(
            values,
            'password',
            'password_confirmation',
            translate('ra.validation.password_mismatch')
        ),
        ...ValidationHelper.ValidatePasswordStrength(
            values,
            'password',
            translate('ra.validation.password_too_weak')
        ),
    });

    const submit = (values) => {
        setLoading(true);
        values.token = props.match.params.token;
        return AuthProvider.changePassword(values).then((response) => {
            setLoading(false);
            if (response.status === 200) {
                notify('ra.auth.password_change_success');
                setTimeout(() => (window.location.href = '/'), 4500);
            } else {
                return response.json().then((data) => data.errors);
            }
        });
    };

    return (
        <PasswordResetWrapper header={translate('ra.auth.password_change')}>
            <Form
                onSubmit={submit}
                validate={validate}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    id='password_current'
                                    name='password_current'
                                    component={Input}
                                    label={translate('ra.auth.password_current')}
                                    type='password'
                                    disabled={loading}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    id='password'
                                    name='password'
                                    component={Input}
                                    label={translate('ra.auth.new_password')}
                                    type='password'
                                    disabled={loading}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    id='password_confirmation'
                                    name='password_confirmation'
                                    component={Input}
                                    label={translate('ra.auth.password_confirmation')}
                                    type='password'
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <CardActions>
                            <Button
                                variant='contained'
                                type='submit'
                                color='primary'
                                disabled={loading}
                                className={classes.button}
                            >
                                {loading && (
                                    <CircularProgress
                                        className={classes.icon}
                                        size={18}
                                        thickness={2}
                                    />
                                )}
                                {translate('ra.auth.password_change')}
                            </Button>
                        </CardActions>
                        <Notification />
                    </form>
                )}
            />
        </PasswordResetWrapper>
    );
};

export default ChangePassword;
