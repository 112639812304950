import React from 'react';
import { useTranslate } from 'ra-core';
import { Admin, Resource } from 'react-admin';
import CustomRoutes from './routes';
import i18nProvider from './i18n/i18nProvider';
import AuthProvider from './providers/AuthProvider';
import DataProvider from './providers/DataProvider';
import Theme from './themes/DefaultTheme';
import CustomLayout from './layout/CustomLayout';
import LoginPage from './pages/Login';
import Dashboard from './pages/Dashboard';

import { UserList } from './components/user/UserList';
import { UserCreate } from './components/user/UserCreate';
import { UserEdit } from './components/user/UserEdit';
import { UserShow } from './components/user/UserShow';

import { CategoryList } from './components/category/CategoryList';
import { CategoryCreate } from './components/category/CategoryCreate';
import { CategoryEdit } from './components/category/CategoryEdit';
import { CategoryShow } from './components/category/CategoryShow';

import { CustomLocationList } from './components/custom-locations/CustomLocationList';
import { CustomLocationCreate } from './components/custom-locations/CustomLocationCreate';
import { CustomLocationEdit } from './components/custom-locations/CustomLocationEdit';
import { CustomLocationShow } from './components/custom-locations/CustomLocationShow';

import { MetaCategoryList } from './components/meta-category/MetaCategoryList';
import { MetaCategoryCreate } from './components/meta-category/MetaCategoryCreate';
import { MetaCategoryEdit } from './components/meta-category/MetaCategoryEdit';
import { MetaCategoryShow } from './components/meta-category/MetaCategoryShow';

import { GoalList } from './components/goal/GoalList';
import { GoalCreate } from './components/goal/GoalCreate';
import { GoalEdit } from './components/goal/GoalEdit';
import { GoalShow } from './components/goal/GoalShow';

import { DemographicFocusList } from './components/demographic-focus/DemographicFocusList';
import { DemographicFocusCreate } from './components/demographic-focus/DemographicFocusCreate';
import { DemographicFocusEdit } from './components/demographic-focus/DemographicFocusEdit';
import { DemographicFocusShow } from './components/demographic-focus/DemographicFocusShow';

import { TagList } from './components/tag/TagList';
import { TagCreate } from './components/tag/TagCreate';
import { TagEdit } from './components/tag/TagEdit';
import { TagShow } from './components/tag/TagShow';

import { ServiceCategoryList } from './components/service-category/ServiceCategoryList';
import { ServiceCategoryCreate } from './components/service-category/ServiceCategoryCreate';
import { ServiceCategoryEdit } from './components/service-category/ServiceCategoryEdit';
import { ServiceCategoryShow } from './components/service-category/ServiceCategoryShow';

import { EntityList } from './components/entity/EntityList';
import { EntityCreate } from './components/entity/EntityCreate';
import { EntityEdit } from './components/entity/EntityEdit';
import { EntityShow } from './components/entity/EntityShow';

import { ProductList } from './components/product/ProductList';
import { ProductCreate } from './components/product/ProductCreate';
import { ProductEdit } from './components/product/ProductEdit';
import { ProductShow } from './components/product/ProductShow';

import { RegionList } from './components/region/RegionList';
import { RegionCreate } from './components/region/RegionCreate';
import { RegionEdit } from './components/region/RegionEdit';
import { RegionShow } from './components/region/RegionShow';

import { PersonnelList } from './components/personnel/PersonnelList';
import { PersonnelCreate } from './components/personnel/PersonnelCreate';
import { PersonnelEdit } from './components/personnel/PersonnelEdit';
import { PersonnelShow } from './components/personnel/PersonnelShow';

import { RoleList } from './components/role/RoleList';
import { RoleCreate } from './components/role/RoleCreate';
import { RoleEdit } from './components/role/RoleEdit';
import { RoleShow } from './components/role/RoleShow';

import { MassLanguageList } from './components/mass-language/MassLanguageList';
import { MassLanguageCreate } from './components/mass-language/MassLanguageCreate';
import { MassLanguageEdit } from './components/mass-language/MassLanguageEdit';
import { MassLanguageShow } from './components/mass-language/MassLanguageShow';

import { MassTypeList } from './components/mass-type/MassTypeList';
import { MassTypeCreate } from './components/mass-type/MassTypeCreate';
import { MassTypeEdit } from './components/mass-type/MassTypeEdit';
import { MassTypeShow } from './components/mass-type/MassTypeShow';

import { MassSeasonList } from './components/mass-season/MassSeasonList';
import { MassSeasonCreate } from './components/mass-season/MassSeasonCreate';
import { MassSeasonEdit } from './components/mass-season/MassSeasonEdit';
import { MassSeasonShow } from './components/mass-season/MassSeasonShow';

function App() {
    const translate = useTranslate();
    return (
        <Admin
            loginPage={LoginPage}
            title={process.env.REACT_APP_TITLE}
            layout={CustomLayout}
            theme={Theme}
            dashboard={Dashboard}
            i18nProvider={i18nProvider}
            authProvider={AuthProvider}
            dataProvider={DataProvider}
            customRoutes={CustomRoutes}
        >
            {(permissions) => [
                // Categories
                <Resource
                    name='categories'
                    key='categories'
                    options={{ label: translate('ra.navigation.resources.category', 2) }}
                    list={
                        permissions.includes('system-configuration') ? CategoryList : null
                    }
                    create={
                        permissions.includes('system-configuration') ? CategoryCreate : null
                    }
                    edit={
                        permissions.includes('system-configuration') ? CategoryEdit : null
                    }
                    show={
                        permissions.includes('system-configuration') ? CategoryShow : null
                    }
                />,

                // Meta categories
                <Resource
                    name='meta-categories'
                    key='meta-categories'
                    options={{
                        label: translate('ra.navigation.resources.meta_category', 2),
                    }}
                    list={
                        permissions.includes('system-configuration')
                            ? MetaCategoryList
                            : null
                    }
                    create={
                        permissions.includes('system-configuration')
                            ? MetaCategoryCreate
                            : null
                    }
                    edit={
                        permissions.includes('system-configuration')
                            ? MetaCategoryEdit
                            : null
                    }
                    show={
                        permissions.includes('system-configuration')
                            ? MetaCategoryShow
                            : null
                    }
                />,

                // Custom locations
                permissions.includes('system-configuration') ? (
                    <Resource
                        name='custom-locations'
                        key='custom-locations'
                        options={{
                            label: translate('ra.navigation.resources.custom_location', 2),
                        }}
                        list={CustomLocationList}
                        create={CustomLocationCreate}
                        edit={CustomLocationEdit}
                        show={CustomLocationShow}
                    />
                ) : null,

                // Mass languages
                <Resource
                    name='mass-languages'
                    key='mass-languages'
                    options={{
                        label: translate('ra.navigation.resources.mass_language', 2),
                    }}
                    list={
                        permissions.includes('system-configuration')
                            ? MassLanguageList
                            : null
                    }
                    create={
                        permissions.includes('system-configuration')
                            ? MassLanguageCreate
                            : null
                    }
                    edit={
                        permissions.includes('system-configuration')
                            ? MassLanguageEdit
                            : null
                    }
                    show={
                        permissions.includes('system-configuration')
                            ? MassLanguageShow
                            : null
                    }
                />,

                // Mass types
                <Resource
                    name='mass-types'
                    key='mass-types'
                    options={{
                        label: translate('ra.navigation.resources.mass_type', 2),
                    }}
                    list={
                        permissions.includes('system-configuration') ? MassTypeList : null
                    }
                    create={
                        permissions.includes('system-configuration') ? MassTypeCreate : null
                    }
                    edit={
                        permissions.includes('system-configuration') ? MassTypeEdit : null
                    }
                    show={
                        permissions.includes('system-configuration') ? MassTypeShow : null
                    }
                />,

                // Mass seasons
                <Resource
                    name='mass-seasons'
                    key='mass-seasons'
                    options={{
                        label: translate('ra.navigation.resources.mass_season', 2),
                    }}
                    list={
                        permissions.includes('system-configuration') ? MassSeasonList : null
                    }
                    create={
                        permissions.includes('system-configuration') ? MassSeasonCreate : null
                    }
                    edit={
                        permissions.includes('system-configuration') ? MassSeasonEdit : null
                    }
                    show={
                        permissions.includes('system-configuration') ? MassSeasonShow : null
                    }
                />,

                // Regions
                permissions.includes('directory') ? (
                    <Resource
                        name='regions'
                        key='regions'
                        options={{ label: translate('ra.navigation.resources.region', 2) }}
                        list={RegionList}
                        create={RegionCreate}
                        edit={RegionEdit}
                        show={RegionShow}
                    />
                ) : null,

                // Goals
                permissions.includes('directory') ? (
                    <Resource
                        name='goals'
                        key='goals'
                        options={{ label: translate('ra.navigation.resources.goal', 2) }}
                        list={GoalList}
                        create={GoalCreate}
                        edit={GoalEdit}
                        show={GoalShow}
                    />
                ) : null,

                // Demographic focus
                permissions.includes('directory') ? (
                    <Resource
                        name='demographic-foci'
                        key='demographic-foci'
                        options={{
                            label: translate('ra.navigation.resources.demographic_focus', 2),
                        }}
                        list={DemographicFocusList}
                        create={DemographicFocusCreate}
                        edit={DemographicFocusEdit}
                        show={DemographicFocusShow}
                    />
                ) : null,

                // Tags
                permissions.includes('directory') ? (
                    <Resource
                        name='tags'
                        key='tags'
                        options={{ label: translate('ra.navigation.resources.tag', 2) }}
                        list={TagList}
                        create={TagCreate}
                        edit={TagEdit}
                        show={TagShow}
                    />
                ) : null,

                // Service categories
                permissions.includes('directory') ? (
                    <Resource
                        name='service-categories'
                        key='service-categories'
                        options={{
                            label: translate('ra.navigation.resources.service_category', 2),
                        }}
                        list={ServiceCategoryList}
                        create={ServiceCategoryCreate}
                        edit={ServiceCategoryEdit}
                        show={ServiceCategoryShow}
                    />
                ) : null,

                // Entities
                permissions.includes('directory') ||
                    permissions.includes('directory-entities') ? (
                    <Resource
                        name='entities'
                        key='entities'
                        options={{ label: translate('ra.navigation.resources.entity', 2) }}
                        list={EntityList}
                        create={EntityCreate}
                        edit={EntityEdit}
                        show={EntityShow}
                    />
                ) : null,

                // Products
                permissions.includes('directory') ||
                    permissions.includes('directory-products') ? (
                    <Resource
                        name='products'
                        key='products'
                        options={{ label: translate('ra.navigation.resources.product', 2) }}
                        list={ProductList}
                        create={ProductCreate}
                        edit={ProductEdit}
                        show={ProductShow}
                    />
                ) : null,

                // Personnel
                permissions.includes('directory') ||
                    permissions.includes('directory-products') ? (
                    <Resource
                        name='personnel'
                        key='personnel'
                        options={{
                            label: translate('ra.navigation.resources.personnel', 2),
                        }}
                        list={PersonnelList}
                        create={PersonnelCreate}
                        edit={PersonnelEdit}
                        show={PersonnelShow}
                    />
                ) : null,

                // Roles
                <Resource
                    name='roles'
                    key='roles'
                    options={{ label: translate('ra.navigation.resources.role', 2) }}
                    list={permissions.includes('system-configuration') ? RoleList : null}
                    create={
                        permissions.includes('system-configuration') ? RoleCreate : null
                    }
                    edit={permissions.includes('system-configuration') ? RoleEdit : null}
                    show={permissions.includes('system-configuration') ? RoleShow : null}
                />,

                // Users
                <Resource
                    name='users'
                    key='users'
                    options={{ label: translate('ra.navigation.resources.user', 2) }}
                    list={permissions.includes('user') ? UserList : null}
                    create={permissions.includes('user') ? UserCreate : null}
                    edit={permissions.includes('user') ? UserEdit : null}
                    show={permissions.includes('user') ? UserShow : null}
                />,
            ]}
        </Admin>
    );
}

export default App;
