import React, { Fragment } from 'react';
import UrlHelper from '../helpers/UrlHelper';
import { BulkDeleteButton } from 'react-admin';
import CustomPermanentlyDeleteButton from './CustomPermanentlyDeleteButton';
import CustomRestoreButton from './CustomRestoreButton';

const ListBulkActions = (props) => {
    const hashObj = UrlHelper.HashToObject(window.location.hash);
    return (
        <Fragment>
            {(!hashObj?.filter || !hashObj.filter.includes('trash')) && (
                <BulkDeleteButton {...props} undoable={false} />
            )}
            {hashObj?.filter && hashObj.filter.includes('trash') && (
                <Fragment>
                    <CustomPermanentlyDeleteButton
                        basePath={props.basePath}
                        record={props.selectedIds}
                        isPlural={true}
                    />
                    <CustomRestoreButton
                        basePath={props.basePath}
                        record={props.selectedIds}
                        isPlural={true}
                    />
                </Fragment>
            )}
        </Fragment>
    );
};

export default ListBulkActions;
