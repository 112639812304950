import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import { MassTimeTime } from './MassTimeTime';

export const MassTimeDay = (props) => {
    const useStyles = makeStyles(() => ({
        massTimeList: {
            margin: 0,
            padding: 0,
            width: '100%',
            '&> ul': {
                padding: 0,
                '&> li': {
                    listStyle: 'none',
                    margin: '0 .7rem .7rem 0',
                    width: '100%',
                },
            },
        },
    }));
    const classes = useStyles();

    const dayNames = [
        'ra.input.days_plural.monday',
        'ra.input.days_plural.tuesday',
        'ra.input.days_plural.wednesday',
        'ra.input.days_plural.thursday',
        'ra.input.days_plural.friday',
        'ra.input.days_plural.saturday',
        'ra.input.days_plural.sunday',
    ];
    const translate = useTranslate();
    const specialDateName =
        props.massTimes[0]['date_str_derived'] !==
            props.massTimes[0]['special_date_name_derived']
            ? ` (${props.massTimes[0]['special_date_name_derived']})`
            : '';

    return (
        <div key={`mass-time-day-${props.key}`} className={classes.massTimeList}>
            <h4>
                {+props.day
                    ? translate(dayNames[+props.day - 1])
                    : `${props.massTimes[0]['date_str_derived']} ${translate(
                        'ra.action.only'
                    ).toLowerCase()}${specialDateName}`}
            </h4>
            <ul>
                {props.massTimes.map((massTime, key) => (
                    <li key={`mass-time-${key}`}>
                        <MassTimeTime time={massTime} />
                    </li>
                ))}
            </ul>
        </div>
    );
};
