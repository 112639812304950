import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import FormTheme from '../../themes/DefaultThemeForm';
import {
    Show,
    SimpleShowLayout,
    TextField,
    EmailField,
    ReferenceField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    BooleanField,
    DateField,
    Datagrid,
} from 'react-admin';
import ShowActions from '../ShowActions';
import ListTransformPathField from '../ListTransformPathField';
import ListActiveField from '../ListActiveField';

const useStyles = makeStyles(() => FormTheme.styles, {
    name: 'UserForm',
});

export const UserShow = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const ShowTitle = ({ record }) => {
        return (
            <h3 className={classes.formTitle} fullWidth>
                {translate('ra.action.showing')}{' '}
                {translate('ra.navigation.resources.user', 1)}: {record.name}
            </h3>
        );
    };
    return (
        <Show
            {...props}
            actions={<ShowActions />}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.resources.user',
                2
            )}`}
        >
            <SimpleShowLayout>
                <ShowTitle />
                <TextField source='id' label={translate('ra.input.id', 1)} />
                <TextField
                    source='username'
                    label={translate('ra.input.username', 1)}
                />
                <ReferenceField
                    label={translate('ra.input.primary_role', 1)}
                    source='primary_role_id'
                    reference='roles'
                >
                    <TextField source='name' />
                </ReferenceField>
                <ReferenceArrayField
                    label={translate('ra.navigation.resources.role', 2)}
                    source='roles_ids'
                    reference='roles'
                    sortable={false}
                >
                    <SingleFieldList>
                        <ChipField source='name' />
                    </SingleFieldList>
                </ReferenceArrayField>
                <ReferenceArrayField
                    label={translate('ra.input.category_manage')}
                    source='categories_ids'
                    reference='categories'
                    sortable={false}
                >
                    <SingleFieldList>
                        <ChipField source='name' />
                    </SingleFieldList>
                </ReferenceArrayField>
                <TextField
                    source='full_name_reversed_derived'
                    label={translate('ra.input.name', 1)}
                />
                <TextField
                    source='first_name'
                    label={translate('ra.input.first_name', 1)}
                />
                <TextField
                    source='last_name'
                    label={translate('ra.input.last_name', 1)}
                />
                <EmailField source='email' label={translate('ra.input.email', 1)} />
                <TextField
                    source='api_token'
                    label={translate('ra.auth.api_token', 1)}
                />
                <TextField source='locale' label={translate('ra.input.locale', 1)} />
                <BooleanField
                    source='is_active'
                    label={translate('ra.input.enabled')}
                />
                <TextField
                    source='internal_notes'
                    label={translate('ra.input.internal_note', 2)}
                />
                <DateField
                    source='updated_at'
                    label={translate('ra.input.last_updated_date')}
                />
                <DateField
                    source='created_at'
                    label={translate('ra.input.publication_date')}
                />
                <ReferenceArrayField
                    label={`${translate('ra.input.allowed', 1)} ${translate(
                        'ra.navigation.resources.product',
                        2
                    ).toLowerCase()}`}
                    source='edit_scope_ids'
                    reference='products'
                    sortable={false}
                >
                    <Datagrid>
                        <TextField
                            source='name_with_locality_derived'
                            label={translate('ra.input.name', 1)}
                            sortable={false}
                        />
                        <TextField
                            source='slug'
                            label={translate('ra.input.slug', 1)}
                            sortable={false}
                        />
                        <TextField
                            source='meta_description'
                            label={translate('ra.input.meta_description', 1)}
                            sortable={false}
                        />
                        <ListActiveField
                            source='is_active'
                            label={translate('ra.input.active')}
                            sortable={false}
                        />
                        <ListTransformPathField
                            label={translate('ra.input.path', 1)}
                            sortable={false}
                        />
                    </Datagrid>
                </ReferenceArrayField>
            </SimpleShowLayout>
        </Show>
    );
};
