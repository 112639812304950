import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useSafeSetState } from 'ra-core';
import FormTheme from '../../themes/DefaultThemeForm';
import {
    useNotify,
    useRefresh,
    TextField,
    Edit,
    SimpleForm,
} from 'react-admin';
import CustomInputs from '../CustomInputs';
import CustomToolbar from '../CustomToolbar';
import EditActions from '../EditActions';
import ValidationHelper from '../../helpers/ValidationHelper';

const useStyles = makeStyles(() => FormTheme.styles, {
    name: 'UserForm',
});

export const UserEdit = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const onSuccess = () => {
        notify(translate('ra.notification.updated', 1));
        refresh();
    };

    const transform = (data) => ({
        ...data,
        is_active: data.is_active ? 1 : 0,
    });

    const apiPrimaryRoleId = process.env.REACT_APP_PRIMARY_ROLES.split(
        '|'
    ).reduce((p, d) => {
        const parts = d.split(':');
        return parts[0] === 'API' ? parts[1] : null;
    });

    const validate = (values) => {
        const errors = ValidationHelper.ValidateRequired(
            values,
            ['primary_role_id', 'first_name'],
            translate('ra.validation.required')
        );

        if (values.primary_role_id !== apiPrimaryRoleId && !values.email) {
            errors.email = translate('ra.validation.required');
        }

        if (values.primary_role_id === apiPrimaryRoleId && !values.api_token) {
            errors.api_token = translate('ra.validation.required');
        }

        return errors;
    };

    // Set initial state
    const [primaryRoleId, setPrimaryRoleId] = useSafeSetState(null);
    const WithProps = ({ record }) => {
        if (!primaryRoleId) {
            setPrimaryRoleId(record.primary_role_id);
        }
        return '';
    };

    return (
        <Edit
            {...props}
            actions={<EditActions />}
            title={`${process.env.REACT_APP_TITLE} ${translate(
                'ra.navigation.resources.user',
                2
            )}`}
            transform={transform}
            onSuccess={onSuccess}
            onFailure={(e) => {
                notify(ValidationHelper.FormatApiErrors(e));
            }}
            undoable={false}
        >
            <SimpleForm
                validate={validate}
                warnWhenUnsavedChanges
                toolbar={<CustomToolbar />}
                redirect='list'
                className={classes.form}
            >
                <WithProps />
                <TextField source='id' label={translate('ra.input.id', 1)} />
                <TextField
                    source='username'
                    label={translate('ra.input.username', 1)}
                />
                <CustomInputs.PrimaryRoleReferenceInput
                    onChange={(e) => {
                        setPrimaryRoleId(e);
                    }}
                />
                {(!primaryRoleId || primaryRoleId !== apiPrimaryRoleId) && (
                    <CustomInputs.RoleArrayInput />
                )}
                {(!primaryRoleId || primaryRoleId === apiPrimaryRoleId) && (
                    <CustomInputs.ApiTokenTextInput />
                )}
                <CustomInputs.CategoriesInput
                    allowEmpty
                    label={translate('ra.input.category_manage')}
                    helperText={translate('ra.input.helper.user_categories')}
                />
                <CustomInputs.NameTextInput
                    label={translate('ra.input.first_name', 1)}
                    source='first_name'
                />
                <CustomInputs.NameTextInput
                    label={translate('ra.input.last_name', 1)}
                    source='last_name'
                />
                {(!primaryRoleId || primaryRoleId !== apiPrimaryRoleId) && (
                    <CustomInputs.EmailTextInput />
                )}
                <CustomInputs.LocaleInput />
                <CustomInputs.IsActiveBooleanInput
                    label={translate('ra.input.enabled', 1)}
                    defaultValue='1'
                />
                <CustomInputs.InternalNotesTextInput />
            </SimpleForm>
        </Edit>
    );
};
