import React, { useState, useContext } from 'react';
import DataProvider from '../../providers/DataProvider';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useNotify } from 'ra-core';
import { MediaContext } from './MediaProvider';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import { Form } from 'react-final-form';
import DescriptionIcon from '@material-ui/icons/Description';
import { TextInput, EditButton } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormTheme from '../../themes/DefaultThemeForm';
import ValidationHelper from '../../helpers/ValidationHelper';

const useStyles = makeStyles((theme) => ({
    document: {
        display: 'block',
        marginBottom: '.5rem',
    },
    image: {
        display: 'block',
        height: 198,
        objectFit: 'cover',
        width: 198,
    },
    show: {
        margin: '1rem 0',
    },
    title: {
        color: theme.palette.text.primary,
    },
    ...FormTheme.styles.modal,
}));

const MediaEditModal = ({ thisResource, record }) => {
    record.media_name = record.name; // Parent resource optimistic rendering
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [saving, setSaving] = useState(false);
    const [, dispatch] = useContext(MediaContext);

    const validate = (values) =>
        ValidationHelper.ValidateRequired(
            values,
            'media_name',
            translate('ra.validation.required')
        );

    const submit = (values) => {
        setSaving(true);
        const mediaData = {
            collection: thisResource.collection,
            name: values.media_name,
            alt: values.alt ?? null,
            caption: values.caption ?? null,
        };
        return DataProvider.updateSubResource(
            thisResource.basePath,
            'media',
            thisResource.id,
            record.id,
            {
                data: mediaData,
            }
        )
            .then(() => {
                dispatch({
                    type: 'toggle_dirty_accordion',
                    collection: mediaData.collection,
                });
                setSaving(false);
                setOpen(false);
            })
            .catch((error) => {
                dispatch({
                    type: 'toggle_dirty_accordion',
                    collection: mediaData.collection,
                });
                setSaving(false);
                notify(
                    `${values.name}: ${error.message} ${translate(
                        'ra.message.try_again'
                    )}`
                );
            });
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <EditButton onClick={handleOpen} scrollToTop={false} />
            <Modal
                aria-labelledby='modal-title'
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                scrollToTop={false}
            >
                <Fade in={open}>
                    <div>
                        <Form
                            initialValues={record}
                            onSubmit={submit}
                            validate={validate}
                            render={({ handleSubmit }) => (
                                <form onSubmit={handleSubmit} noValidate>
                                    <div className={classes.paper}>
                                        <Grid
                                            container
                                            direction='row'
                                            justify='space-between'
                                            alignItems='center'
                                        >
                                            <h3 id='modal-title' className={classes.title}>
                                                {translate('ra.action.edit')}{' '}
                                                {translate('ra.input.media_item', 1).toLowerCase()}
                                            </h3>
                                            <IconButton onClick={handleClose}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Grid>
                                        <div className={classes.show}>
                                            {record.mime_type.includes('image') && (
                                                <img
                                                    className={classes.image}
                                                    src={
                                                        record['derived']?.['crop-1-1']?.['198w']?.[
                                                        'regular'
                                                        ] ?? record.url
                                                    }
                                                    title={record.media_name}
                                                    alt={record.media_name}
                                                />
                                            )}
                                            {!record.mime_type.includes('image') && (
                                                <DescriptionIcon className={classes.document} />
                                            )}
                                            <Typography component='span'>
                                                <strong>{translate('ra.input.url', 1) + ': '}</strong>
                                            </Typography>
                                            <Typography component='span'>
                                                <a
                                                    href={record.url}
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                >
                                                    {record.url}
                                                </a>
                                            </Typography>
                                        </div>
                                        <TextInput
                                            className={classes.field}
                                            variant='outlined'
                                            label={translate('ra.input.name', 1)}
                                            source='media_name'
                                        />
                                        <TextInput
                                            className={classes.field}
                                            variant='outlined'
                                            label={translate('ra.input.alt', 1)}
                                            source='alt'
                                        />
                                        <TextInput
                                            className={classes.field}
                                            variant='outlined'
                                            label={translate('ra.input.caption', 1)}
                                            source='caption'
                                        />
                                        <CardActions>
                                            <Button
                                                className={classes.button}
                                                variant='contained'
                                                type='submit'
                                                color='primary'
                                            >
                                                {translate('ra.action.save')}{' '}
                                                {translate('ra.input.media_item', 1)}{' '}
                                            </Button>
                                            {saving && <CircularProgress size={18} thickness={2} />}
                                        </CardActions>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </Fade>
            </Modal>
        </>
    );
};

export default MediaEditModal;
