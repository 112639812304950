import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import FormTheme from '../../themes/DefaultThemeForm';
import {
    useNotify,
    useRefresh,
    useRedirect,
    Create,
    SimpleForm,
    TextInput,
} from 'react-admin';
import CustomInputs from '../CustomInputs';
import CreateActions from '../CreateActions';
import ValidationHelper from '../../helpers/ValidationHelper';

const useStyles = makeStyles(() => FormTheme.styles, {
    name: 'RoleForm',
});

export const RoleCreate = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = ({ data }) => {
        notify(translate('ra.notification.created'));
        redirect(data.id);
        refresh();
    };

    const validate = (values) =>
        ValidationHelper.ValidateRequired(
            values,
            'name',
            translate('ra.validation.required')
        );

    return (
        <Create
            {...props}
            actions={<CreateActions />}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.configuration'
            )}: ${translate('ra.navigation.resources.role', 2)}`}
            onSuccess={onSuccess}
            onFailure={(e) => {
                notify(ValidationHelper.FormatApiErrors(e));
            }}
            undoable={false}
        >
            <SimpleForm
                validate={validate}
                warnWhenUnsavedChanges
                redirect='list'
                className={classes.form}
            >
                <h3 className={classes.formTitle} fullWidth>
                    {translate('ra.action.creating')}
                    {' - '}
                    {translate('ra.navigation.resources.role', 1)}
                </h3>
                <CustomInputs.NameTextInput />
                <TextInput
                    variant='outlined'
                    label={translate('ra.input.description', 1)}
                    source='description'
                    fullWidth
                />
                <CustomInputs.OrderingNumberInput
                    helperText={translate('ra.input.helper.optional_ordering')}
                />
                <CustomInputs.InternalNotesTextInput />
            </SimpleForm>
        </Create>
    );
};
