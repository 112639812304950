const ArrayHelper = {
    Chunk: (array, chunkSize) => {
        if (chunkSize <= 0) {
            return array;
        }
        const chunks = [];
        for (let i = 0, length = array.length; i < length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    },
};

export default ArrayHelper;
