import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import FormTheme from '../../themes/DefaultThemeForm';
import {
    useNotify,
    useRefresh,
    TextField,
    Edit,
    TabbedForm,
    FormTab,
} from 'react-admin';
import CustomInputs from '../CustomInputs';
import CustomToolbar from '../CustomToolbar';
import EditActions from '../EditActions';
import ValidationHelper from '../../helpers/ValidationHelper';

const useStyles = makeStyles(() => FormTheme.styles, {
    name: 'MassSeasonForm',
});

let date = null;

export const MassSeasonEdit = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const onSuccess = () => {
        notify(translate('ra.notification.updated', 1));
        refresh();
    };

    // Set initial states
    const WithProps = ({ record }) => {
        if (!isDateSet && record.date) {
            handleDateChange(new Date(`${new Date().getFullYear()}-${record?.date}`));
            setIsDateSet(true);
        }
        return '';
    };
    const [isDateSet, setIsDateSet] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const handleDateChange = (d) => {
        setSelectedDate(d);
        date = d?.toISOString().slice(5, 10);
    };

    const validate = (values) => {
        const errors = {
            ...ValidationHelper.ValidateRequired(
                values,
                ['name', 'slug', 'meta_description'],
                translate('ra.validation.required')
            ),
            ...ValidationHelper.ValidateLength(
                values,
                'meta_description',
                160,
                translate('ra.validation.maxLength', {
                    max: 160,
                })
            ),
        };

        if (
            date &&
            values.easter_date_plus_days != null &&
            Number.isInteger(values.easter_date_plus_days)
        ) {
            errors.easter_date_plus_days = translate(
                'ra.validation.use_one_of_date_or_easter_days'
            );
        }
        if (!date && values.easter_date_plus_days == null) {
            errors.date = translate('ra.validation.one_required');
            errors.easter_date_plus_days = translate('ra.validation.one_required');
        }

        return errors;
    };

    const transform = (data) => {
        return {
            ...data,
            date,
            is_publish_times: data.is_publish_times ? 1 : 0,
            is_active: data.is_active ? 1 : 0,
        };
    };

    return (
        <Edit
            {...props}
            actions={<EditActions />}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.configuration'
            )}: ${translate('ra.navigation.resources.mass_season', 2)}`}
            transform={transform}
            onSuccess={onSuccess}
            onFailure={(e) => {
                notify(ValidationHelper.FormatApiErrors(e));
            }}
            undoable={false}
        >
            <TabbedForm
                validate={validate}
                warnWhenUnsavedChanges
                toolbar={<CustomToolbar />}
                redirect='list'
                className={classes.form}
            >
                <FormTab label='Overview'>
                    <p fullWidth>
                        <strong>{translate('ra.action.remember')}</strong>{' '}
                        {translate('ra.message.mass_season_remember')}
                    </p>
                    <p fullWidth>
                        <strong>{translate('ra.action.christmas')}</strong>{' '}
                        {translate('ra.message.mass_season_christmas')}
                    </p>
                    <p fullWidth>
                        <strong>{translate('ra.action.easter')}</strong>{' '}
                        {translate('ra.message.mass_season_easter')}
                    </p>
                    <WithProps />
                    <TextField source='id' label={translate('ra.input.id', 1)} />
                    <CustomInputs.NameTextInput />
                    <CustomInputs.SlugTextInput />
                    <CustomInputs.MetaDescriptionTextInput />
                    <CustomInputs.DescriptionRichTextInput />
                    <div className={classes.groupFields} fullWidth>
                        <div className={classes.inlineNote} fullWidth>
                            <span className={classes.strong}>
                                {translate('ra.action.note')}
                            </span>{' '}
                            {translate('ra.input.helper.enter_only_one_date_or_easter_days')}
                        </div>
                        <CustomInputs.DatePicker
                            label={translate('ra.input.annual_date', 1)}
                            selectedDate={selectedDate}
                            handleDateChange={handleDateChange}
                        />
                        <CustomInputs.EasterDatePlusDaysNumberInput />
                    </div>
                    <CustomInputs.IsPublishTimesBooleanInput fullWidth />
                    <CustomInputs.IsActiveBooleanInput />
                    <CustomInputs.InternalNotesTextInput />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};
