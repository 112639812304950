import React, { useState, useContext } from 'react';
import DataProvider from '../../providers/DataProvider';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { useTranslate, useNotify } from 'ra-core';
import { MassTimeContext } from './MassTimeProvider';
import { SelectInput } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomInputs from '../CustomInputs';
import MassTimeWrapper from '../../layout/MassTimeWrapper';
import MassTimeHelper from '../../helpers/MassTimeHelper';
import ValidationHelper from '../../helpers/ValidationHelper';
import dateFormat from 'dateformat';

const useStyles = makeStyles(() => ({
    button: {
        marginLeft: -8,
    },
    field: {
        width: '100%',
    },
    paddedBottom: {
        paddingBottom: '1rem',
    },
}));

const MassTimeCreate = (props) => {
    const thisResource = {
        basePath: props.data.basePath.replace(/^\/+/, ''),
        id: props.data.record.id,
    };

    const [saving, setSaving] = useState(false);
    const [categoryId, setCategoryId] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [, dispatch] = useContext(MassTimeContext);
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles();

    const validate = (values) => {
        const errors = {
            ...ValidationHelper.ValidateRequired(
                values,
                ['category', 'mass_language_id'],
                translate('ra.validation.required')
            ),
        };
        if (
            values.repetition &&
            values.repetition.length > 1 &&
            values.repetition.includes(0)
        ) {
            errors.repetition = translate('ra.validation.mass_time_repetition');
        }

        if (!selectedDate) {
            errors.date = translate('ra.validation.required');
        }

        if (!selectedTime) {
            errors.time = translate('ra.validation.required');
        }

        return errors;
    };

    const submit = (values, form) => {
        setSaving(true);
        return DataProvider.create('mass-times', {
            data: {
                product_id: thisResource.id,
                mass_type_id: values.mass_type_id ?? null,
                mass_season_id:
                    values.category === 'SPECIAL' && values.mass_season_id
                        ? values.mass_season_id
                        : null,
                mass_language_id: values.mass_language_id,
                category: values.category,
                date: dateFormat(selectedDate.toISOString(), 'yyyy-mm-dd'),
                time: selectedTime.toLocaleTimeString('en-GB', {
                    hour12: false,
                    timeStyle: 'short',
                }),
                repetition: values.repetition
                    ? `[${values.repetition.toString()}]`
                    : null,
                end_date: selectedEndDate
                    ? dateFormat(selectedEndDate.toISOString(), 'yyyy-mm-dd')
                    : null,
                notes: values.mass_time_notes ?? null,
                cancellations: null,
                is_web_cast: values.is_web_cast ? 1 : 0,
                is_active: values.is_active ? 1 : 0,
                internal_notes: values.mass_time_internal_notes ?? null,
            },
        })
            .then(() => {
                notify(
                    `${translate('ra.navigation.resources.mass_time', 1)} ${translate(
                        'ra.action.created'
                    ).toLowerCase()}`
                );
                dispatch({
                    type: 'toggle_dirty_accordion',
                    season:
                        values.category.toLowerCase() === 'special'
                            ? values.mass_season_id
                            : values.category,
                });
                Object.keys(values).forEach((key) => {
                    if (
                        key !== 'category' &&
                        key !== 'mass_language_id' &&
                        key !== 'mass_season_id'
                    ) {
                        form.change(key, null);
                    }
                    form.change('is_active', 1);
                });
                setSelectedDate(null);
                setSelectedEndDate(null);
                setSelectedTime(null);
                setSaving(false);
            })
            .catch((error) => {
                notify(`${error.message} ${translate('ra.message.try_again')}`);
                setSaving(false);
            });
    };

    return (
        <MassTimeWrapper
            header={`${translate('ra.action.add')} ${translate(
                'ra.navigation.resources.mass_time',
                1
            )}`}
        >
            <Form
                onSubmit={submit}
                validate={validate}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <SelectInput
                            className={classes.field}
                            variant='outlined'
                            label={translate('ra.navigation.resources.category', 1)}
                            source='category'
                            fullWidth
                            choices={MassTimeHelper.Categories()}
                            onChange={(e) => setCategoryId(e.target.value)}
                        />
                        {categoryId && (
                            <div>
                                <CustomInputs.MassTimeLanguageReferenceInput />
                                {categoryId === 'SPECIAL' && (
                                    <CustomInputs.MassTimeSeasonReferenceInput />
                                )}
                                <CustomInputs.MassTimeTypeReferenceInput />
                                <div className={classes.paddedBottom}>
                                    <CustomInputs.DatePicker
                                        label={
                                            categoryId === 'SPECIAL'
                                                ? translate('ra.input.date', 1)
                                                : translate('ra.input.start_date', 1)
                                        }
                                        source='date'
                                        selectedDate={selectedDate}
                                        handleDateChange={setSelectedDate}
                                        format='dd-MM-yyyy'
                                        maxDate={selectedEndDate}
                                        isRequired={true}
                                    />
                                </div>
                                {categoryId !== 'SPECIAL' && (
                                    <CustomInputs.RepetitionArrayInput />
                                )}
                                <div className={classes.paddedBottom}>
                                    <CustomInputs.TimePicker
                                        source='time'
                                        selectedTime={selectedTime}
                                        handleTimeChange={setSelectedTime}
                                        isRequired={true}
                                    />
                                </div>
                                {categoryId !== 'SPECIAL' && (
                                    <div className={classes.paddedBottom}>
                                        <CustomInputs.DatePicker
                                            label={translate('ra.input.end_date', 1)}
                                            source='end_date'
                                            selectedDate={selectedEndDate}
                                            handleDateChange={setSelectedEndDate}
                                            format='dd-MM-yyyy'
                                            minDate={selectedDate}
                                        />
                                    </div>
                                )}
                                <CustomInputs.NotesTextInput source={'mass_time_notes'} />
                                <CustomInputs.IsWebCastBooleanInput />
                                <CustomInputs.IsActiveBooleanInput defaultValue={true} />
                                <CustomInputs.InternalNotesTextInput
                                    source={'mass_time_internal_notes'}
                                />
                            </div>
                        )}
                        <CardActions>
                            <Button
                                disabled={saving}
                                className={classes.button}
                                variant='contained'
                                type='submit'
                                color='primary'
                            >
                                {translate('ra.action.add')}{' '}
                                {translate('ra.navigation.resources.mass_time', 1)}
                            </Button>
                            {saving && <CircularProgress size={18} thickness={2} />}
                        </CardActions>
                    </form>
                )}
            />
        </MassTimeWrapper>
    );
};

export default MassTimeCreate;
