import * as React from 'react';
import AuthProvider from '../providers/AuthProvider';
import { makeStyles } from '@material-ui/core/styles';
import { usePermissions } from 'react-admin';
import { useTranslate, useSafeSetState } from 'ra-core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DirectoryIcon from '@material-ui/icons/ImportContacts';
import ProductIcon from '@material-ui/icons/EmojiPeople';
import EntityIcon from '@material-ui/icons/Place';
import PersonnelIcon from '@material-ui/icons/GroupWork';
import GoalIcon from '@material-ui/icons/CheckBox';
import TagIcon from '@material-ui/icons/Label';
import RegionIcon from '@material-ui/icons/Map';
import DemographicFociIcon from '@material-ui/icons/Group';
import SettingsIcon from '@material-ui/icons/Settings';
import CategoryIcon from '@material-ui/icons/Apps';
import CustomLocationIcon from '@material-ui/icons/LocationSearching';
import MassLanguageIcon from '@material-ui/icons/Language';
import MassTypeIcon from '@material-ui/icons/Ballot';
import MassSeasonIcon from '@material-ui/icons/HomeWork';
import MetaCategoryIcon from '@material-ui/icons/PermDataSetting';
import UserRoleIcon from '@material-ui/icons/Category';
import UserIcon from '@material-ui/icons/VerifiedUser';
import PasswordIcon from '@material-ui/icons/VpnKey';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';

const useStyles = makeStyles((theme) => ({
    icon: {
        minWidth: 45,
    },
    item: {
        width: 240,
    },
    list: {
        width: 240,
    },
    nested: {
        paddingLeft: theme.spacing(3),
    },
}));

const CustomMenu = ({ onMenuClick, ...props }) => {
    const { permissions } = usePermissions();
    const [directoryOpen, setDirectoryOpen] = useSafeSetState(false);
    const [configurationOpen, setConfigurationOpen] = useSafeSetState(false);
    const translate = useTranslate();
    const classes = useStyles(props);

    const handleLogout = () => {
        AuthProvider.logout().then(() => {
            window.location.reload();
        });
    };

    return (
        <div>
            <div className={classes.root}>
                <List component='nav' className={classes.list}>
                    <ListItem
                        className={classes.item}
                        button
                        component='a'
                        href='/'
                        onClick={onMenuClick}
                    >
                        <ListItemIcon className={classes.icon}>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary={translate('ra.navigation.menu.dashboard')} />
                    </ListItem>

                    {permissions &&
                        (permissions.includes('directory') ||
                            permissions.includes('directory-products') ||
                            permissions.includes('directory-entities')) && (
                            <ListItem
                                button
                                onClick={() => {
                                    setDirectoryOpen(!directoryOpen);
                                }}
                            >
                                <ListItemIcon className={classes.icon}>
                                    <DirectoryIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={translate('ra.navigation.menu.directory')}
                                />
                                {directoryOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                        )}

                    <Collapse in={directoryOpen} timeout='auto' unmountOnExit>
                        <List component='div' disablePadding>
                            {permissions &&
                                (permissions.includes('directory') ||
                                    permissions.includes('directory-products')) && (
                                    <ListItem
                                        button
                                        component='a'
                                        href='/#/products'
                                        className={classes.nested}
                                    >
                                        <ListItemIcon className={classes.icon}>
                                            <ProductIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={translate('ra.navigation.resources.product', 2)}
                                        />
                                    </ListItem>
                                )}
                            {permissions &&
                                (permissions.includes('directory') ||
                                    permissions.includes('directory-entities')) && (
                                    <ListItem
                                        button
                                        component='a'
                                        href='/#/entities'
                                        className={classes.nested}
                                    >
                                        <ListItemIcon className={classes.icon}>
                                            <EntityIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={translate('ra.navigation.resources.entity', 2)}
                                        />
                                    </ListItem>
                                )}
                            {permissions &&
                                (permissions.includes('directory') ||
                                    permissions.includes('directory-products')) && (
                                    <ListItem
                                        button
                                        component='a'
                                        href='/#/service-categories'
                                        className={classes.nested}
                                    >
                                        <ListItemIcon className={classes.icon}>
                                            <CategoryIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={translate(
                                                'ra.navigation.resources.service_category',
                                                2
                                            )}
                                        />
                                    </ListItem>
                                )}
                            {permissions &&
                                (permissions.includes('directory') ||
                                    permissions.includes('directory-products')) && (
                                    <ListItem
                                        button
                                        component='a'
                                        href='/#/personnel'
                                        className={classes.nested}
                                    >
                                        <ListItemIcon className={classes.icon}>
                                            <PersonnelIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={translate(
                                                'ra.navigation.resources.personnel',
                                                2
                                            )}
                                        />
                                    </ListItem>
                                )}
                            {permissions &&
                                (permissions.includes('directory') ||
                                    permissions.includes('directory-products')) && (
                                    <ListItem
                                        button
                                        component='a'
                                        href='/#/goals'
                                        className={classes.nested}
                                    >
                                        <ListItemIcon className={classes.icon}>
                                            <GoalIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={translate('ra.navigation.resources.goal', 2)}
                                        />
                                    </ListItem>
                                )}
                            {permissions &&
                                (permissions.includes('directory') ||
                                    permissions.includes('directory-products')) && (
                                    <ListItem
                                        button
                                        component='a'
                                        href='/#/demographic-foci'
                                        className={classes.nested}
                                    >
                                        <ListItemIcon className={classes.icon}>
                                            <DemographicFociIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={translate(
                                                'ra.navigation.resources.demographic_focus',
                                                2
                                            )}
                                        />
                                    </ListItem>
                                )}
                            {permissions &&
                                (permissions.includes('directory') ||
                                    permissions.includes('directory-products')) && (
                                    <ListItem
                                        button
                                        component='a'
                                        href='/#/tags'
                                        className={classes.nested}
                                    >
                                        <ListItemIcon className={classes.icon}>
                                            <TagIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={translate('ra.navigation.resources.tag', 2)}
                                        />
                                    </ListItem>
                                )}
                            {permissions &&
                                (permissions.includes('directory') ||
                                    permissions.includes('directory-products')) && (
                                    <ListItem
                                        button
                                        component='a'
                                        href='/#/regions'
                                        className={classes.nested}
                                    >
                                        <ListItemIcon className={classes.icon}>
                                            <RegionIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={translate('ra.navigation.resources.region', 2)}
                                        />
                                    </ListItem>
                                )}
                        </List>
                    </Collapse>

                    {permissions && permissions.includes('system-configuration') && (
                        <ListItem
                            button
                            onClick={() => {
                                setConfigurationOpen(!configurationOpen);
                            }}
                        >
                            <ListItemIcon className={classes.icon}>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={translate('ra.navigation.menu.configuration')}
                            />
                            {configurationOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    )}

                    <Collapse in={configurationOpen} timeout='auto' unmountOnExit>
                        <List component='div' disablePadding>
                            <ListItem
                                button
                                component='a'
                                href='/#/categories'
                                className={classes.nested}
                            >
                                <ListItemIcon className={classes.icon}>
                                    <CategoryIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={translate('ra.navigation.resources.category', 2)}
                                />
                            </ListItem>
                            <ListItem
                                button
                                component='a'
                                href='/#/custom-locations'
                                className={classes.nested}
                            >
                                <ListItemIcon className={classes.icon}>
                                    <CustomLocationIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={translate(
                                        'ra.navigation.resources.custom_location',
                                        2
                                    )}
                                />
                            </ListItem>
                            <ListItem
                                button
                                component='a'
                                href='/#/mass-languages'
                                className={classes.nested}
                            >
                                <ListItemIcon className={classes.icon}>
                                    <MassLanguageIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={translate(
                                        'ra.navigation.resources.mass_language',
                                        2
                                    )}
                                />
                            </ListItem>
                            <ListItem
                                button
                                component='a'
                                href='/#/mass-types'
                                className={classes.nested}
                            >
                                <ListItemIcon className={classes.icon}>
                                    <MassTypeIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={translate('ra.navigation.resources.mass_type', 2)}
                                />
                            </ListItem>
                            <ListItem
                                button
                                component='a'
                                href='/#/mass-seasons'
                                className={classes.nested}
                            >
                                <ListItemIcon className={classes.icon}>
                                    <MassSeasonIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={translate('ra.navigation.resources.mass_season', 2)}
                                />
                            </ListItem>
                            <ListItem
                                button
                                component='a'
                                href='/#/meta-categories'
                                className={classes.nested}
                            >
                                <ListItemIcon className={classes.icon}>
                                    <MetaCategoryIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={translate(
                                        'ra.navigation.resources.meta_category',
                                        2
                                    )}
                                />
                            </ListItem>
                            <ListItem
                                button
                                component='a'
                                href='/#/roles'
                                className={classes.nested}
                            >
                                <ListItemIcon className={classes.icon}>
                                    <UserRoleIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={translate('ra.navigation.resources.role', 2)}
                                />
                            </ListItem>
                        </List>
                    </Collapse>

                    {permissions && permissions.includes('user') && (
                        <ListItem
                            button
                            component='a'
                            href='/#/users'
                            onClick={onMenuClick}
                        >
                            <ListItemIcon className={classes.icon}>
                                <UserIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={translate('ra.navigation.resources.user', 2)}
                            />
                        </ListItem>
                    )}

                    <ListItem
                        button
                        component='a'
                        href='/#/change-password'
                        onClick={onMenuClick}
                    >
                        <ListItemIcon className={classes.icon}>
                            <PasswordIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={translate('ra.navigation.menu.change_password')}
                        />
                    </ListItem>
                    <ListItem button onClick={handleLogout}>
                        <ListItemIcon className={classes.icon}>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary='Logout' />
                    </ListItem>
                </List>
            </div>
        </div>
    );
};

export default CustomMenu;
