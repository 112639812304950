import React from 'react';
import { useTranslate } from 'ra-core';
import { MassTimeDay } from './MassTimeDay';

export const MassTimeSeason = ({ currentMassTimes }) => {
    const translate = useTranslate();
    return currentMassTimes
        ? Object.keys(currentMassTimes).map((day, key) => (
            <MassTimeDay
                key={key}
                day={day}
                massTimes={currentMassTimes[day]}
            />
        ))
        : <div>{translate('ra.message.none_found')}</div>;
};
