import React, { Fragment, useState } from 'react';
import { useTranslate } from 'ra-core';
import DataProvider from '../providers/DataProvider';
import { Button, Confirm } from 'react-admin';
import Description from '@material-ui/icons/Description';
import dateFormat from 'dateformat';

const CustomReportButton = ({
    reportUrl,
    buttonName,
    record,
    mimeType,
    extension,
}) => {
    const [open, setOpen] = useState(false);
    const translate = useTranslate();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        DataProvider.getDownload(reportUrl, { id: record.id }).then((blob) => {
            const URL = window.URL.createObjectURL(blob, {
                type: mimeType,
            });
            const link = document.createElement('a');
            const time = dateFormat(new Date(), 'yyyy-mm-dd-HH-MM');
            link.href = URL;
            link.download = `${buttonName.toLowerCase()}-${record.slug}-${time}.${extension}`;
            link.click();
            link.remove();
        });
        setOpen(false);
    };

    return (
        <Fragment>
            <Button label={buttonName} onClick={handleClick}>
                <Description />
            </Button>
            <Confirm
                isOpen={open}
                title={translate('ra.message.download_report_title')}
                content={translate('ra.message.download_report_content', {
                    name: record.slug,
                })}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
};

export default CustomReportButton;
