import React, { useEffect, useContext } from 'react';
import DataProvider from '../../providers/DataProvider';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslate, useSafeSetState } from 'ra-core';
import CircularProgress from '@material-ui/core/CircularProgress';
import MassTimeWrapper from '../../layout/MassTimeWrapper';
import { MassTimeContext } from './MassTimeProvider';
import { MassTimeSeason } from './MassTimeSeason';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '1rem 0',
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    details: {
        display: 'block',
    },
}));

const MassTimeAccordion = (props) => {
    const [state, dispatch] = useContext(MassTimeContext);
    const thisResource = {
        basePath: props.data.basePath.replace(/^\/+/, ''),
        id: props.data.record.id,
    };
    const thisSeason = props.season.id ?? props.season.name.toLowerCase();
    const [isLoading, setIsLoading] = useSafeSetState(true);
    const [currentMassTimes, setCurrentMassTimes] = useSafeSetState([]);
    const translate = useTranslate();
    const classes = useStyles();

    const loadAccordionData = function (event, expanded, toggle = true) {
        if (toggle) {
            dispatch({
                type: 'toggle_accordion',
                season: thisSeason,
            });
        }
        if (expanded) {
            setIsLoading(true);
            DataProvider.getOne('products', {
                id: thisResource.id,
                with: 'mass_times_grouped_derived',
            }).then((response) => {
                const massTimeData =
                    response['data']['mass_times_grouped_derived']['data'];
                setCurrentMassTimes(
                    ['Sunday', 'Weekday'].includes(props.season.name)
                        ? massTimeData[props.season.name.toUpperCase()]
                        : massTimeData?.['SPECIAL']?.[props.season.name]
                );
                setIsLoading(false);
            });
        }
    };

    useEffect(() => {
        if (state.dirty.includes(thisSeason.toLowerCase())) {
            dispatch({
                type: 'toggle_dirty_accordion',
                season: thisSeason,
            });
            loadAccordionData(null, true, false);
        }
    }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // Load any initially open accordions
        if (state.open.includes(thisSeason)) {
            loadAccordionData(null, true, false);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <MassTimeWrapper
            header={`${translate('ra.action.edit')} ${translate(
                'ra.navigation.resources.mass_time',
                1
            )}`}
        >
            <div className={classes.root}>
                <Accordion
                    onChange={loadAccordionData}
                    expanded={state.open.includes(thisSeason)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                    >
                        <Typography className={classes.heading}>
                            {props.season.name}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                        {!isLoading ? (
                            <MassTimeSeason currentMassTimes={currentMassTimes} />
                        ) : (
                            <CircularProgress size={18} thickness={2} />
                        )}
                    </AccordionDetails>
                </Accordion>
            </div>
        </MassTimeWrapper>
    );
};

export default MassTimeAccordion;
