import * as React from 'react';
import { useFormState } from 'react-final-form';
import { SelectInput } from 'react-admin';

const MetaCategoryHelper = {
    Models: () => {
        return Object.keys(MetaCategoryHelper.Attributes());
    },
    Attributes: () => {
        const data = process.env.REACT_APP_META_CATEGORY_MODEL_ATTRIBUTES.split(
            '|'
        ).map((d) => {
            const parts = d.split(':');
            return {
                model: parts[0],
                attributes: parts[1].split(','),
            };
        });
        const object = {};
        for (const d of data) {
            object[d.model] = d.attributes;
        }
        return object;
    },
    ToChoices: (items) => items.map((item) => ({ id: item, name: item })),
    AttributeInput: (props) => {
        const { values } = useFormState();
        return (
            <SelectInput
                choices={
                    values.model
                        ? MetaCategoryHelper.ToChoices(
                            MetaCategoryHelper.Attributes()[values.model]
                        )
                        : []
                }
                {...props}
            />
        );
    },
};

export default MetaCategoryHelper;
