import React, { Fragment } from 'react';
import DataProvider from '../providers/DataProvider';
import {
    useDeleteWithConfirmController,
    useRefresh,
    useTranslate,
    useNotify,
} from 'ra-core';
import { Button, Confirm } from 'react-admin';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';

const CustomRestoreButton = ({
    resource,
    record = {},
    basePath,
    redirect,
    onClick,
    isPlural,
    ...rest
}) => {
    const { open, loading, handleDialogOpen, handleDialogClose } =
        useDeleteWithConfirmController({
            resource,
            record,
            redirect,
            basePath,
            onClick,
        });
    const refresh = useRefresh();
    const translate = useTranslate();
    const notify = useNotify();

    const handleRestore = () => {
        return !isPlural
            ? DataProvider.restoreDeleted(basePath, { id: record.id })
                .then(() => {
                    notify(translate('ra.input.trash.restore.success'));
                    refresh();
                })
                .catch((error) => {
                    notify(error.message);
                })
            : DataProvider.restoreDeletedBulk(basePath, { ids: record })
                .then(() => {
                    notify(translate('ra.input.trash.restore.success_plural'));
                    refresh();
                })
                .catch((error) => {
                    notify(error.message);
                });
    };

    return (
        <Fragment>
            <Button
                onClick={handleDialogOpen}
                label='ra.input.trash.restore.button'
                {...rest}
            >
                <RestoreFromTrashIcon />
            </Button>
            <Confirm
                isOpen={open}
                loading={loading}
                title={
                    !isPlural
                        ? 'ra.input.trash.restore.title'
                        : 'ra.input.trash.restore.title_plural'
                }
                content={
                    !isPlural
                        ? 'ra.input.trash.restore.message'
                        : 'ra.input.trash.restore.message_plural'
                }
                translateOptions={{
                    name: resource,
                    id: record.id,
                }}
                onConfirm={handleRestore}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
};

export default CustomRestoreButton;
