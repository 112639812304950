import * as React from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import theme from '../themes/DefaultTheme';

const useStyles = makeStyles(() => ({}), {
    name: 'NewMassTimeForm',
});

const MassTimeWrapper = ({ children }) => {
    const classes = useStyles();
    return (
        <ThemeProvider theme={theme}>
            <div className={classes.page}>
                <div className={classes.wrapper}>
                    {children}
                </div>
            </div>
        </ThemeProvider>
    );
};

export default MassTimeWrapper;
