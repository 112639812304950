import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import FormTheme from '../../themes/DefaultThemeForm';
import {
    useNotify,
    useRefresh,
    TextField,
    Edit,
    SimpleForm,
    TextInput,
} from 'react-admin';
import CustomInputs from '../CustomInputs';
import CustomToolbar from '../CustomToolbar';
import EditActions from '../EditActions';
import ValidationHelper from '../../helpers/ValidationHelper';

const useStyles = makeStyles(() => FormTheme.styles, {
    name: 'RoleForm',
});

export const RoleEdit = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const onSuccess = () => {
        notify(translate('ra.notification.updated', 1));
        refresh();
    };

    const validate = (values) =>
        ValidationHelper.ValidateRequired(
            values,
            'name',
            translate('ra.validation.required')
        );

    return (
        <Edit
            {...props}
            actions={<EditActions />}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.configuration'
            )}: ${translate('ra.navigation.resources.role', 2)}`}
            onSuccess={onSuccess}
            onFailure={(e) => {
                notify(ValidationHelper.FormatApiErrors(e));
            }}
            undoable={false}
        >
            <SimpleForm
                validate={validate}
                warnWhenUnsavedChanges
                toolbar={<CustomToolbar />}
                redirect='list'
                className={classes.form}
            >
                <TextField source='id' label={translate('ra.input.id', 1)} />
                <CustomInputs.NameTextInput />
                <TextInput
                    variant='outlined'
                    label={translate('ra.input.description', 1)}
                    source='description'
                    fullWidth
                />
                <CustomInputs.OrderingNumberInput
                    helperText={translate('ra.input.helper.optional_ordering')}
                />
                <CustomInputs.InternalNotesTextInput />
            </SimpleForm>
        </Edit>
    );
};
