import * as React from 'react';
import AuthProvider from '../providers/AuthProvider';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form } from 'react-final-form';
import { useTranslate, useNotify, useSafeSetState } from 'ra-core';
import { Notification } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import PasswordResetWrapper from '../layout/PasswordResetWrapper';
import PasswordResetTheme from '../themes/DefaultThemePasswordReset';
import ValidationHelper from '../helpers/ValidationHelper';

const useStyles = makeStyles(() => PasswordResetTheme.styles, {
    name: 'ForgottenPasswordForm',
});

const Input = ({
    meta: { touched, error },
    input: inputProps,
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const ForgotPassword = (props) => {
    const [loading, setLoading] = useSafeSetState(false);
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles(props);

    const validate = (values) =>
        ValidationHelper.ValidateRequired(
            values,
            'username',
            translate('ra.validation.required')
        );

    const submit = (values, form) => {
        setLoading(true);
        AuthProvider.forgotPassword(values).then((response) => {
            setLoading(false);
            form.resetFieldState('username');
            form.reset();
            notify(response.message);
        });
    };

    return (
        <PasswordResetWrapper header={translate('ra.auth.password_forgotten')}>
            <Form
                onSubmit={submit}
                validate={validate}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    autoFocus
                                    id='username'
                                    name='username'
                                    component={Input}
                                    label={translate('ra.auth.username')}
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <CardActions>
                            <Button
                                variant='contained'
                                type='submit'
                                color='primary'
                                disabled={loading}
                                className={classes.button}
                            >
                                {loading && (
                                    <CircularProgress
                                        className={classes.icon}
                                        size={18}
                                        thickness={2}
                                    />
                                )}
                                {translate('ra.auth.request_password_reset')}
                            </Button>
                        </CardActions>
                        <Notification />
                    </form>
                )}
            />
        </PasswordResetWrapper>
    );
};

export default ForgotPassword;
