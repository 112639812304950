import * as React from 'react';
import { useTranslate } from 'ra-core';
import { List, Datagrid, TextField } from 'react-admin';
import ListActions from '../ListActions';
import ListBulkActions from '../ListBulkActions';
import ListFilter from '../ListFilter';
import ListPagination from '../ListPagination';
import ListEmpty from '../ListEmpty';
import ResourceManagementButtons from '../ResourceManagementButtons';

export const CategoryList = (props) => {
    const translate = useTranslate();

    return (
        <List
            {...props}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.configuration'
            )}: ${translate('ra.navigation.resources.category', 2)}`}
            actions={<ListActions />}
            filters={<ListFilter noActive={true} />}
            bulkActionButtons={<ListBulkActions />}
            perPage={15}
            sort={{ field: 'slug', order: 'ASC' }}
            pagination={<ListPagination />}
            empty={<ListEmpty />}
        >
            <Datagrid>
                <TextField source='slug' label={translate('ra.input.slug', 1)} />
                <TextField source='name' label={translate('ra.input.name', 1)} />
                <ResourceManagementButtons
                    reportButtonName={translate('ra.action.report')}
                    reportUrl={'advanced/category-report'}
                    reportMimeType={'application/docx'}
                    reportExtension={'docx'}
                />
            </Datagrid>
        </List>
    );
};
