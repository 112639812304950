import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import FormTheme from '../../themes/DefaultThemeForm';
import {
    useNotify,
    useRefresh,
    useRedirect,
    Create,
    SimpleForm,
} from 'react-admin';
import CustomInputs from '../CustomInputs';
import CreateActions from '../CreateActions';
import ValidationHelper from '../../helpers/ValidationHelper';

const useStyles = makeStyles(() => FormTheme.styles, {
    name: 'MassLanguageForm',
});

export const MassLanguageCreate = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = ({ data }) => {
        notify(translate('ra.notification.created'));
        redirect(data.id);
        refresh();
    };

    const validate = (values) => ({
        ...ValidationHelper.ValidateRequired(
            values,
            ['name', 'meta_description'],
            translate('ra.validation.required')
        ),
        ...ValidationHelper.ValidateLength(
            values,
            'meta_description',
            160,
            translate('ra.validation.maxLength', {
                max: 160,
            })
        ),
    });

    const transform = (data) => {
        return {
            ...data,
            is_show_overview: data.is_show_overview ? 1 : 0,
            is_active: data.is_active ? 1 : 0,
        };
    };

    return (
        <Create
            {...props}
            actions={<CreateActions />}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.configuration'
            )}: ${translate('ra.navigation.resources.mass_language', 2)}`}
            transform={transform}
            onSuccess={onSuccess}
            onFailure={(e) => {
                notify(ValidationHelper.FormatApiErrors(e));
            }}
            undoable={false}
        >
            <SimpleForm
                validate={validate}
                warnWhenUnsavedChanges
                redirect='list'
                className={classes.form}
            >
                <h3 className={classes.formTitle} fullWidth>
                    {translate('ra.action.creating')}
                    {' - '}
                    {translate('ra.navigation.resources.mass_language', 1)}
                </h3>
                <p fullWidth>
                    <strong>{translate('ra.action.note')}</strong>{' '}
                    {translate('ra.message.slug_auto_generated')}
                </p>
                <CustomInputs.NameTextInput />
                <CustomInputs.MetaDescriptionTextInput />
                <CustomInputs.DescriptionRichTextInput />
                <CustomInputs.OrderingNumberInput
                    helperText={translate('ra.input.helper.optional_ordering')}
                />
                <CustomInputs.IsShowOverviewBooleanInput />
                <CustomInputs.IsActiveBooleanInput defaultValue={true} />
                <CustomInputs.InternalNotesTextInput />
            </SimpleForm>
        </Create>
    );
};
