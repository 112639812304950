import React, { useEffect, useContext } from 'react';
import DataProvider from '../../providers/DataProvider';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslate, useSafeSetState } from 'ra-core';
import CircularProgress from '@material-ui/core/CircularProgress';
import MediaWrapper from '../../layout/MediaWrapper';
import { MediaContext } from './MediaProvider';
import MediaDraggableInput from './MediaDraggableInput';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '1rem 0',
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const MediaAccordion = (props) => {
    const [state, dispatch] = useContext(MediaContext);
    const thisResource = {
        basePath: props.data.basePath.replace(/^\/+/, ''),
        id: props.data.record.id,
        collection: props.collection.id,
    };
    const [isLoading, setIsLoading] = useSafeSetState(true);
    const [currentMedia, setCurrentMedia] = useSafeSetState([]);
    const translate = useTranslate();
    const classes = useStyles();

    const loadAccordionData = function (event, expanded, toggle = true) {
        if (toggle) {
            dispatch({
                type: 'toggle_accordion',
                collection: thisResource.collection,
            });
        }
        if (expanded) {
            setIsLoading(true);
            DataProvider.getMedia(
                thisResource.basePath,
                thisResource.id,
                thisResource.collection
            ).then((response) => {
                const mediaData = response['data']['media']['data'];
                if (typeof mediaData === 'object') {
                    setCurrentMedia(mediaData[thisResource.collection]);
                    setIsLoading(false);
                }
            });
        }
    };

    useEffect(() => {
        if (state.dirty.includes(thisResource.collection)) {
            dispatch({
                type: 'toggle_dirty_accordion',
                collection: thisResource.collection,
            });
            loadAccordionData(null, true, false);
        }
    }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // Load any initially open accordions
        if (state.open.includes(thisResource.collection)) {
            loadAccordionData(null, true, false);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <MediaWrapper
            header={`${translate('ra.action.edit')} ${translate(
                'ra.input.media_item',
                1
            )}`}
        >
            <div className={classes.root}>
                <Accordion
                    onChange={loadAccordionData}
                    expanded={state.open.includes(thisResource.collection)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                    >
                        <Typography className={classes.heading}>
                            {props.collection.name}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {!isLoading ? (
                            <MediaDraggableInput
                                thisResource={thisResource}
                                currentMedia={currentMedia}
                            />
                        ) : (
                            <CircularProgress size={18} thickness={2} />
                        )}
                    </AccordionDetails>
                </Accordion>
            </div>
        </MediaWrapper>
    );
};

export default MediaAccordion;
