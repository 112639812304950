import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useSafeSetState } from 'ra-core';
import FormTheme from '../../themes/DefaultThemeForm';
import {
    useNotify,
    useRefresh,
    useRedirect,
    Create,
    SimpleForm,
    BooleanInput,
} from 'react-admin';
import CustomInputs from '../CustomInputs';
import CreateActions from '../CreateActions';
import ValidationHelper from '../../helpers/ValidationHelper';

const useStyles = makeStyles(() => FormTheme.styles, {
    name: 'ServiceCategoryForm',
});

export const ServiceCategoryCreate = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = ({ data }) => {
        notify(translate('ra.notification.created'));
        redirect(data.id);
        refresh();
    };

    const transform = (data) => ({
        ...data,
        is_show_all_products_overview: data.is_show_all_products_overview ? 1 : 0,
        is_active: data.is_active ? 1 : 0,
    });

    const validate = (values) => ({
        ...ValidationHelper.ValidateRequired(
            values,
            ['category_id', 'name', 'meta_description'],
            translate('ra.validation.required')
        ),
        ...ValidationHelper.ValidateLength(
            values,
            'meta_description',
            160,
            translate('ra.validation.maxLength', {
                max: 160,
            })
        ),
    });

    const [categoryId, setCategoryId] = useSafeSetState(null);
    const filter = () => {
        const filter = { flatten: null };
        if (categoryId) {
            return {
                ...filter,
                category_id: categoryId,
            };
        } else {
            return filter;
        }
    };

    return (
        <Create
            {...props}
            actions={<CreateActions />}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.directory'
            )}: ${translate('ra.navigation.resources.service_category', 2)}`}
            transform={transform}
            onSuccess={onSuccess}
            onFailure={(e) => {
                notify(ValidationHelper.FormatApiErrors(e));
            }}
            undoable={false}
        >
            <SimpleForm
                validate={validate}
                warnWhenUnsavedChanges
                redirect='list'
                className={classes.form}
            >
                <h3 className={classes.formTitle} fullWidth>
                    {translate('ra.action.creating')}
                    {' - '}
                    {translate('ra.navigation.resources.service_category', 1)}
                </h3>
                <p fullWidth>
                    <strong>{translate('ra.action.note')}</strong>{' '}
                    {translate('ra.message.slug_auto_generated')}
                </p>
                <CustomInputs.CategoryReferenceInput
                    onChange={(e) => setCategoryId(e.target.value)}
                />
                <CustomInputs.ServiceCategoryInput
                    label={translate('ra.input.parent', 1)}
                    source='parent_id'
                    filter={filter()}
                    allowEmpty
                />
                <CustomInputs.NameTextInput />
                <CustomInputs.MetaDescriptionTextInput />
                <CustomInputs.DescriptionRichTextInput />
                <CustomInputs.GoalsInput filter={filter()} allowEmpty />
                <BooleanInput
                    label={translate('ra.input.show_products_in_overview', 1)}
                    source='is_show_all_products_overview'
                    defaultValue={true}
                    fullWidth
                />
                <CustomInputs.OrderingNumberInput
                    helperText={translate('ra.input.helper.optional_ordering')}
                />
                <CustomInputs.IsActiveBooleanInput />
                <CustomInputs.InternalNotesTextInput />
            </SimpleForm>
        </Create>
    );
};
