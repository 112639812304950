import React, { useState } from 'react';
import Wkt from 'wicket';
import { TextInput } from 'react-admin';

const AreaHelper = {
    AreaObjectToWkt: (record) => {
        let initialWkt = null;
        if (record?.area?.data) {
            const wkt = new Wkt.Wkt();
            wkt.fromObject(record?.area?.data[0]);
            initialWkt = wkt.write();
        }
        return initialWkt;
    },
    AreaEditInput: ({ record, ...props }) => {
        const [areaString, setAreaString] = useState(
            AreaHelper.AreaObjectToWkt(record)
        );
        const onChange = (e) => {
            setAreaString(e.target.value);
        };
        return (
            <TextInput
                source='area'
                component='pre'
                multiline
                inputProps={{
                    onChange,
                    value: areaString,
                }}
                {...props}
            />
        );
    },
};

export default AreaHelper;
