import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import FormTheme from '../../themes/DefaultThemeForm';
import {
    useNotify,
    useRefresh,
    useRedirect,
    Create,
    SimpleForm,
    SelectInput,
} from 'react-admin';
import CustomInputs from '../CustomInputs';
import CreateActions from '../CreateActions';
import ValidationHelper from '../../helpers/ValidationHelper';
import MetaCategoryHelper from '../../helpers/MetaCategoryHelper';

const useStyles = makeStyles(() => FormTheme.styles, {
    name: 'MetaCategoryForm',
});

export const MetaCategoryCreate = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = ({ data }) => {
        notify(translate('ra.notification.created'));
        redirect(data.id);
        refresh();
    };

    const validate = (values) =>
        ValidationHelper.ValidateRequired(
            values,
            ['model', 'attribute', 'name', 'ordering'],
            translate('ra.validation.required')
        );

    return (
        <Create
            {...props}
            actions={<CreateActions />}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.configuration'
            )}: ${translate('ra.navigation.resources.meta_category', 2)}`}
            onSuccess={onSuccess}
            onFailure={(e) => {
                notify(ValidationHelper.FormatApiErrors(e));
            }}
            undoable={false}
        >
            <SimpleForm
                validate={validate}
                warnWhenUnsavedChanges
                redirect='list'
                className={classes.form}
            >
                <h3 className={classes.formTitle} fullWidth>
                    {translate('ra.action.creating')}
                    {' - '}
                    {translate('ra.navigation.resources.meta_category', 1)}
                </h3>
                <SelectInput
                    fullWidth
                    variant='outlined'
                    label='Model'
                    source='model'
                    choices={MetaCategoryHelper.ToChoices(MetaCategoryHelper.Models())}
                />
                <MetaCategoryHelper.AttributeInput
                    fullWidth
                    variant='outlined'
                    label={translate('ra.input.attribute', 1)}
                    source='attribute'
                />
                <CustomInputs.NameTextInput />
                <CustomInputs.OrderingNumberInput />
                <CustomInputs.InternalNotesTextInput />
            </SimpleForm>
        </Create>
    );
};
