import * as React from 'react';
import { useTranslate } from 'ra-core';
import { makeStyles, Chip } from '@material-ui/core';
import { TextInput, Filter } from 'react-admin';

const useStyles = makeStyles({
    filter: {
        width: '15em',
    },
});

const useQuickFilterStyles = makeStyles((theme) => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));

const QuickFilter = ({ label }) => {
    const translate = useTranslate();
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={translate(label)} />;
};

const ListFilter = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <TextInput
                label={translate('ra.action.search')}
                source='name'
                alwaysOn
                className={classes.filter}
            />
            {!props.noSlug && (
                <TextInput
                    label={translate('ra.input.slug', 1)}
                    source='slug'
                    defaultValue=''
                    className={classes.filter}
                />
            )}
            <TextInput
                label={translate('ra.input.name', 1)}
                source='name'
                defaultValue=''
                className={classes.filter}
            />
            {!props.noActive && (
                <QuickFilter
                    source='active'
                    label={translate('ra.input.active')}
                    defaultValue={1}
                />
            )}
            {!props.noActive && (
                <QuickFilter
                    source='inactive'
                    label={`${translate('ra.action.not')} ${translate(
                        'ra.input.active'
                    ).toLowerCase()}`}
                    defaultValue={1}
                />
            )}
            {!props.trash && (
                <QuickFilter
                    source='trash'
                    label={translate('ra.input.trash.show')}
                    defaultValue={1}
                />
            )}
        </Filter>
    );
};

export default ListFilter;
