const ValidationHelper = {
    FormatError: (e) => {
        return e && typeof e === 'object' ? e.join(' \n ') : e;
    },

    FormatApiErrors: (e) => {
        let messages = e.body.message;
        if (e.body.errors) {
            for (const key of Object.keys(e.body.errors)) {
                messages += ` ${ValidationHelper.FormatError(e.body.errors[key])}`;
            }
        }
        return messages;
    },

    PasswordStrength: (p) => {
        // Minimum 8 characters with at least 1 upper case character,
        // 1 lower case character, 1 digit, 1 special character
        const pattern =
            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[^\p{L}\d\s@#]).{8,}$/gmu;
        return pattern.test(p);
    },

    ValidateRequired: (v, properties, msg) => {
        const e = {};
        for (const p of [].concat(properties)) {
            if (!v[p] && v[p] !== 0) {
                e[p] = msg;
            }
        }
        return e;
    },

    ValidateLength: (v, properties, length, msg) => {
        const e = {};
        for (const p of [].concat(properties)) {
            if (v[p] && v[p].length > length) {
                e[p] = msg;
            }
        }
        return e;
    },

    ValidateInteger: (v, properties, msg) => {
        const e = {};
        for (const p of [].concat(properties)) {
            if (v[p] && !Number.isInteger(v[p])) {
                e[p] = msg;
            }
        }
        return e;
    },

    ValidateMatch: (v, a, b, msg) => {
        const e = {};
        if (v[a] !== v[b]) {
            e[b] = msg;
        }
        return e;
    },

    ValidateUsername: (v, properties, msg) => {
        const e = {};
        for (const p of [].concat(properties)) {
            if (v[p] && v[p].search(/^[a-zA-Z0-9-_]+$/) === -1) {
                e[p] = msg;
            }
        }
        return e;
    },

    ValidatePasswordStrength: (v, properties, msg) => {
        const e = {};
        for (const p of [].concat(properties)) {
            if (!ValidationHelper.PasswordStrength(v[p])) {
                e[p] = msg;
            }
        }
        return e;
    },

    ValidatePhoneNumbers: (v, property, msg) => {
        const e = {};
        if (v[property]?.data && Array.isArray(v[property].data)) {
            const phoneNumberErrors = { data: [] };
            v[property].data.forEach((p, i) => {
                const thisError = {};
                if (p) {
                    if (!v[property]?.data?.[i].type_meta_category_id) {
                        thisError.type_meta_category_id = msg;
                    }
                    if (!v[property]?.data?.[i].number) {
                        thisError.number = msg;
                    }
                }
                if (Object.keys(thisError).length) {
                    phoneNumberErrors.data[i] = thisError;
                }
            });
            if (phoneNumberErrors.data.length) {
                e[property] = phoneNumberErrors;
            }
        }
        return e;
    },

    ValidateAddresses: (v, property, msg) => {
        const e = {};
        if (v[property]?.data && Array.isArray(v[property].data)) {
            const addressErrors = { data: [] };
            v[property].data.forEach((a, i) => {
                const thisError = {};
                if (a) {
                    if (!v[property]?.data?.[i].type_meta_category_id) {
                        thisError.type_meta_category_id = msg;
                    }
                    if (!v[property]?.data?.[i].suburb) {
                        thisError.suburb = msg;
                    }
                    if (!v[property]?.data?.[i].state) {
                        thisError.state = msg;
                    }
                    if (!v[property]?.data?.[i].postcode) {
                        thisError.postcode = msg;
                    }
                }
                if (Object.keys(thisError).length) {
                    addressErrors.data[i] = thisError;
                }
            });
            if (addressErrors.data.length) {
                e[property] = addressErrors;
            }
        }
        return e;
    },

    ValidateOnlinePlatforms: (v, property, msg) => {
        const e = {};
        if (v[property]?.data && Array.isArray(v[property].data)) {
            const onlinePlatformErrors = { data: [] };
            v[property].data.forEach((p, i) => {
                const thisError = {};
                if (p) {
                    if (!v[property]?.data?.[i].type_meta_category_id) {
                        thisError.type_meta_category_id = msg;
                    }
                    if (!v[property]?.data?.[i].url) {
                        thisError.url = msg;
                    }
                    if (!v[property]?.data?.[i].name) {
                        thisError.name = msg;
                    }
                }
                if (Object.keys(thisError).length) {
                    onlinePlatformErrors.data[i] = thisError;
                }
            });
            if (onlinePlatformErrors.data.length) {
                e[property] = onlinePlatformErrors;
            }
        }
        return e;
    },
};

export default ValidationHelper;
