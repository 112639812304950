export const MassTimeReducer = (state, action) => {
    const thisSeason = action.season.toLowerCase();
    const setStateData = (stateOption, season) =>
        !stateOption.includes(season)
            ? stateOption.concat(season)
            : stateOption.filter((n) => n !== season);

    switch (action.type) {
        case 'toggle_accordion':
            state.open = setStateData(state.open, thisSeason);
            return {
                ...state,
                open: state.open,
            };

        case 'toggle_dirty_accordion':
            if (state.open.includes(thisSeason)) {
                state.dirty = setStateData(state.dirty, thisSeason);
            }
            return {
                ...state,
                dirty: state.dirty,
            };

        default:
            return state;
    }
};

export const initialState = {
    open: [],
    dirty: [],
};
