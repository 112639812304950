import * as React from 'react';
import PathHelper from '../helpers/PathHelper';

const ListTransformPathField = ({ record = {} }) => {
    return (
        <div>{PathHelper.TriplePipeToSlash(record.tree_name_path_derived)}</div>
    );
};

export default ListTransformPathField;
