import React, { useState } from 'react';
import { useTranslate } from 'ra-core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { DeleteWithConfirmButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
}));

const CustomDeleteButton = ({ basePath, record = {}, resource }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const translate = useTranslate();

    if (!record.is_dependency_derived) {
        // Allowed to be deleted
        return (
            <DeleteWithConfirmButton
                basePath={basePath}
                record={record}
                resource={resource}
                undoable={false}
            />
        );
    } else {
        // Not allowed to be deleted
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };
        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (
            <>
                <Button>
                    <HelpOutlineIcon
                        variant='contained'
                        color='secondary'
                        startIcon={<HelpOutlineIcon />}
                        onClick={handleClick}
                    />
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Typography className={classes.typography}>
                        {translate('ra.message.delete_disallowed')}
                    </Typography>
                </Popover>
            </>
        );
    }
};

export default CustomDeleteButton;
