import * as React from 'react';
import { useTranslate } from 'ra-core';
import {
    useQuery,
    Loading,
    Error,
    FilterList,
    FilterListItem,
} from 'react-admin';
import CategoryIcon from '@material-ui/icons/Apps';
import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core';

const CategoryFilter = () => {
    const translate = useTranslate();
    const { data, loading, error } = useQuery({
        type: 'getList',
        resource: 'categories',
        payload: {
            pagination: { page: 1, perPage: -1 },
            filter: {},
            sort: { field: 'name', order: 'ASC' },
        },
    });

    if (loading) {
        return <Loading />;
    }
    if (error) {
        return <Error />;
    }
    if (!data) {
        return null;
    }

    return (
        <FilterList
            label={translate('ra.navigation.resources.category', 1)}
            icon={<CategoryIcon />}
        >
            {data.map((d) => {
                return (
                    <FilterListItem
                        label={d.name}
                        value={{
                            category_id: d.id,
                            service_category_path: null,
                        }}
                        key={d.id}
                    />
                );
            })}
        </FilterList>
    );
};

const Card = withStyles((theme) => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginLeft: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

const ListCategoryFilter = () => (
    <Card>
        <CardContent>
            <CategoryFilter />
        </CardContent>
    </Card>
);

export default ListCategoryFilter;
