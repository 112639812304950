import * as React from 'react';
import { useTranslate } from 'ra-core';
import { makeStyles, Chip } from '@material-ui/core';
import {
    TextField,
    Filter,
    List,
    Datagrid,
    ReferenceField,
    TextInput,
} from 'react-admin';
import ListActions from '../ListActions';
import ListBulkActions from '../ListBulkActions';
import ListPagination from '../ListPagination';
import ListActiveField from '../ListActiveField';
import ListEmpty from '../ListEmpty';
import ResourceManagementButtons from '../ResourceManagementButtons';

const useStyles = makeStyles({
    filter: {
        width: '15em',
    },
});

const useQuickFilterStyles = makeStyles((theme) => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));

const QuickFilter = ({ label }) => {
    const translate = useTranslate();
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={translate(label)} />;
};

const EntityFilter = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <TextInput
                label={translate('ra.action.search')}
                source='search_keyword_term'
                alwaysOn
                className={classes.filter}
            />
            <TextInput
                label={translate('ra.input.slug', 1)}
                source='slug'
                defaultValue=''
                className={classes.filter}
            />
            <TextInput
                label={translate('ra.input.name', 1)}
                source='name_with_locality_derived'
                defaultValue=''
                className={classes.filter}
            />
            <QuickFilter
                source='active'
                label={translate('ra.input.active')}
                defaultValue={1}
            />
            <QuickFilter
                source='inactive'
                label={`${translate('ra.action.not')} ${translate(
                    'ra.input.active'
                ).toLowerCase()}`}
                defaultValue={1}
            />
            {!props.trash && (
                <QuickFilter
                    source='trash'
                    label={translate('ra.input.trash.show')}
                    defaultValue={1}
                />
            )}
        </Filter>
    );
};

export const EntityList = (props) => {
    const translate = useTranslate();
    return (
        <List
            {...props}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.directory'
            )}: ${translate('ra.navigation.resources.entity', 2)}`}
            actions={<ListActions />}
            filters={<EntityFilter />}
            bulkActionButtons={<ListBulkActions />}
            perPage={15}
            sort={{ field: '-search_relevance,slug', order: 'ASC' }}
            pagination={<ListPagination />}
            empty={<ListEmpty />}
        >
            <Datagrid>
                <TextField source='slug' label={translate('ra.input.slug', 1)} />
                <TextField
                    source='name_with_locality_derived'
                    label={translate('ra.input.name', 1)}
                />
                <ReferenceField
                    label={translate('ra.navigation.resources.region', 1)}
                    source='region_id'
                    reference='regions'
                    sortable={false}
                >
                    <TextField source='name' />
                </ReferenceField>
                <ListActiveField
                    source='is_active'
                    label={translate('ra.input.active')}
                />
                <ResourceManagementButtons />
            </Datagrid>
        </List>
    );
};
