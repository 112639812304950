const UrlHelper = {
    HashToObject: (hash) => {
        const result = {};
        hash
            .split('?')
            .pop()
            .split('&')
            .forEach((item) => {
                result[item.split('=')[0]] = decodeURIComponent(item.split('=')[1]);
            });
        return result;
    },

    YouTubeIdToUrl: (id) => `https://www.youtube.com/watch?v=${id}`,
};

export default UrlHelper;
