import React, { useState } from 'react';
import { useTranslate } from 'ra-core';
import keyBy from 'lodash/keyBy';
import {
    usePermissions,
    useQuery,
    Datagrid,
    TextField,
    Loading,
    ListContextProvider,
    EditButton,
    ShowButton,
} from 'react-admin';
import ListTransformPathField from '../ListTransformPathField';
import ListActiveField from '../ListActiveField';
import CustomDeleteButton from '../CustomDeleteButton';
import ListPagination from '../ListPagination';

export const EntityShowProductsList = ({ record }) => {
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(15);
    const { data, total, loading, error } = useQuery({
        type: 'getManyReference',
        resource: 'entities',
        payload: {
            pagination: { page, perPage },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
            id: record.id,
            reference: 'products',
        },
    });

    if (loading) {
        return <Loading />;
    }
    if (error) {
        return <p>{error.message}</p>;
    }
    return (
        <ListContextProvider
            value={{
                total,
                page,
                setPage,
                perPage,
                setPerPage,
                data: keyBy(data, 'id'),
                ids: data.map(({ id }) => id),
                currentSort: { field: 'name', order: 'ASC' },
                basePath: '/products',
                resource: 'products',
                selectedIds: [],
            }}
        >
            <Datagrid>
                <TextField
                    source='name_with_locality_derived'
                    label={translate('ra.input.name', 1)}
                    sortable={false}
                />
                <TextField
                    source='slug'
                    label={translate('ra.input.slug', 1)}
                    sortable={false}
                />
                <TextField
                    source='meta_description'
                    label={translate('ra.input.meta_description', 1)}
                    sortable={false}
                />
                <ListActiveField
                    source='is_active'
                    label={translate('ra.input.active')}
                    sortable={false}
                />
                <ListTransformPathField
                    label={translate('ra.input.path', 1)}
                    record={record}
                    sortable={false}
                />
                {permissions &&
                    (permissions.includes('directory') ||
                        permissions.includes('directory-products')) && <EditButton />}
                {permissions &&
                    (permissions.includes('directory') ||
                        permissions.includes('directory-products')) && (
                        <CustomDeleteButton />
                    )}
                {permissions &&
                    (permissions.includes('directory') ||
                        permissions.includes('directory-products')) && <ShowButton />}
            </Datagrid>
            <ListPagination />
        </ListContextProvider>
    );
};
