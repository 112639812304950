import * as React from 'react';
import { forwardRef } from 'react';
import { useTranslate } from 'ra-core';
import { AppBar, UserMenu, MenuItemLink } from 'react-admin';
import PasswordIcon from '@material-ui/icons/VpnKey';

const ConfigurationMenu = forwardRef(({ onClick }, ref) => (
    <MenuItemLink
        ref={ref}
        to='/change-password'
        primaryText={useTranslate()('ra.navigation.menu.change_password')}
        leftIcon={<PasswordIcon />}
        onClick={onClick} // close the menu on click
    />
));
ConfigurationMenu.displayName = 'ConfigurationMenu';

const CustomUserMenu = (props) => (
    <UserMenu {...props}>
        <ConfigurationMenu />
    </UserMenu>
);

const CustomAppBar = (props) => {
    return <AppBar {...props} userMenu={<CustomUserMenu />} />;
};

export default CustomAppBar;
