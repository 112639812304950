import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import FormTheme from '../../themes/DefaultThemeForm';
import {
    Show,
    SimpleShowLayout,
    ReferenceField,
    RichTextField,
    TextField,
    BooleanField,
    DateField,
    FunctionField,
} from 'react-admin';
import Link from '@material-ui/core/Link';
import UrlHelper from '../../helpers/UrlHelper';
import CustomInputs from '../CustomInputs';
import ShowActions from '../ShowActions';
import { PersonnelShowProductsList } from './PersonnelShowProductsList';
import { MediaShowList } from '../media/MediaShowList';

const useStyles = makeStyles(() => FormTheme.styles, {
    name: 'PersonnelForm',
});

export const PersonnelShow = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const ShowTitle = ({ record }) => {
        return (
            <h3 className={classes.formTitle} fullWidth>
                {translate('ra.action.showing')}{' '}
                {translate('ra.navigation.resources.personnel', 1)}: {record.first_name}{' '}
                {record.last_name}
            </h3>
        );
    };
    return (
        <Show
            {...props}
            actions={<ShowActions />}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.directory'
            )}: ${translate('ra.navigation.resources.personnel', 2)}`}
        >
            <SimpleShowLayout>
                <ShowTitle />
                <TextField source='id' label={translate('ra.input.id', 1)} />
                <ReferenceField
                    label={translate('ra.input.type', 1)}
                    source='title_meta_category_id'
                    reference='meta-categories'
                >
                    <TextField source='name' />
                </ReferenceField>
                <TextField
                    source='first_name'
                    label={translate('ra.input.first_name', 1)}
                />
                <TextField
                    source='last_name'
                    label={translate('ra.input.last_name', 1)}
                />
                <TextField
                    source='post_nominal'
                    label={translate('ra.input.post_nominal', 1)}
                />
                <TextField source='email' label={translate('ra.input.email', 1)} />
                <CustomInputs.PhoneNumberArrayField className={classes.show} />
                <TextField
                    source='biography_pull_quote'
                    label={translate('ra.input.biography_pull_quote', 1)}
                />
                <RichTextField
                    source='biography'
                    label={translate('ra.input.biography', 1)}
                    className={classes.show}
                />
                <FunctionField
                    source='biography_video_youtube_id'
                    render={(record) =>
                        <Link
                            href={UrlHelper.YouTubeIdToUrl(
                                record.biography_video_youtube_id
                            )}>{record.biography_video_youtube_id}
                        </Link>
                    }
                    label={translate('ra.input.biography_video_youtube_id', 1)}
                />
                <BooleanField source='is_active' label={translate('ra.input.active')} />
                <TextField
                    source='internal_notes'
                    label={translate('ra.input.internal_note', 2)}
                />
                <DateField
                    source='updated_at'
                    label={translate('ra.input.last_updated_date')}
                />
                <DateField
                    source='created_at'
                    label={translate('ra.input.publication_date')}
                />
                <h4>
                    {translate('ra.message.associated')}{' '}
                    {translate('ra.navigation.resources.product', 2).toLowerCase()}
                </h4>
                <PersonnelShowProductsList />
                <MediaShowList />
            </SimpleShowLayout>
        </Show>
    );
};
