import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import FormTheme from '../../themes/DefaultThemeForm';
import {
    useNotify,
    useRefresh,
    TextField,
    Edit,
    TabbedForm,
    FormTab,
} from 'react-admin';
import CustomInputs from '../CustomInputs';
import CustomToolbar from '../CustomToolbar';
import { MediaProvider } from '../media/MediaProvider';
import EditActions from '../EditActions';
import ValidationHelper from '../../helpers/ValidationHelper';

const useStyles = makeStyles(() => FormTheme.styles, {
    name: 'PersonnelForm',
});

export const PersonnelEdit = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const onSuccess = () => {
        notify(translate('ra.notification.updated', 1));
        refresh();
    };

    const transform = (data) => ({
        ...data,
        phone_numbers: {
            data: data?.phone_numbers?.data?.filter((p) => p) ?? [],
        },
        is_active: data.is_active ? 1 : 0,
    });

    const validate = (values) =>
        ValidationHelper.ValidateRequired(
            values,
            ['title_meta_category_id', 'first_name', 'last_name'],
            translate('ra.validation.required')
        );

    return (
        <Edit
            {...props}
            actions={<EditActions />}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.directory'
            )}: ${translate('ra.navigation.resources.personnel', 2)}`}
            transform={transform}
            onSuccess={onSuccess}
            onFailure={(e) => {
                notify(ValidationHelper.FormatApiErrors(e));
            }}
            undoable={false}
        >
            <TabbedForm
                validate={validate}
                warnWhenUnsavedChanges
                toolbar={<CustomToolbar />}
                redirect='list'
                className={classes.form}
            >
                <FormTab label='Overview'>
                    <TextField source='id' label={translate('ra.input.id', 1)} />
                    <CustomInputs.TitleReferenceInput
                        filter={{ model: 'personnel', attribute: 'title' }}
                    />
                    <CustomInputs.FirstNameTextInput />
                    <CustomInputs.LastNameTextInput />
                    <CustomInputs.PostNominalTextInput />
                    <CustomInputs.BiographyPullQuoteTextInput />
                    <CustomInputs.BiographyRichTextInput />
                    <CustomInputs.BiographyVideoYouTubeIdTextInput />
                    <CustomInputs.IsActiveBooleanInput />
                    <CustomInputs.InternalNotesTextInput />
                </FormTab>
                <FormTab label={translate('ra.input.contact', 2)}>
                    <CustomInputs.EmailTextInput />
                    <CustomInputs.PhoneNumberArrayInput
                        filter={{ model: 'personnel', attribute: 'PhoneNumbers' }}
                    />
                </FormTab>
                <FormTab label={translate('ra.navigation.resources.product', 2)}>
                    <CustomInputs.RoleProductArrayInput
                        filter={{ model: 'personnel', attribute: 'Role' }}
                    />
                </FormTab>
                <FormTab label={translate('ra.input.media_collection', 2)}>
                    <MediaProvider data={props} />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};
