import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import FormTheme from '../../themes/DefaultThemeForm';
import {
    useNotify,
    useRefresh,
    TextField,
    TextInput,
    Edit,
    TabbedForm,
    FormTab,
} from 'react-admin';
import CustomInputs from '../CustomInputs';
import CustomToolbar from '../CustomToolbar';
import { MediaProvider } from '../media/MediaProvider';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import GoogleMapComponentWrapper from '../MapField';
import Button from '@material-ui/core/Button';
import EditActions from '../EditActions';
import LocationHelper from '../../helpers/LocationHelper';
import ValidationHelper from '../../helpers/ValidationHelper';
import dateFormat from 'dateformat';

const useStyles = makeStyles(() => FormTheme.styles, {
    name: 'EntityForm',
});

export const EntityEdit = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const onSuccess = () => {
        setIsLocationSet(false);
        setIsOpenHoursSet(false);
        notify(translate('ra.notification.updated', 1));
        refresh();
    };

    // Set initial states
    const WithProps = ({ record }) => {
        if (!isLocationSet && record.location) {
            if (record.location?.coordinates) {
                setLocation({
                    lat: record.location.coordinates[1],
                    lng: record.location.coordinates[0],
                });
            }
            setIsLocationSet(true);
        }
        if (!isOpenHoursSet && record.open_hours) {
            record.open_hours.data.map((d) => {
                if (!(d.open_time instanceof Date)) {
                    d.open_time = new Date(`2016-06-10T${d.open_time}`);
                }
                if (!(d.close_time instanceof Date)) {
                    d.close_time = new Date(`2016-06-10T${d.close_time}`);
                }
                return d;
            });
            setIsOpenHoursSet(true);
        }
        return '';
    };

    // Open hours states
    const [isOpenHoursSet, setIsOpenHoursSet] = useState(null);

    // Map states
    const [isLocationSet, setIsLocationSet] = useState(null);
    const [location, setLocation] = useState({
        lat: null,
        lng: null,
    });
    const [locationSearch, setLocationSearch] = useState(null);
    const getLocationSearch = (place) => {
        LocationHelper.SetLatLngByPlaces(place, setLocationSearch, setLocation);
    };

    const validate = (values) => {
        const errors = {
            ...ValidationHelper.ValidateRequired(
                values,
                ['name', 'slug', 'meta_description'],
                translate('ra.validation.required')
            ),
            ...ValidationHelper.ValidateLength(
                values,
                'meta_description',
                160,
                translate('ra.validation.maxLength', {
                    max: 160,
                })
            ),
            ...ValidationHelper.ValidatePhoneNumbers(
                values,
                'phone_numbers',
                translate('ra.validation.required')
            ),
            ...ValidationHelper.ValidateAddresses(
                values,
                'addresses',
                translate('ra.validation.required')
            ),
            ...ValidationHelper.ValidateOnlinePlatforms(
                values,
                'online_platforms',
                translate('ra.validation.required')
            ),
        };

        if (values.open_hours?.data && Array.isArray(values.open_hours.data)) {
            const openHoursErrors = { data: [] };
            values.open_hours.data.forEach((o, i) => {
                if (o && (o?.open_time || o?.close_time)) {
                    const thisError = {};
                    if (!o?.day && o?.day !== 0) {
                        thisError.day = translate('ra.validation.required');
                    }
                    if (!o.open_time) {
                        thisError.open_time = translate('ra.validation.required');
                    } else if (o.close_time && o.open_time >= o.close_time) {
                        thisError.open_time = translate('ra.validation.earlier_time');
                    }

                    if (!o.close_time) {
                        thisError.close_time = translate('ra.validation.required');
                    } else if (o.open_time && o.open_time >= o.close_time) {
                        thisError.close_time = translate('ra.validation.later_time');
                    }

                    if (Object.keys(thisError).length) {
                        openHoursErrors.data[i] = thisError;
                    }
                }
            });
            if (openHoursErrors.data.length) {
                errors.open_hours = openHoursErrors;
            }
        }

        return errors;
    };

    const transform = (data) => {
        const lat = document.getElementById('lat').value;
        const lng = document.getElementById('lng').value;
        const locationWkt = lat && lng ? `POINT(${lng} ${lat})` : null;
        const openHours = data?.open_hours?.data?.map((o) => {
            const openHourData = {};
            if (o) {
                openHourData.day = parseInt(o.day, 10) ?? null;
                openHourData.notes = o.notes ?? null;
                if (o.open_time) {
                    openHourData.open_time = dateFormat(
                        o.open_time.setSeconds(0),
                        'HH:MM:ss'
                    );
                }
                if (o.close_time) {
                    openHourData.close_time = dateFormat(
                        o.close_time.setSeconds(0),
                        'HH:MM:ss'
                    );
                }
            }
            return openHourData;
        });
        return {
            ...data,
            location: locationWkt,
            phone_numbers: {
                data: data?.phone_numbers?.data?.filter((p) => p) ?? [],
            },
            addresses: { data: data?.addresses?.data?.filter((a) => a) ?? [], },
            open_hours: { data: openHours?.filter((o) => o) ?? [], },
            online_platforms: {
                data: data?.online_platforms?.data?.filter((o) => o) ?? [],
            },
            is_exclude_locality_from_name: data.is_exclude_locality_from_name ? 1 : 0,
            is_show_in_search: data.is_show_in_search ? 1 : 0,
            is_show_overview: data.is_show_overview ? 1 : 0,
            is_virtual: data.is_virtual ? 1 : 0,
            is_active: data.is_active ? 1 : 0,
        };
    };

    return (
        <Edit
            {...props}
            actions={<EditActions />}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.directory'
            )}: ${translate('ra.navigation.resources.entity', 2)}`}
            transform={transform}
            onSuccess={onSuccess}
            onFailure={(e) => {
                notify(ValidationHelper.FormatApiErrors(e));
            }}
            undoable={false}
        >
            <TabbedForm
                validate={validate}
                warnWhenUnsavedChanges
                toolbar={<CustomToolbar alwaysEnableSaveButton />}
                redirect='list'
                className={classes.form}
            >
                <FormTab label='Overview'>
                    <TextField source='id' label={translate('ra.input.id', 1)} />
                    <CustomInputs.NameTextInput />
                    <CustomInputs.SlugTextInput />
                    <CustomInputs.LocalityTextInput />
                    <CustomInputs.IsExcludeLocalityBooleanInput fullWidth />
                    <CustomInputs.MetaDescriptionTextInput />
                    <CustomInputs.DescriptionRichTextInput />
                    <CustomInputs.NotesRichTextInput />
                    <CustomInputs.IsShowInSearchBooleanInput fullWidth />
                    <CustomInputs.IsShowOverviewBooleanInput />
                    <CustomInputs.IsVirtualBooleanInput fullWidth />
                    <CustomInputs.OrderingNumberInput
                        helperText={translate('ra.input.helper.optional_ordering')}
                    />
                    <CustomInputs.InternalNotesTextInput />
                </FormTab>
                <FormTab label={translate('ra.input.contact', 2)}>
                    <CustomInputs.EmailTextInput />
                    <CustomInputs.EnquiryFormEmailTextInput />
                    <CustomInputs.WebsiteUrlTextInput />
                    <CustomInputs.EnquiryFormUrlTextInput />
                    <CustomInputs.PhoneNumberArrayInput
                        filter={{ model: 'entity', attribute: 'PhoneNumbers' }}
                    />
                </FormTab>
                <FormTab label={translate('ra.input.location', 1)}>
                    <WithProps />
                    <CustomInputs.RegionReferenceInput />
                    <CustomInputs.AddressArrayInput
                        filter={{ model: 'entity', attribute: 'Addresses' }}
                    />
                    <div className={classes.map} fullWidth>
                        <p fullWidth>
                            <strong>{translate('ra.input.set_location', 1)}</strong>{' '}
                            {translate('ra.input.helper.set_location')}
                        </p>
                        <div>
                            <GooglePlacesAutocomplete
                                selectProps={{
                                    locationSearch,
                                    placeholder: translate('ra.input.helper.search_for_location'),
                                    onChange: getLocationSearch,
                                }}
                                autocompletionRequest={{
                                    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                                    minLengthAutocomplete: 3,
                                    componentRestrictions: {
                                        country:
                                            process.env.REACT_APP_GOOGLE_MAPS_COUNTRY.split('|'),
                                    },
                                }}
                            />
                        </div>
                        <GoogleMapComponentWrapper
                            name='map'
                            lat={location.lat}
                            lng={location.lng}
                            zoom={18}
                            draggable={true}
                            passLatLng={setLocation}
                        />
                        <div className={classes.map}>
                            <Button
                                variant='contained'
                                onClick={() => {
                                    setLocation({
                                        lat: '',
                                        lng: '',
                                    });
                                }}
                            >
                                {translate('ra.action.remove')} {translate('ra.input.pin', 1)}
                            </Button>
                        </div>
                    </div>
                    <TextInput
                        id='lat'
                        name='lat'
                        variant='outlined'
                        label={translate('ra.input.lat', 1)}
                        source='lat'
                        inputProps={{
                            value: location.lat,
                        }}
                        fullWidth
                    />
                    <TextInput
                        id='lng'
                        name='lng'
                        variant='outlined'
                        label={translate('ra.input.lng', 1)}
                        source='lng'
                        inputProps={{
                            value: location.lng,
                        }}
                        fullWidth
                    />
                </FormTab>
                <FormTab label={translate('ra.input.open_hour', 2)}>
                    <CustomInputs.OpenHoursArrayInput showServiceCategory={false} />
                </FormTab>
                <FormTab label={translate('ra.input.online_platform', 2)}>
                    <CustomInputs.OnlinePlatformArrayInput
                        filter={{ model: 'entity', attribute: 'OnlinePlatforms' }}
                    />
                </FormTab>
                <FormTab label={translate('ra.input.media_collection', 2)}>
                    <MediaProvider data={props} />
                </FormTab>
                <FormTab label={translate('ra.input.activity_state', 1)}>
                    <p fullWidth>
                        <strong>{translate('ra.action.note')}</strong>{' '}
                        {translate('ra.message.activity_state')}
                    </p>
                    <CustomInputs.IsActiveBooleanInput
                        fullWidth
                        helperText={translate('ra.message.activity_state_confirm')}
                    />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};
