import React, { useState, useContext } from 'react';
import arrayMutators from 'final-form-arrays';
import DataProvider from '../../providers/DataProvider';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useNotify } from 'ra-core';
import { MassTimeContext } from './MassTimeProvider';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import { Form } from 'react-final-form';
import { EditButton } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import BlockIcon from '@material-ui/icons/Block';
import CustomInputs from '../CustomInputs';
import FormTheme from '../../themes/DefaultThemeForm';

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.text.primary,
    },
    ...FormTheme.styles.modal,
}));

const MassTimeCancellationsModal = ({ record }) => {
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [saving, setSaving] = useState(false);
    const [, dispatch] = useContext(MassTimeContext);

    const submit = (values) => {
        setSaving(true);
        const thisSeason =
            record.category.toLowerCase() === 'special'
                ? record.mass_season_id
                : record.category;
        const cancellationsStr = values.cancellations
            ? values.cancellations
                .filter((d) => d)
                .map((d) => `"${d}"`)
                .toString()
            : null;
        return DataProvider.update('mass-times', {
            id: record.id,
            data: {
                cancellations: cancellationsStr ? `[${cancellationsStr}]` : null,
            },
        })
            .then(() => {
                dispatch({
                    type: 'toggle_dirty_accordion',
                    season: thisSeason,
                });
                setSaving(false);
                setOpen(false);
            })
            .catch((error) => {
                dispatch({
                    type: 'toggle_dirty_accordion',
                    season: thisSeason,
                });
                setSaving(false);
                notify(`${error.message} ${translate('ra.message.try_again')}`);
            });
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <EditButton
                onClick={handleOpen}
                scrollToTop={false}
                label={translate('ra.input.cancellation', 2)}
                icon={<BlockIcon />}
            />
            <Modal
                aria-labelledby='modal-title'
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                scrollToTop={false}
            >
                <Fade in={open}>
                    <div>
                        <Form
                            initialValues={record}
                            key={`cancellations-form-${record.id}`}
                            mutators={{ ...arrayMutators }}
                            onSubmit={submit}
                            render={({ handleSubmit }) => (
                                <form onSubmit={handleSubmit} noValidate>
                                    <div className={classes.paper}>
                                        <Grid
                                            container
                                            direction='row'
                                            justify='space-between'
                                            alignItems='center'
                                        >
                                            <h3 id='modal-title' className={classes.title}>
                                                {translate('ra.action.edit')}{' '}
                                                {translate('ra.navigation.resources.mass_time', 1)}
                                            </h3>
                                            <IconButton onClick={handleClose}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Grid>
                                        <CustomInputs.CancellationDatesArrayInput />
                                        <CardActions>
                                            <Button
                                                className={classes.button}
                                                variant='contained'
                                                type='submit'
                                                color='primary'
                                            >
                                                {translate('ra.action.save')}{' '}
                                                {translate('ra.navigation.resources.mass_time', 1)}{' '}
                                            </Button>
                                            {saving && <CircularProgress size={18} thickness={2} />}
                                        </CardActions>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </Fade>
            </Modal>
        </>
    );
};

export default MassTimeCancellationsModal;
