export const MediaReducer = (state, action) => {
    const setStateAccordionData = (stateOption, collection) =>
        !stateOption.includes(collection)
            ? stateOption.concat(collection)
            : stateOption.filter((n) => n !== collection);

    switch (action.type) {
        case 'toggle_accordion':
            state.open = setStateAccordionData(state.open, action.collection);
            return {
                ...state,
                open: state.open,
            };

        case 'toggle_dirty_accordion':
            if (state.open.includes(action.collection)) {
                state.dirty = setStateAccordionData(state.dirty, action.collection);
            }
            return {
                ...state,
                dirty: state.dirty,
            };

        default:
            return state;
    }
};

export const initialState = {
    open: [],
    dirty: [],
};
