import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';
import keyBy from 'lodash/keyBy';
import {
    useQuery,
    Datagrid,
    TextField,
    Loading,
    ListContextProvider,
    FunctionField,
    UrlField,
} from 'react-admin';
import DescriptionIcon from '@material-ui/icons/Description';
import MediaHelper from '../../helpers/MediaHelper';

const useStyles = makeStyles(() => ({
    document: {
        display: 'block',
        marginBottom: '.5rem',
    },
    image: {
        display: 'block',
        height: 99,
        objectFit: 'cover',
        width: 99,
    },
}));

export const MediaShowList = ({ resource, record }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const { data, loading, error } = useQuery({
        resource,
        type: 'getOne',
        payload: {
            id: record.id,
            with_media: MediaHelper.Collections()
                .map((c) => c.id)
                .join(','),
            with_crop: '1:1',
        },
    });
    if (loading) {
        return (
            <div>
                <h4>{translate('ra.input.media_item', 2)}</h4>
                <Loading />
            </div>
        );
    }
    if (error) {
        return (
            <div>
                <h4>{translate('ra.input.media_item', 2)}</h4>
                <p>{error.message}</p>
            </div>
        );
    }
    if (!Object.keys(data.media?.data).length) {
        return (
            <div>
                <h4>{translate('ra.input.media_item', 2)}</h4>
                <div>{translate('ra.navigation.no_results')}</div>
            </div>
        );
    }
    return (
        <div>
            <h4>{translate('ra.input.media_item', 2)}</h4>
            {Object.keys(data.media?.data).map((collection) => {
                const thisData = data.media.data[collection];
                return (
                    <div key={collection}>
                        <h5>{MediaHelper.Collections(true)[collection].name}</h5>
                        <ListContextProvider
                            value={{
                                data: keyBy(thisData, 'id'),
                                ids: thisData.map(({ id }) => id),
                                currentSort: { field: 'order', order: 'ASC' },
                            }}
                        >
                            <Datagrid>
                                <FunctionField
                                    render={(item) =>
                                        item.mime_type.includes('image') ? (
                                            <img
                                                className={classes.image}
                                                src={
                                                    item['derived']?.['crop-1-1']?.['198w']?.[
                                                    'regular'
                                                    ] ?? item.url
                                                }
                                                title={item.media_name}
                                                alt={item.media_name}
                                            />
                                        ) : (
                                            <DescriptionIcon className={classes.document} />
                                        )
                                    }
                                />
                                <TextField
                                    source='name'
                                    label={translate('ra.input.name', 1)}
                                    sortable={false}
                                />
                                <UrlField
                                    label={translate('ra.input.url', 1)}
                                    source='url'
                                    sortable={false}
                                />
                                <TextField
                                    source='alt'
                                    label={translate('ra.input.alt', 1)}
                                    sortable={false}
                                />
                                <TextField
                                    source='caption'
                                    label={translate('ra.input.caption', 1)}
                                    sortable={false}
                                />
                            </Datagrid>
                        </ListContextProvider>
                    </div>
                );
            })}
        </div>
    );
};
