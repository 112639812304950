import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useSafeSetState } from 'ra-core';
import FormTheme from '../../themes/DefaultThemeForm';
import {
    useNotify,
    useRefresh,
    useRedirect,
    Create,
    SimpleForm,
} from 'react-admin';
import CustomInputs from '../CustomInputs';
import CreateActions from '../CreateActions';
import ValidationHelper from '../../helpers/ValidationHelper';
import PasswordHelper from '../../helpers/PasswordHelper';

const useStyles = makeStyles(() => FormTheme.styles, {
    name: 'UserForm',
});

export const UserCreate = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = ({ data }) => {
        notify(translate('ra.notification.created'));
        redirect(data.id);
        refresh();
    };

    const transform = (data) => {
        const password = PasswordHelper.Generate();
        return {
            ...data,
            password,
            password_confirmation: password,
            is_active: data.is_active ? 1 : 0,
            edit_scope_ids: (!scopedProductRole) ? [] : data.edit_scope_ids,
        };
    };

    const apiPrimaryRoleId = process.env.REACT_APP_PRIMARY_ROLES.split(
        '|'
    ).reduce((p, d) => {
        const parts = d.split(':');
        return parts[0] === 'API' ? parts[1] : null;
    });

    const validate = (values) => {
        const errors = {
            ...ValidationHelper.ValidateRequired(
                values,
                ['username', 'primary_role_id', 'first_name'],
                translate('ra.validation.required')
            ),
            ...ValidationHelper.ValidateUsername(
                values,
                'username',
                translate('ra.validation.alpha_dash')
            ),
        };

        if (values.primary_role_id !== apiPrimaryRoleId && !values.email) {
            errors.email = translate('ra.validation.required');
        }

        if (values.primary_role_id === apiPrimaryRoleId && !values.api_token) {
            errors.api_token = translate('ra.validation.required');
        }

        return errors;
    };

    const [primaryRoleId, setPrimaryRoleId] = useSafeSetState(null);
    const [scopedProductRole, setScopedProductRole] = useSafeSetState(false);

    return (
        <Create
            {...props}
            actions={<CreateActions />}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.resources.user',
                2
            )}`}
            transform={transform}
            onSuccess={onSuccess}
            onFailure={(e) => {
                notify(ValidationHelper.FormatApiErrors(e));
            }}
            undoable={false}
        >
            <SimpleForm
                validate={validate}
                warnWhenUnsavedChanges
                redirect='list'
                className={classes.form}
            >
                <h3 className={classes.formTitle} fullWidth>
                    {translate('ra.action.creating')}
                    {' - '}
                    {translate('ra.navigation.resources.user', 1)}
                </h3>
                <p fullWidth>
                    <strong>{translate('ra.action.note')}</strong>{' '}
                    {translate('ra.message.new_user')}
                </p>
                <CustomInputs.UsernameTextInput />
                <CustomInputs.PrimaryRoleReferenceInput
                    onChange={(e) => {
                        setPrimaryRoleId(e);
                    }}
                />
                {(!primaryRoleId || primaryRoleId !== apiPrimaryRoleId) && (
                    <CustomInputs.RoleArrayInput
                        onChange={(e) => {
                            setScopedProductRole(
                                e.target.value.includes(
                                    process.env.REACT_APP_PRODUCT_SCOPED_ROLE
                                )
                            );
                        }}
                    />
                )}
                {(!primaryRoleId || primaryRoleId === apiPrimaryRoleId) && (
                    <CustomInputs.ApiTokenTextInput />
                )}
                {!scopedProductRole && (
                    <CustomInputs.CategoriesInput
                        allowEmpty
                        label={translate('ra.input.category_manage')}
                        helperText={translate('ra.input.helper.user_categories')}
                    />
                )}
                {scopedProductRole && (
                    <CustomInputs.ProductArrayInput
                        label={`${translate('ra.input.allowed', 1)} ${translate(
                            'ra.navigation.resources.product',
                            2
                        ).toLowerCase()}`}
                        helperText={translate('ra.input.helper.edit_scope')}
                        filter={{ flatten: null }}
                        source={'edit_scope_ids'}
                        allowEmpty
                    />
                )}
                <CustomInputs.NameTextInput
                    label={translate('ra.input.first_name', 1)}
                    source='first_name'
                />
                <CustomInputs.NameTextInput
                    label={translate('ra.input.last_name', 1)}
                    source='last_name'
                />
                {(!primaryRoleId || primaryRoleId !== apiPrimaryRoleId) && (
                    <CustomInputs.EmailTextInput />
                )}
                <CustomInputs.LocaleInput />
                <CustomInputs.IsActiveBooleanInput
                    label={translate('ra.input.enabled', 1)}
                    defaultValue='1'
                />
                <CustomInputs.InternalNotesTextInput />
            </SimpleForm>
        </Create>
    );
};
