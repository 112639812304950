import React, { useContext } from 'react';
import DataProvider from '../../providers/DataProvider';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { useTranslate, useNotify, useSafeSetState } from 'ra-core';
import { MediaContext } from './MediaProvider';
import { TextInput, SelectInput, FileInput, FileField } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import MediaWrapper from '../../layout/MediaWrapper';
import MediaHelper from '../../helpers/MediaHelper';
import ValidationHelper from '../../helpers/ValidationHelper';

const useStyles = makeStyles(() => ({
    button: {
        marginLeft: -8,
    },
    field: {
        width: '100%',
    },
    uploading: {
        background: 'rgb(250, 250, 250)',
        display: 'block',
        margin: '1rem 0',
        padding: '2rem 1rem',
    },
}));

const uploadingFileNames = [];
let uploadCompleteFileNames = [];

const MediaCreate = (props) => {
    const thisResource = {
        basePath: props.data.basePath.replace(/^\/+/, ''),
        id: props.data.record.id,
        name:
            props.data.record.name ??
            props.data.record.full_name_with_post_nominal_derived,
    };
    const [uploading, setUploading] = useSafeSetState([]);
    const updateUploading = () => {
        setUploading(
            uploadingFileNames.filter((n) => !uploadCompleteFileNames.includes(n))
        );
    };
    const addUploading = (name) => {
        if (!uploadingFileNames.includes(name)) {
            uploadingFileNames.push(name);
        }
        uploadCompleteFileNames = uploadCompleteFileNames.filter((n) => n !== name);
        updateUploading();
    };
    const removeUploading = (name) => {
        uploadCompleteFileNames.push(name);
        updateUploading();
    };
    const [, dispatch] = useContext(MediaContext);
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles();

    const validate = (values) =>
        ValidationHelper.ValidateRequired(
            values,
            ['collection', 'name', 'file'],
            translate('ra.validation.required')
        );

    const submit = (values, form) => {
        addUploading(values.file.rawFile.name);
        const mediaData = new FormData();
        mediaData.append('collection', values.collection);
        mediaData.append('name', values.name);
        mediaData.append('alt', values.alt ?? '');
        mediaData.append('caption', values.caption ?? '');
        mediaData.append('file', values.file.rawFile);
        const thisFileName = values.file.rawFile.name;
        Object.keys(values).forEach((key) => {
            form.change(key, null);
            form.resetFieldState(key);
        });
        form.change('name', thisResource.name);
        form.change('alt', thisResource.name);
        return DataProvider.createSubResource(
            thisResource.basePath,
            'media',
            thisResource.id,
            {
                data: mediaData,
            }
        )
            .then(() => {
                removeUploading(thisFileName);
                dispatch({
                    type: 'toggle_dirty_accordion',
                    collection: values.collection,
                });
            })
            .catch((error) => {
                removeUploading(thisFileName);
                notify(
                    `${values.name}: ${error.message} ${translate(
                        'ra.message.try_again'
                    )}`
                );
            });
    };

    return (
        <MediaWrapper
            header={`${translate('ra.action.add')} ${translate(
                'ra.input.media_item',
                1
            )}`}
        >
            <Form
                onSubmit={submit}
                validate={validate}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <FileInput
                            source='file'
                            label=''
                            maxSize={31457280}
                            helperText={translate('ra.input.helper.media_accepted')}
                            accept={MediaHelper.AcceptFileTypes()}
                        >
                            <FileField source='src' title='title' />
                        </FileInput>
                        <SelectInput
                            {...props}
                            className={classes.field}
                            variant='outlined'
                            label={translate('ra.input.media_collection', 1)}
                            source='collection'
                            fullWidth
                            choices={MediaHelper.Collections()}
                        />
                        <TextInput
                            {...props}
                            className={classes.field}
                            variant='outlined'
                            label={translate('ra.input.name', 1)}
                            source='name'
                            defaultValue={thisResource.name}
                        />
                        <TextInput
                            {...props}
                            className={classes.field}
                            variant='outlined'
                            label={translate('ra.input.alt', 1)}
                            source='alt'
                            defaultValue={thisResource.name}
                        />
                        <TextInput
                            {...props}
                            className={classes.field}
                            variant='outlined'
                            label={translate('ra.input.caption', 1)}
                            source='caption'
                        />
                        <CardActions>
                            <Button
                                className={classes.button}
                                variant='contained'
                                type='submit'
                                color='primary'
                            >
                                {translate('ra.action.add')}{' '}
                                {translate('ra.input.media_item', 1)}
                            </Button>
                        </CardActions>
                        {uploading && uploading.length > 0 && (
                            <div className={classes.uploading}>
                                <CircularProgress size={18} thickness={2} />{' '}
                                {translate('ra.action.uploading')}
                                <ul>
                                    {uploading.map((fileName, index) => (
                                        <li key={index}>{fileName}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </form>
                )}
            />
        </MediaWrapper>
    );
};

export default MediaCreate;
