import React, { useState } from 'react';
import { useTranslate } from 'ra-core';
import keyBy from 'lodash/keyBy';
import {
    usePermissions,
    useQuery,
    Datagrid,
    ReferenceField,
    TextField,
    Loading,
    ListContextProvider,
    EditButton,
    ShowButton,
} from 'react-admin';
import CustomInputs from '../CustomInputs';
import ListActiveField from '../ListActiveField';
import CustomDeleteButton from '../CustomDeleteButton';
import ListPagination from '../ListPagination';

export const ProductShowPersonnelList = ({ record }) => {
    const productId = record.id;
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(15);
    const { data, total, loading, error } = useQuery({
        type: 'getManyReference',
        resource: 'products',
        payload: {
            pagination: { page, perPage },
            sort: { field: 'last_name', order: 'ASC' },
            filter: {
                with: 'roles_by_role_meta_category_derived',
            },
            id: record.id,
            reference: 'personnel',
        },
    });

    if (loading) {
        return <Loading />;
    }
    if (error) {
        return <p>{error.message}</p>;
    }
    return (
        <ListContextProvider
            value={{
                total,
                page,
                setPage,
                perPage,
                setPerPage,
                data: keyBy(data, 'id'),
                ids: data.map(({ id }) => id),
                currentSort: { field: 'last_name', order: 'ASC' },
                basePath: '/personnel',
                resource: 'personnel',
                selectedIds: [],
            }}
        >
            <Datagrid>
                <ReferenceField
                    label={translate('ra.input.title', 1)}
                    source='title_meta_category_id'
                    reference='meta-categories'
                    sortable={false}
                >
                    <TextField source='name' />
                </ReferenceField>
                <TextField
                    source='last_name'
                    label={translate('ra.input.last_name', 1)}
                />
                <TextField
                    source='first_name'
                    label={translate('ra.input.first_name', 1)}
                />
                <TextField
                    source='post_nominal'
                    label={translate('ra.input.post_nominal', 1)}
                />
                <CustomInputs.PersonnelRoleField
                    parentId={productId}
                    label={translate('ra.input.role', 2)}
                />
                <ListActiveField
                    source='is_active'
                    label={translate('ra.input.active')}
                />
                {permissions &&
                    (permissions.includes('directory') ||
                        permissions.includes('directory-products')) && <EditButton />}
                {permissions &&
                    (permissions.includes('directory') ||
                        permissions.includes('directory-products')) && (
                        <CustomDeleteButton />
                    )}
                {permissions &&
                    (permissions.includes('directory') ||
                        permissions.includes('directory-products')) && <ShowButton />}
            </Datagrid>
            <ListPagination />
        </ListContextProvider>
    );
};
