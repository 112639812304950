import React, { createContext, useReducer } from 'react';
import { MediaReducer, initialState } from './MediaReducer';
import MediaCreate from './MediaCreate';
import MediaAccordion from './MediaAccordion';
import MediaHelper from '../../helpers/MediaHelper';

export const MediaContext = createContext({
    state: initialState,
    dispatch: () => null,
});

export const MediaProvider = (data) => {
    const [state, dispatch] = useReducer(MediaReducer, initialState);

    return (
        <MediaContext.Provider value={[state, dispatch]} data={data}>
            <MediaCreate data={data} />
            {MediaHelper.Collections().map((collection, key) => (
                <MediaAccordion
                    data={data}
                    collection={collection}
                    key={key}
                ></MediaAccordion>
            ))}
        </MediaContext.Provider>
    );
};
