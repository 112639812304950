import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useSafeSetState } from 'ra-core';
import FormTheme from '../../themes/DefaultThemeForm';
import {
    useNotify,
    useRefresh,
    TextField,
    Edit,
    TabbedForm,
    FormTab,
    TextInput,
    BooleanInput,
} from 'react-admin';
import CustomInputs from '../CustomInputs';
import CustomToolbar from '../CustomToolbar';
import { MediaProvider } from '../media/MediaProvider';
import RichTextInput from 'ra-input-rich-text';
import RichTextHelper from '../../helpers/RichTextHelper';
import EditActions from '../EditActions';
import ValidationHelper from '../../helpers/ValidationHelper';

const useStyles = makeStyles(() => FormTheme.styles, {
    name: 'ServiceCategoryForm',
});

export const ServiceCategoryEdit = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const onSuccess = () => {
        notify(translate('ra.notification.updated', 1));
        refresh();
    };

    const transform = (data) => ({
        ...data,
        is_show_all_products_overview: data.is_show_all_products_overview ? 1 : 0,
        is_active: data.is_active ? 1 : 0,
    });

    const validate = (values) => ({
        ...ValidationHelper.ValidateRequired(
            values,
            ['category_id', 'name', 'slug', 'meta_description'],
            translate('ra.validation.required')
        ),
        ...ValidationHelper.ValidateLength(
            values,
            'meta_description',
            160,
            translate('ra.validation.maxLength', {
                max: 160,
            })
        ),
    });

    // Set initial state
    const WithProps = ({ record }) => {
        if (categoryId === null) {
            setCategoryId(record.category_id);
        }
        return '';
    };

    const [categoryId, setCategoryId] = useSafeSetState(null);
    const filter = () => {
        return {
            flatten: null,
            category_id: categoryId || null,
        };
    };

    return (
        <Edit
            {...props}
            actions={<EditActions />}
            title={`${process.env.REACT_APP_TITLE} - ${translate(
                'ra.navigation.menu.directory'
            )}: ${translate('ra.navigation.resources.service_category', 2)}`}
            transform={transform}
            onSuccess={onSuccess}
            onFailure={(e) => {
                notify(ValidationHelper.FormatApiErrors(e));
            }}
            undoable={false}
        >
            <TabbedForm
                validate={validate}
                warnWhenUnsavedChanges
                toolbar={<CustomToolbar />}
                redirect='list'
                className={classes.form}
            >
                <FormTab label='Overview'>
                    <WithProps />
                    <TextField source='id' label={translate('ra.input.id', 1)} />
                    <CustomInputs.CategoryReferenceInput
                        onChange={(e) => setCategoryId(e.target.value)}
                    />
                    <CustomInputs.ServiceCategoryInput
                        label={translate('ra.input.parent', 1)}
                        source='parent_id'
                        filter={filter()}
                        allowEmpty
                    />
                    <CustomInputs.SlugTextInput />
                    <TextInput
                        variant='outlined'
                        label={translate('ra.input.name', 1)}
                        source='name'
                        fullWidth
                    />
                    <TextInput
                        variant='outlined'
                        label={translate('ra.input.meta_description', 1)}
                        source='meta_description'
                        helperText={translate('ra.validation.maxLength', {
                            max: 160,
                        })}
                        fullWidth
                    />
                    <RichTextInput
                        variant='outlined'
                        label={translate('ra.input.description', 1)}
                        source='description'
                        fullWidth
                        toolbar={RichTextHelper.Basic}
                    />
                    <CustomInputs.GoalsInput filter={filter()} allowEmpty />
                    <BooleanInput
                        label={translate('ra.input.show_products_in_overview', 1)}
                        source='is_show_all_products_overview'
                        defaultValue={true}
                        fullWidth
                    />
                    <CustomInputs.OrderingNumberInput
                        helperText={translate('ra.input.helper.optional_ordering')}
                    />
                    <BooleanInput
                        label={translate('ra.input.active', 1)}
                        source='is_active'
                    />
                    <CustomInputs.InternalNotesTextInput />
                </FormTab>
                <FormTab label={translate('ra.input.media_collection', 2)}>
                    <MediaProvider data={props} />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};
