const PasswordResetTheme = {
    styles: {
        button: {
            width: '100%',
            margin: '0 .5rem .5rem',
        },
        form: {
            padding: '0 1em 1em 1em',
        },
        icon: {
            marginRight: '.4rem',
        },
        input: {
            marginTop: '1em',
        },
    },
};

export default PasswordResetTheme;
