import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { EditButton, ShowButton } from 'react-admin';
import CustomReportButton from './CustomReportButton';
import CustomDeleteButton from './CustomDeleteButton';
import CustomRestoreButton from './CustomRestoreButton';
import CustomPermanentlyDeleteButton from './CustomPermanentlyDeleteButton';

const useStyles = makeStyles(() => ({
    root: {
        '& div': {
            minWidth: 85,
            textAlign: 'center',
        },
    },
}));

const ResourceManagementButtons = ({
    basePath,
    record = {},
    reportButtonName,
    reportUrl,
    reportMimeType,
    reportExtension,
}) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {record.deleted_at === null && (
                <Grid
                    container
                    direction='row'
                    justify='space-around'
                    alignItems='center'
                >
                    <EditButton record={record} />
                    <CustomDeleteButton record={record} />
                    <ShowButton record={record} />
                    {reportUrl && (
                        <CustomReportButton
                            reportUrl={reportUrl}
                            buttonName={reportButtonName}
                            record={record}
                            mimeType={reportMimeType}
                            extension={reportExtension}
                        />
                    )}
                </Grid>
            )}
            {record.deleted_at !== null && (
                <Grid
                    container
                    direction='row'
                    justify='space-around'
                    alignItems='center'
                >
                    <CustomRestoreButton
                        basePath={basePath}
                        record={record}
                        isPlural={false}
                    />
                    <CustomPermanentlyDeleteButton
                        basePath={basePath}
                        record={record}
                        isPlural={false}
                    />
                </Grid>
            )}
        </div>
    );
};

export default ResourceManagementButtons;
