import * as React from 'react';
import { TopToolbar, ListButton } from 'react-admin';

const CreateActions = ({ basePath }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
    </TopToolbar>
);

export default CreateActions;
