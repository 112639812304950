import * as React from 'react';
import { useTranslate } from 'ra-core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles({
    h2: {
        fontSize: 22,
        fontWeight: 'normal',
    },
});

const Dashboard = () => {
    const translate = useTranslate();
    const classes = useStyles();
    return (
        <Card>
            <CardHeader title={process.env.REACT_APP_TITLE} />
            <>
                <CardContent>{translate('ra.message.welcome')}</CardContent>
                <CardContent>
                    <h2 className={classes.h2}>
                        {translate('ra.navigation.menu.documentation')}
                    </h2>
                    <ul>
                        <li>
                            <a href='/api-admin-manual.docx'>
                                {translate('ra.message.api_documentation_admin_manual')}
                            </a>
                        </li>
                        <li>
                            <a href={translate('ra.message.api_documentation_url')}>
                                {translate('ra.message.api_documentation_api_docs')}
                            </a>
                        </li>
                    </ul>
                </CardContent>
            </>
        </Card>
    );
};

export default Dashboard;
